import { Button, PageHeader, Tooltip, Image } from "antd"
import { useEffect, useState } from "react";
import { AddVendor } from "./AddVendor";
import type { ColumnsType } from 'antd/es/table';
import UseSupplierService from "../hooks/UseSupplierService";
import useSessionStorageService from '../hooks/UseSessionStorageService';
import { ImportSupplier } from "./ImportSupplier";
import { CustomTable } from "./CustomTable";
import { AddVendorContact } from "./AddVendorContact";
import Swal from "sweetalert2";
import { MESSAGE_CONSTANT } from "../constants/MessageConstants";
import { Plus, Edit, UploadWhite } from "../assets/icons";

export interface CustomType {
  enableSearch?: boolean
}

export type CustomColumnsType<T> = CustomType & ColumnsType<T>

export const Vendor = () => {
  const { getAllSupplierWithContact } = UseSupplierService();
  const { getUserName } = useSessionStorageService();
  const [supplierWithContacts, setSupplierWithContacts] = useState<any>([]);
  const [isSupplierModalVisible, setIsSupplierModalVisible] = useState(false);
  const [isSupplierContactModalVisible, setIsSupplierContactModalVisible] = useState(false);
  const [isImportSupplierModalVisible, setIsImportSupplierModalVisible] = useState(false);
  const [supplierContactId, setSupplierContactId] = useState(0)
  const [newSupplierId, setNewSupplierId] = useState<any>(0);
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 5
  });
  const [supplierUserAssociationId, setSupplierUserAssociationId] = useState(0);

  const hideSupplierModel = (confirmAddContact: boolean, newSupplierId: any) => {
    setIsSupplierModalVisible(false);
    setNewSupplierId(newSupplierId);

    if (confirmAddContact) {
      Swal.fire({
        text: MESSAGE_CONSTANT.ConfirmAddSupplierContact,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          showSupplierContactModal();
        }
      });
    }
    else {
      setSupplierUserAssociationId(0);
      setNewSupplierId(0);
    }

    getSupplierWithContact();
  }

  const cancelSupplierModel = () => {
    setIsSupplierModalVisible(false);
    setSupplierUserAssociationId(0);
  }

  const hideSupplierContactModel = () => {
    setIsSupplierContactModalVisible(false);
    setSupplierContactId(0);
    getSupplierWithContact();
  }

  const cancelSupplierContactModel = () => {
    setIsSupplierContactModalVisible(false);
  }

  useEffect(() => {
    getSupplierWithContact();
  }, [])

  const getSupplierWithContact = () => {
    let reqObj = {
      userEmailId: getUserName(),
    }
    getAllSupplierWithContact(reqObj).then((supplierWithContact: any) => {
      setSupplierWithContacts(supplierWithContact);
    });
  }

  const editSupplier = (obj: any) => {
    setSupplierUserAssociationId(obj.supplierUserAssociationId);
    showSupplierModalVisible();
  }

  const supplierColumns: CustomColumnsType<any> = [
    {
      title: 'Supplier Name', dataIndex: 'supplierName', key: 'SupplierName', width: '45%'
      , sorter: (a: any, b: any) => a.supplierName?.length - b.supplierName?.length
      , sortDirections: ['descend', 'ascend']
      , enableSearch: true,
      ellipsis: true
    },
    {
      title: 'Supplier Type', dataIndex: 'supplierTypeMappings', key: 'supplierTypeMappings', width: '45%'
      , sorter: (a: any, b: any) => a.supplierTypeMappings?.length - b.supplierTypeMappings?.length
      , sortDirections: ['descend', 'ascend']
      , enableSearch: true
    },
    {
      key: "action",
      title: "Action",
      dataIndex: "action",
      width: "10%",
      render: (text: any, record: any) => (
        <Tooltip title="Edit">
          <Button
            key={`Edit_${record.id}`}
            icon={
              <Image src={Edit} width={20} height={20} preview={false} />
            }
            onClick={() => editSupplier(record)}
            style={{ border: "none", backgroundColor: "White" }}
          />
        </Tooltip>
      ),
    },

    // { title: 'Preference Order', dataIndex: 'preferenceOrder', key: 'PreferenceOrder', align: 'right', width: '20%' },
    // {
    //     title: 'Created Date', key: 'CreatedDate',
    //     width: '30%',
    //     render: (text: any, record: any) => (
    //         <>{new Date(record.createdDate).toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric' })}</>
    //     )
    // }
  ];

  const expandedRowRender = (row: any, index: any) => {

    const editSupplierContact = (id: any) => {
      setIsSupplierContactModalVisible(true);
      setSupplierContactId(id);
    }

    const columns: CustomColumnsType<any> = [
      {
        title: "Full Name",
        dataIndex: "fullName",
        width: "15%",
        sorter: (a: any, b: any) => a.fullName?.length - b.fullName?.length,
        sortDirections: ["descend", "ascend"],
        enableSearch: true,
      },
      {
        title: "Email Id",
        dataIndex: "emailId",
        width: "20%",
        sorter: (a: any, b: any) => a.emailId?.length - b.emailId?.length,
        sortDirections: ["descend", "ascend"],
        enableSearch: true,
      },
      {
        title: "Address",
        dataIndex: "address",
        sorter: (a: any, b: any) => a.address?.length - b.address?.length,
        sortDirections: ["descend", "ascend"],
        enableSearch: true,
      },
      {
        title: "Contact No",
        dataIndex: "contactNo",
        sorter: (a: any, b: any) =>
          a.contactNo?.length - b.contactNo?.length,
        sortDirections: ["descend", "ascend"],
        enableSearch: true,
      },
      // {
      //     title: 'Is Default', dataIndex: 'isDefault',
      //     width: '10%'
      //     , enableSearch: true
      // },
      // {
      //     title: 'Active', dataIndex: 'active',
      //     width: '5%'
      //     , enableSearch: true
      // },
      {
        title: "Created Date",
        key: "CreatedDate",
        width: "15%",
        render: (text: any, record: any) => (
          <>
            {new Date(record.createdDate).toLocaleString("default", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </>
        ),
      },
      {
        key: "action",
        title: "Action",
        dataIndex: "action",
        width: "5%",
        render: (text: any, record: any) => (
          <Tooltip title="Edit">
            <Button
              key={`Edit_${record.id}`}
              icon={
                <Image src={Edit} width={20} height={20} preview={false} />
              }
              onClick={() => editSupplierContact(record.id)}
              style={{ border: "none", backgroundColor: "White" }}
            />
          </Tooltip>
        ),
      },
    ];
    return <CustomTable scroll={{ x: true }}
      columns={columns}
      dataSource={row.supplierContacts}
      pagination={false}
      rowKey='id' />;
  };

  const showImportSupplierModal = () => {
    setIsImportSupplierModalVisible(true);
  }

  const cancelImportSupplierModal = (isSuccess: boolean) => {
    setIsImportSupplierModalVisible(false);
    if (isSuccess) {
      getSupplierWithContact();
    }
  }

  const showSupplierContactModal = () => {
    setIsSupplierContactModalVisible(true);
    setSupplierContactId(0);
  }

  const showSupplierModalVisible = () => {
    setIsSupplierModalVisible(true);
  }

  return (
    <>
      <PageHeader
        title="My Suppliers"
        extra={[
          <>
            <Button
              icon={
                <Image src={Plus} width={16} height={16} preview={false} />
              }
              id='supplier_addSupplier'
              onClick={showSupplierModalVisible}
              style={{
                float: "right",
                borderRadius: "5px",
                backgroundColor: "#046AED",
                color: "white",
              }}
            >
              <span style={{ marginLeft: "8px" }}>ADD SUPPLIER</span>
            </Button>
            <Button
              icon={
                <Image src={Plus} width={16} height={16} preview={false} />
              }
              id='supplier_addSupplierContact'
              onClick={showSupplierContactModal}
              style={{
                float: "right",
                borderRadius: "5px",
                backgroundColor: "#046AED",
                color: "white",
              }}
            >
              <span style={{ marginLeft: "8px" }}> ADD SUPPLIER CONTACT</span>
            </Button>
            <Button
              icon={
                <Image
                  src={UploadWhite}
                  width={16}
                  height={16}
                  preview={false}
                />
              }
              id='supplier_addSupplierConatctFromQuoteCQ'
              onClick={showImportSupplierModal}
              style={{
                float: "right",
                borderRadius: "5px",
                backgroundColor: "#046AED",
                color: "white",
              }}
            >
              <span style={{ marginLeft: "8px" }}>IMPORT SUPPLIERS FROM QuoteCQ</span>
            </Button>
          </>
        ]}
      ></PageHeader>

      <AddVendor
        isSupplierModalVisible={isSupplierModalVisible}
        hideSupplierModel={hideSupplierModel}
        cancelSupplierModel={cancelSupplierModel}
        supplierUserAssociationId={supplierUserAssociationId}
      />
      <AddVendorContact
        isSupplierContactModalVisible={isSupplierContactModalVisible}
        cancelSupplierContactModel={cancelSupplierContactModel}
        editSupplierContactId={supplierContactId}
        hideSupplierContactModel={hideSupplierContactModel}
        newSupplierId={newSupplierId}
      />
      <ImportSupplier
        isImportSupplierModalVisible={isImportSupplierModalVisible}
        cancelImportSupplierModal={cancelImportSupplierModal}
      />

      <CustomTable
        columns={supplierColumns}
        expandable={{ expandedRowRender }}
        dataSource={supplierWithContacts}
        rowKey='id'
        pagination={{
          pageSize: pagination.pageSize,
          showSizeChanger: true,
          onChange: (page: any, pageSize: any) => {
            setPagination({ current: page, pageSize: pageSize })
          }
        }}
      />
    </>
  )
}