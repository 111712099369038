import { Form, Input, Select, Modal, Col, Row, Tooltip } from 'antd';
import { useState, useEffect } from 'react';
import { MESSAGE_CONSTANT } from '../constants/MessageConstants';
import UseSupplierService from "../hooks/UseSupplierService";
import UseSupplierContactService from "../hooks/UseSupplierContactService";
import Notification from '../components/notification/Notification';
import useSessionStorageService from '../hooks/UseSessionStorageService';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import TextArea from 'antd/lib/input/TextArea';
import { ErrorConstants } from '../constants/ErrorConstants';
import { PlusCircleOutlined } from '@ant-design/icons';
import { AddVendor } from './AddVendor';

export const AddVendorContact = ({ isSupplierContactModalVisible, cancelSupplierContactModel, editSupplierContactId, hideSupplierContactModel, newSupplierId }: any) => {

    const { Option } = Select;

    const [form] = Form.useForm();
    const { getSupplierNamesForDropdown } = UseSupplierService();
    const { addSupplierContact, updateSupplierContact, getSupplierContact } = UseSupplierContactService();
    const { getUserName } = useSessionStorageService();
    const [suppliers, setSuppliers] = useState<any>([]);
    const [isDefaultChecked, setIsDefaultChecked] = useState(false);
    const [activeChecked, setActiveChecked] = useState(false);
    const [isSupplierModalVisible, setIsSupplierModalVisible] = useState(false);
    const [addVenderContactResponse, setAddvenderContactResponse] = useState('')

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 8 },
    };

    useEffect(() => {
        getSupplierNames();
    }, [])

    useEffect(() => {
        if (newSupplierId) {
            getSupplierNames();
        }
    }, [newSupplierId])

    const getSupplierNames = () => {
        getSupplierNamesForDropdown({ UserName: getUserName() }).then((suppliers: any) => setSuppliers(suppliers));
    }

    useEffect(() => {
        if (editSupplierContactId > 0) {
            form.resetFields();
            getSupplierContact({
                supplierContactId: editSupplierContactId
            }).then((supplierContact: any) => {
                form.setFieldsValue(supplierContact);
                // setActiveChecked(supplierContact.active);
                // setIsDefaultChecked(supplierContact.isDefault);
            });
        }
    }, [editSupplierContactId])

    useEffect(() => {
        if (addVenderContactResponse) {
            form.setFieldsValue({
                supplierId: addVenderContactResponse
            });
        }
    }, [form, addVenderContactResponse]);

    useEffect(() => {
        if (newSupplierId) {
            form.setFieldsValue({
                supplierId: newSupplierId
            });
        }
    }, [newSupplierId])

    const onFinish = async (values: any) => {
        let reqObj = {
            UserName: getUserName(),
            Id: 0,
            SupplierId: values.supplierId,
            FullName: values.fullName.trim(),
            EmailId: values.emailId,
            ContactNo: values.contactNo ?? "",
            Address: values.address ?? "",
            IsDefault: false,
            Active: true
        };
        if (editSupplierContactId > 0) {
            reqObj.Id = editSupplierContactId;
            let responseData: any = await updateSupplierContact(reqObj);
            if (responseData?.statusCode == ErrorConstants.badRequest.statusCode) {
                Notification('error', 'Error', responseData.message);
                return;
            }
            Notification('success', 'Success', MESSAGE_CONSTANT.supplierContactUpdatedSuccessfully);
            hideSupplierContactModel();
            form.resetFields();
        }
        else {
            let responseData: any = await addSupplierContact(reqObj);
            if (responseData?.statusCode == ErrorConstants.badRequest.statusCode) {
                Notification('error', 'Error', responseData.message);
                return;
            }
            Notification('success', 'Success', MESSAGE_CONSTANT.supplierContactCreatedSuccessfully);
            hideSupplierContactModel();
            form.resetFields();
        }
    };

    const onIsDefaultCheckboxChange = (e: CheckboxChangeEvent) => {
        setIsDefaultChecked(e.target.checked);
    }

    const onActiveCheckboxChange = (e: CheckboxChangeEvent) => {
        setActiveChecked(e.target.checked);
    }
    const onCancelSupplierContactModal = () => {
        form.resetFields();
        cancelSupplierContactModel();
    }
    const cancelSupplierModel = () => {
        setIsSupplierModalVisible(false);
    }
    const hideSupplierModel = async () => {
        form.resetFields();
        let suppliers = await getSupplierNamesForDropdown({ UserName: getUserName() });
        setSuppliers(suppliers);
        setIsSupplierModalVisible(false);
    }

    const addVendorResponse = (value: any) => {
        setAddvenderContactResponse(value);
    }

    return (
        <>
            <Modal title={editSupplierContactId > 0 ? 'Edit Supplier Contact' : 'Add New Supplier Contact'}
                visible={isSupplierContactModalVisible}
                okText="Save"
                cancelText="Cancel"
                width={800}
                maskClosable={false}
                onOk={form.submit}
                onCancel={onCancelSupplierContactModal}
            >
                <Form
                    {...layout}
                    form={form}
                    name="register"
                    onFinish={onFinish}
                    scrollToFirstError
                >
                    <Row>
                        <Col span={20} push={1}>
                            <Form.Item label="Supplier" name="supplierId"
                                rules={[{ required: true, message: '*Required' }]}>
                                <Select
                                    showSearch
                                    allowClear
                                    style={{ width: '250px' }}
                                    placeholder="Please Select"
                                    size="large"
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {
                                        suppliers.map((value: any, index: number) => {
                                            return (
                                                <Option key={index} value={value.supplierId}>
                                                    {value.supplierName}
                                                </Option>
                                            )
                                        })};
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4} pull={6} >
                            <Tooltip title="Add Supplier">
                                <PlusCircleOutlined
                                    style={{ fontSize: "25px", paddingTop: '5px', marginLeft: '12px' }}
                                    onClick={() => setIsSupplierModalVisible(true)}
                                />
                            </Tooltip>
                        </Col>
                    </Row>

                    <Form.Item
                        name="fullName"
                        label="Full Name"
                        rules={[{ required: true, message: 'Please enter Full Name' }]}>
                        <Input size="large" placeholder='Full Name' maxLength={200} />
                    </Form.Item>

                    <Form.Item
                        name="emailId"
                        label="Email Id"
                        rules={[{ type: 'email', message: 'Please enter valid Email Id' }, { required: true, message: '*Required' }]}>
                        <Input size="large" placeholder='Email Id' maxLength={200} />
                    </Form.Item>

                    <Form.Item
                        name="contactNo"
                        label="Contact No"
                        rules={[{ pattern: new RegExp(/^[0-9]+$/), message: 'Please enter valid Contact No' }]}>
                        <Input size="large" placeholder='Contact No' maxLength={20} />
                    </Form.Item>

                    <Form.Item
                        name="address"
                        label="Address">
                        <TextArea
                            placeholder="Address"
                            autoSize={{ minRows: 3, maxRows: 3 }}
                            maxLength={400}
                        />
                    </Form.Item>

                    {/* <Form.Item
                    name="isDefault"
                    label="IsDefault">
                    <Checkbox checked={isDefaultChecked} onChange={onIsDefaultCheckboxChange}></Checkbox>
                </Form.Item> */}

                    {/* <Form.Item
                    name="active"
                    label="Active">
                    <Checkbox checked={activeChecked} onChange={onActiveCheckboxChange}></Checkbox>
                </Form.Item> */}
                </Form>
            </Modal>

            {isSupplierModalVisible &&
                <AddVendor
                    isSupplierModalVisible={isSupplierModalVisible}
                    hideSupplierModel={hideSupplierModel}
                    cancelSupplierModel={cancelSupplierModel}
                    addVendorResponse={addVendorResponse}
                />
            }
        </>
    )
}