import { Form, Input, Radio, Col, Select, Tooltip, Row } from "antd";
import { useEffect, useState } from "react";
import { DATA_CONSTANT } from "../../constants/DataConstants";
import { isNumber } from "underscore";
import { json } from "node:stream/consumers";


export const PCB = ({ form, pcbAnswer, pcbQuestions, handlePCBAnswerData, isDisabled, textboxControlLayout, dropdownControlLayout, radioControlLayout, textAreaControlLayout }: any) => {

    const questions = pcbQuestions;

    const { Option } = Select;

    const [pcbData, setPCBData] = useState<any>(pcbAnswer);

    const onUOMValueChange = (value: any, fieldName: string) => {
        if (fieldName.startsWith("Board")) {
            setPCBData({ ...pcbData, "BoardHeightUOM": value, "BoardWidthUOM": value });
        }
        else if (fieldName.startsWith("Panel")) {
            setPCBData({ ...pcbData, "PanelHeightUOM": value, "PanelWidthUOM": value });
        }
        else if (fieldName.startsWith("Dimension")) {
            setPCBData({ ...pcbData, "DimensionWidthUOM": value, "DimensionHeightUOM": value });
        }
        else {
            setPCBData({ ...pcbData, [fieldName]: value });
        }
    }


    useEffect(() => {
        handlePCBAnswerData(pcbData);
    }, [pcbData]);

    const handleValueChange = (value: any, fieldName: any, field: any) => {
        let finalValue: any = value;
        if (field.dataType === 'number' && !isNaN(Number(finalValue))) {
            if (field.minValue != null && value.includes(".")) {
                let maxDecimalValue = field.minValue.toString().split('.')[1].length;
                let inputDecimalValue = value.split('.')[1].length;
                finalValue = Number(value).toFixed(Math.min(inputDecimalValue, maxDecimalValue));

                let pcbQuestions = form.getFieldsValue("PCBQuestions");
                form.setFieldsValue({ "PCBQuestions": { ...pcbQuestions, [fieldName]: finalValue } });
            }
        }

        setPCBData({ ...pcbData, [fieldName]: finalValue });
    }

    const validateNumber = (rule: any, value: any, field: any) => {
        if (field.dataType === 'number' &&
            value != "" && isNumber(Number(value)) &&
            (field.minValue != null && Number(value) < field.minValue) || (field.maxValue != null && Number(value) > field.maxValue)) {
            return Promise.reject(new Error(`${field.displayName} should be between ${field.minValue} to ${field.maxValue}.`));
        }
        return Promise.resolve();
    }

    const handleKeyDown = (e: any, field: any) => {
        const charCode = (e.which) ? e.which : e.keyCode;
        if (charCode === 69 || charCode === 109 || charCode === 107 || charCode === 187 || charCode === 189 ||
            ((charCode === 110 || charCode === 190) && (!field.maxValue?.toString()?.includes(".") || !field.minValue?.toString()?.includes(".")))
        ) {
            e.preventDefault();
            return false;
        }
        return true;
    }

    return (
        <>
            {
                questions.map((item: any, indexQ: number) => {
                    return (
                        item.pcbQuestionFields.map((field: any, indexF: number) => {
                            switch (field.control) {
                                case 'TextBox':
                                    return (
                                        <Row key={`${field.fieldName}${indexQ}_${indexF}`}>
                                            <Col span={18}>
                                                <Form.Item
                                                    initialValue={pcbData[field.fieldName]}
                                                    name={["PCBQuestions", `${field.fieldName}`]}
                                                    label={field.displayName}
                                                    rules={[
                                                        { required: item.isRequired, message: `${field.displayName} is required` },
                                                        { validator: (rule, value) => validateNumber(rule, value, field) }
                                                    ]}
                                                    style={{ display: field.active ? "block" : "none", marginBottom: "5px" }}
                                                    {...textboxControlLayout}
                                                >
                                                    {field.dataType === 'text' ?
                                                        <Input
                                                            size="middle"
                                                            disabled={isDisabled}
                                                            type="text"
                                                            onChange={(event: any) => handleValueChange(event.target.value, field.fieldName, field)}
                                                            placeholder={field.displayName}
                                                        />
                                                        :
                                                        <Input
                                                            size="middle"
                                                            disabled={isDisabled}
                                                            type='number'
                                                            placeholder={field.displayName}
                                                            onChange={(e: any) => handleValueChange(e.target.value, field.fieldName, field)}
                                                            onKeyDown={(e: any) => { handleKeyDown(e, field); }}
                                                            onPaste={(e) => {
                                                                e.preventDefault();
                                                                return false;
                                                            }}
                                                            step='0.01'
                                                        />
                                                    }
                                                </Form.Item>
                                            </Col>
                                            {
                                                field.uoMs !== null ?
                                                    <Col span={6}>
                                                        <Select
                                                            key={`${field.fieldName}UOM_${indexQ}_${indexF}`}
                                                            disabled={isDisabled}
                                                            style={{ width: 100, paddingLeft: "10px" }}
                                                            value={pcbData[`${field.fieldName}UOM`]}
                                                            options={JSON.parse(field.uoMs)}
                                                            onChange={(value: any) => onUOMValueChange(value, `${field.fieldName}UOM`)}
                                                        />
                                                    </Col> : <></>
                                            }
                                        </Row >
                                    )
                                case 'DropDown':
                                    return (
                                        <Row key={`${field.fieldName}${indexQ}_${indexF}`}>
                                            <Col span={18}>

                                                <Form.Item
                                                    initialValue={pcbData[field.fieldName]}
                                                    name={["PCBQuestions", `${field.fieldName}`]}
                                                    rules={[
                                                        { required: item.isRequired, message: '' }
                                                    ]}
                                                    label={field.displayName}
                                                    style={{ display: field.active ? "block" : "none", marginBottom: "5px" }}
                                                    {...dropdownControlLayout}
                                                >
                                                    <Select
                                                        placeholder={field.displayName}
                                                        disabled={isDisabled}
                                                        onChange={(value: any) => handleValueChange(value, field.fieldName, field)}
                                                    >
                                                        <Option
                                                            key={`${field.fieldName}${indexQ}_${indexF}_0`}
                                                            value=''>-- Select {field.displayName} -- </Option>
                                                        {JSON.parse(field.possibleValues) && JSON.parse(field.possibleValues).length > 0 &&
                                                            JSON.parse(field.possibleValues).map((option: any) => (
                                                                <Option
                                                                    key={`${field.fieldName}${indexQ}_${indexF}_${option.value}`}
                                                                    value={option.value}>
                                                                    {option.displayValue}
                                                                </Option>
                                                            ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    )
                                case "Radio":
                                    return (
                                        <Row key={`${field.fieldName}${indexQ}_${indexF}`}>
                                            <Col span={24}>
                                                <Form.Item
                                                    initialValue={pcbData[field.fieldName]}
                                                    name={["PCBQuestions", `${field.fieldName}`]}
                                                    label={field.displayName}
                                                    style={{ display: field.active ? "block" : "none", marginBottom: "5px" }}
                                                    {...radioControlLayout}
                                                >
                                                    <Radio.Group
                                                        disabled={isDisabled}
                                                        onChange={(e: any) => handleValueChange(e.target.value, field.fieldName, field)}>
                                                        {JSON.parse(field.possibleValues) && JSON.parse(field.possibleValues).length > 0 &&
                                                            JSON.parse(field.possibleValues).map((option: any) => (
                                                                <Radio
                                                                    style={{ marginBottom: '5px', paddingLeft: "5px" }}
                                                                    key={`${field.fieldName}${indexQ}_${indexF}_${option.value}`}
                                                                    value={option.value}>
                                                                    {option?.description == null ?
                                                                        option.displayValue :
                                                                        <Tooltip className="cm_newline" title=
                                                                            {
                                                                                <ul className="cm_list_style_type_none">{option?.description.split("\n").map((value: any) => (<li>{value}</li>))}</ul>
                                                                            }
                                                                        >{option.displayValue}</Tooltip>
                                                                    }
                                                                </Radio>
                                                            ))}
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    )
                                case "TextArea":
                                    return (
                                        <Row key={`${field.fieldName}${indexQ}_${indexF}`}>
                                            <Col span={24}>
                                                <Form.Item
                                                    initialValue={pcbData[field.fieldName]}
                                                    name={["PCBQuestions", `${field.fieldName}`]}
                                                    label={field.displayName}
                                                    style={{ display: field.active ? "block" : "none" }}
                                                    {...textAreaControlLayout}
                                                >
                                                    <Input.TextArea
                                                        style={{ maxHeight: '100px' }}
                                                        disabled={isDisabled}
                                                        onChange={(e: any) => handleValueChange(e.target.value, field.fieldName, field)}
                                                        rows={3}
                                                        showCount maxLength={4000} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    )
                            }
                        })
                    )
                })
            }
        </>
    )
} 