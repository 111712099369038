import { Button, Descriptions, Modal, Image, Typography } from "antd";
import { useLayoutEffect, useState } from "react";
import usePricingService from "../../hooks/UsePricingService";
import { CSVLink } from "react-csv";
import { Export } from "../../assets/icons";

export const PCBAnswer = ({
  pcbAnswerid,
  isPCBAnswerVisible,
  onCancelPCBAnswer,
  projectName,
  supplierNotes
}: any) => {
  const { getPCBAnswerAction } = usePricingService();
  const [pcbAnswers, setPCBAnswers] = useState<any>({});

  const getPcbAnswer = async (data: any) => {
    const response = (await getPCBAnswerAction(data)) as any;

    let pcbAnswers: any = {};
    let answers = JSON.parse(response.answers);
    for (const key in answers) {
      let newKey = response.questions[key];
      pcbAnswers[newKey] = answers[key];
    }
    setPCBAnswers(pcbAnswers);
  };

  useLayoutEffect(() => {
    (async () => {
      const { pricingId } = pcbAnswerid;
      if (pricingId) {
        await getPcbAnswer(pricingId);
      }
    })();
  }, [pcbAnswerid]);

  return (
    <Modal
      visible={isPCBAnswerVisible}
      centered
      title={
        <>
          <Typography.Text>PCB Answer</Typography.Text>
          <Typography.Text style={{ float: "right", paddingRight: "25px" }}>
            <b>Supplier Name: </b>{isPCBAnswerVisible.supplierName}
          </Typography.Text>
        </>}
      cancelText="Cancel"
      width={700}
      maskClosable={false}
      onCancel={onCancelPCBAnswer}
      footer={null}
    >
      <>
        <Typography.Text
          style={{ maxHeight: '100px', overflowY: 'auto', display: 'block', marginBottom: '10px' }}>
          <b>Supplier Notes:</b> {supplierNotes}</Typography.Text>
        {pcbAnswers && Object.keys(pcbAnswers)?.map((key: any) => {
          return (
            <>
              <Descriptions layout="horizontal" bordered size="middle">
                <Descriptions.Item
                  labelStyle={{ width: 300 }}
                  label={key}
                >
                  {pcbAnswers[key]}
                </Descriptions.Item>
              </Descriptions>
            </>
          );
        })}
        {pcbAnswers &&
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              marginTop: "8px",
            }}
          >
            <CSVLink
              data={Object.entries(pcbAnswers)}
              filename={`PCBAnswer_${projectName}_${isPCBAnswerVisible.supplierName}.csv`}
            >
              <Button
                id="pcb_csvLink"
                icon={<Image src={Export} width={15} height={15} preview={false} />}
                size="middle"
                style={{ backgroundColor: "#046AED", color: "white" }}
              >
                <span style={{ marginLeft: '8px' }}>Export PCB Answer</span>
              </Button>
            </CSVLink>
          </div>}

      </>
    </Modal>
  );
};
