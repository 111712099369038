export const ErrorConstants = {
    internalServer: {
        statusCode: 500,
        title: "An error occurred while processing your request.",
        message: "We're sorry for the inconvenience. Please try again after some time.",
    },
    pageNotFound: {
        statusCode: 404,
        title: "404",
        message:
            "The resource you're looking for does not exist or has been removed.",
    },
    accessDenied: {
        statusCode: 401,
        title: "Unauthorized",
        message:
            "You're not allowed to access this resource.",
    },
    badRequest: {
        statusCode: 400,
        title: "BadRequest",
        message:
            "Invalid Request.",
    }
};
