import { AutoComplete, Checkbox, Col, Form, Input, Modal, Row } from "antd"
import UseSupplierService from "../hooks/UseSupplierService";
import useSessionStorageService from '../hooks/UseSessionStorageService';
import { MESSAGE_CONSTANT } from "../constants/MessageConstants";
import Notification from '../components/notification/Notification';
import { ErrorConstants } from "../constants/ErrorConstants";
import { useCallback, useEffect, useState } from "react";


export const AddVendor = ({ isSupplierModalVisible, hideSupplierModel, cancelSupplierModel, addVendorResponse, supplierUserAssociationId }: any) => {

    const [form] = Form.useForm();

    const { getUserName } = useSessionStorageService();
    const { addSupplier, getSupplierTypeAction, getSupplierById, updateSupplierType } = UseSupplierService();
    const [supplierType, setSupplierType] = useState([]);
    const [supplierData, setSupplierData] = useState<any>({});

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 },
    };

    const SupplierType = useCallback(() => {
        let supplierTypeId = getSupplierTypeAction();
        return supplierTypeId;
    }, [])

    useEffect(() => {
        SupplierType().then((supplierType: any) => setSupplierType(supplierType));
    }, [SupplierType])

    useEffect(() => {
        (async () => {
            if (supplierUserAssociationId > 0) {
                let response = await getSupplierById({ supplierUserAssociationId: supplierUserAssociationId }) as any;
                setSupplierData(response);
                form.setFieldsValue({
                    supplierName: response?.supplierName,
                    supplierTypeIds: response?.supplierTypeIds
                });
            }
        })();
    }, [supplierUserAssociationId])


    const onFinish = async (values: any) => {
        if (supplierData && supplierData.supplierName) {
            await updateSupplier(values);
        }
        else {
            await insertSupplier(values);
        }
    }

    const insertSupplier = async (values: any) => {
        let reqObj = {
            SupplierName: values.supplierName.trim(),
            UserName: getUserName(),
            SupplierTypeIds: values.supplierTypeIds,
            // PreferenceOrder: values.preferenceOrder,
        }

        let responseData: any = await addSupplier(reqObj);
        if (responseData) {
            if (responseData?.statusCode === ErrorConstants.badRequest.statusCode) {
                Notification('error', 'Error', responseData?.message);
                return;
            }
            Notification('success', 'Success', MESSAGE_CONSTANT.supplierCreatedSuccessfully);
            hideSupplierModel(true, responseData);
            form.resetFields();
            setSupplierData({});
        }
    }

    const updateSupplier = async (values: any) => {
        let reqObj = {
            supplierUserAssociationId: supplierUserAssociationId,
            supplierTypeIds: values.supplierTypeIds
        }

        await updateSupplierType(reqObj);
        Notification('success', 'Success', MESSAGE_CONSTANT.supplierUpdatedSuccessfully);
        form.resetFields();
        setSupplierData({});
        hideSupplierModel(false);
    }

    const onCancelSupplierModal = () => {
        form.resetFields();
        setSupplierData({});
        cancelSupplierModel();
    }

    return (
        <Modal title="Add Supplier"
            visible={isSupplierModalVisible}
            okText="Save"
            cancelText="Cancel"
            maskClosable={false}
            width={650}
            onOk={form.submit}
            onCancel={onCancelSupplierModal}
        >
            <Form
                {...layout}
                form={form}
                name="register"
                onFinish={onFinish}
                scrollToFirstError
            >

                <Form.Item
                    name="supplierName"
                    label="Supplier Name"
                    rules={[{ required: true, message: 'Please enter Supplier Name' }]}>
                    <Input
                        disabled={supplierData && supplierData.supplierName}
                        placeholder='Supplier Name' maxLength={100} />
                </Form.Item>
                <Form.Item
                    name="supplierTypeIds"
                    label="Supplier Type"
                    rules={[{ required: true, message: '*Required', type: "array" }]}>
                    <Checkbox.Group style={{ width: '100%' }}>
                        <Row>
                            {supplierType.map((data: any) => (
                                <Col span={10} key={data.id}>
                                    <Checkbox value={data.id}>{data.type}</Checkbox>
                                </Col>
                            ))}
                        </Row>
                    </Checkbox.Group>
                </Form.Item>

                {/* <Form.Item
                    name="preferenceOrder"
                    label="Preference Order"
                    rules={[ 
                        { pattern: new RegExp(/^[0-9]{1,10}(\.[0-9]{0,9})?$/), message: 'Please enter valid Preference Order number' }
                    ]}>
                    <Input size="large"
                         placeholder='Preference Order' maxLength={10} />
                </Form.Item> */}
            </Form>

        </Modal>
    )
}
