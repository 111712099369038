import { Col, Layout, Row, Typography } from "antd";
import AppRoutes from './AppRoutes';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import React, { useLayoutEffect, useMemo, useState } from 'react';
import { authService } from './hooks/authService';
import useSessionStorageService from './hooks/UseSessionStorageService';
import useUserService from './hooks/UseUserService';
import { BoardCQHeader } from './components/layout/Header';
import { BoardCQFooter } from './components/layout/Footer';
import { SideBar } from './components/layout/SideBar';
import './components/styles/global.css';
import { LoadingSpinner } from "./components/layout/LoadingSpinner";
import { Route, Routes } from "react-router-dom";
import { Login } from "./pages/Login";
import { DATA_CONSTANT } from "./constants/DataConstants";
import { getParams } from "./components/helpers/getParams";

declare global {
  interface Window {
    HubSpotConversations: any;
    _hsq: any;
  }
}

function App() {

  const { isCalledFromWidget } = getParams();

  const { setUserFullName, setUserName, setDefaultView, setUserView, getUserView } = useSessionStorageService();
  const { addUserDetailsAction, addUserAccessibleClientsAction } = useUserService();
  const [user, setUser] = useState('');
  const [hasAccessToApplication, setHasAccessToApplication] = useState('');
  const { Content } = Layout;

  useLayoutEffect(() => {
    userFunc();
  }, []);

  const userFunc = async () => {
    const user = await authService.getUser();
    if (user) {
      const userFullName = `${user.profile?.FirstName} ${user.profile?.LastName}`;
      const userEmail = user.profile?.Email || '';
      const requestData = {
        userId: user?.profile?.sub,
        firstName: user?.profile?.FirstName,
        lastName: user?.profile?.LastName,
        email: userEmail,
        DefaultView: getParams().view === DATA_CONSTANT.UserTypes.Supplier ? DATA_CONSTANT.UserTypes.Supplier : DATA_CONSTANT.UserTypes.Buyer
      };
      let userDetails = await addUserDetailsAction(requestData) as any;

      setHasAccessToApplication(user?.profile?.HasAccessToApplication);
      setDefaultView(userDetails.defaultView);
      setUserView(getUserView() || userDetails.defaultView);
      setUserFullName(userFullName);
      setUserName(userEmail);
      setUser(userFullName);
      if (!userDetails.clientGetDate) {
        addUserAccessibleClients(userEmail);
      }
      
      if (isCalledFromWidget || user.profile?.HasAccessToApplication?.toLocaleLowerCase() == 'false') {
      }
      else {
        if (process.env.REACT_APP_IS_HUBSPOT_ENABLED === "true") {
          var userInfo = {
            email: userEmail,
            firstname: user?.profile?.FirstName,
            lastname: user?.profile?.LastName,
          }
          initHubSpotWidget(userInfo);
        }

        if (process.env.REACT_APP_IS_LOGROCKET_ENABLED === "true") {
          const logrocketAppId: string = process.env.REACT_APP_IS_LOGROCKET_APPID || '';
          LogRocket.init(logrocketAppId);
          LogRocket.identify(userFullName, {
            name: userFullName,
            email: userEmail
          })
          setupLogRocketReact(LogRocket);
        }
      }
    }
    else {
      authService.login(encodeURIComponent(window.location.href));
    }
  }

  const addUserAccessibleClients = async (userEmailId: any) => {
    await addUserAccessibleClientsAction({ userEmailId: userEmailId });
  }

  const initHubSpotWidget = (userInfo: any) => {
    const hubSpotAppId = process.env.REACT_APP_HUBSPOT_APP_ID || '';
    
    var script = document.createElement('script');
    script.id = "hs-script-loader";
    script.async = true;
    script.defer = true;
    script.type = 'text/javascript';
    script.src = `//js.hs-scripts.com/${hubSpotAppId}.js`;
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      const _hsq = window._hsq = window._hsq || [];

      _hsq.push(["identify", userInfo]);
      _hsq.push(["trackPageView"]);

      if (window?.HubSpotConversations) {
        const status = window.HubSpotConversations.widget.status();
        if (status.loaded) {
          window?.HubSpotConversations.widget.refresh();
        } else {
          window?.HubSpotConversations.widget.load();
        }
      }
    });
  }

  const renderTemplate = () => {
    if (hasAccessToApplication.toLocaleLowerCase() == 'true') {
      if (isCalledFromWidget) {
        return widgetLayout;
      }
      else {
        return mainLayout;
      }
    }
    else {
      return (
        <Row>
          <Col span={12} push={6}>
            <Typography.Title level={3}> You don't have permission to access this Application.</Typography.Title>
          </Col>
        </Row>
      )
    }
  }

  const mainLayout = (
    <Layout>
      <BoardCQHeader />
      <Layout>
        <SideBar />
        <Layout
          style={{ padding: '10px 0 10px 10px' }}>
          <Content className="content cm-scrollbar" style={{ padding: '5px', margin: '0%', backgroundColor: 'white' }}>
            <LoadingSpinner />
            <AppRoutes />
          </Content>
        </Layout>
      </Layout>
      <BoardCQFooter />
    </Layout>
  );

  const widgetLayout = (
    <Layout>
      <Content className="content cm-scrollbar" style={{ padding: '5px', margin: '0%', backgroundColor: 'white', height: 'calc(100vh - 5px)' }}>
        <LoadingSpinner />
        <AppRoutes />
      </Content>
    </Layout>
  );

  const LayoutTag = useMemo(() => {
    let tag: React.ReactNode;
    if (!user) {
      tag = (<Routes><Route element={<Login />} path='/' /> <Route element={<Login />} path='/login' /></Routes>);
    } else {
      tag = (
        <>{renderTemplate()}</>
      );
    }
    return tag
  }, [Content, user, hasAccessToApplication])

  return LayoutTag;

}

export default App;
