import React, { useState, useEffect } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import usePricingService from '../../hooks/UsePricingService';
import '../styles/supplierRankingList.css';
import useSessionStorageService from '../../hooks/UseSessionStorageService';
import { ConvertOrdinal } from "../helpers/utility";
import { Checkbox, Tooltip, Image, Row, Col, Typography } from "antd";
import { RetweetOutlined } from '@ant-design/icons';
import { PCBAnswer } from '../pcb/PCBAnswer';
import { Eye } from '../../assets/icons';
import { trackPromise } from 'react-promise-tracker';

export const PCBRanking = (props: any) => {
    const { selectedSupplier, isResetTab, isReloadData, projectName } = props;

    const [pricingData, setPricingData] = useState<any>({});
    const { getPCBPricingAction } = usePricingService();
    const { getUserName } = useSessionStorageService();
    const { getUserView, getDefaultView } = useSessionStorageService();
    const [showPCBAnswer, setShowPCBAnswer] = useState<any>({ visible: false, pricingid: '', supplierName: '' });
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        trackPromise(getPCBPricing());
    }, []);

    useEffect(() => {
        if (isReloadData) {
            trackPromise(getPCBPricing());
        }
    }, [isReloadData]);

    useEffect(() => {
        if (isResetTab && !disabled) {
            let newPricingData = { ...pricingData };
            newPricingData.pricings?.forEach((p: any) => p.isPricingSelected = false);
            setPricingData(newPricingData);

            selectedSupplier({ pricingId: 0 });
        }
    }, [isResetTab])

    const callCheckedFunc = (value: any) => {
        const checkedFuncdata = pricingData.pricings.map((item: any) => {
            item.isPricingSelected = (item.pricingId === value)
            return item;
        });

        pricingData.pricings = checkedFuncdata;
        setPricingData(pricingData);
    };

    const getPCBPricing = async () => {
        let userName = getUserView() === 'supplier' || getDefaultView() === 'supplier' ? getUserName() : '';
        let response = await getPCBPricingAction(props.projectId, userName) as any;
        setPricingData(response);

        let isPricingSelected = response.pricings.some((x: any) => x.isPricingSelected === true);
        setDisabled(isPricingSelected);
    }

    const visiblePCBAnswer = (item: any) => {
        setShowPCBAnswer({ visible: true, pricingId: item.pricingId, supplierName: item.supplierName, supplierNotes: item.notes });
    }

    const cancelPCBAnswer = () => {
        setShowPCBAnswer({ visible: false });
    }

    return (
        <>
            {pricingData.pricings && pricingData.pricings.length > 0 &&
                <div className=' tableX-scroll'>
                    <table className="table table-bordered table-hover ranking-table">
                        <thead>
                            <tr key='Heading1'>
                                <th style={{ display: getUserView() === 'supplier' ? 'none' : '' }}>Select Supplier</th>
                                <th style={{ padding: '10px 10px' }}>Supplier Name</th>
                                {getUserView() !== 'supplier' && <th>Supplier Contact Name</th>}
                                {pricingData.quantities && pricingData.quantities.map((item: any, index: number) => {
                                    const qtyPricingItem = 'quantityPricings_' + pricingData.quantities[index].id as any;
                                    let colSpan = pricingData.pricings[0][qtyPricingItem].length;

                                    (pricingData.pricings[0][qtyPricingItem]).forEach((turnTime: any) => {
                                        if (pricingData.refTurnTimeIds.includes(turnTime.turnTimeId).toString() === 'true')
                                            colSpan++;
                                    })

                                    return (
                                        <th colSpan={colSpan} key={index}>
                                            Quantity {item.qty}
                                        </th>
                                    )
                                })}
                                <th style={{ padding: '10px 10px' }}>NRE &nbsp;&nbsp;&nbsp;</th>
                                <th style={{ width: '10%', display: getUserView() === 'supplier' ? 'none' : '' }}>PCB Answer</th>
                            </tr>
                            <tr key='Heading2'>
                                <th></th>
                                <th style={{ display: getUserView() === 'supplier' ? 'none' : '' }}></th>
                                {getUserView() !== 'supplier' && <th></th>}
                                {pricingData.quantities && pricingData.quantities.map((item: any, index: number) => {
                                    const qtyPricingItem = 'quantityPricings_' + pricingData.quantities[index].id as any;
                                    return (
                                        pricingData.pricings[0][qtyPricingItem].map((turnTimeItem: any, tIndex: number) => (
                                            <React.Fragment key={`frTurnTime_${index}_${tIndex}`}>
                                                <th key={`thbuyerTurnTime_${index}_${tIndex}`}
                                                    className={pricingData.refTurnTimeIds.includes(turnTimeItem.turnTimeId).toString() === 'true' ? 'cm-border-left cm-border-top cm-supplier-pricing-color' : ''}>
                                                    <Row>
                                                        <Col span={24}>
                                                            Quote TurnTime {turnTimeItem.turnTime} {turnTimeItem.turnTimeUOM}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={24}>
                                                            Unit Price
                                                        </Col>
                                                    </Row>
                                                </th>

                                                {
                                                    pricingData.refTurnTimeIds.includes(turnTimeItem.turnTimeId).toString() === 'true' &&
                                                    (<th key={`thsupplierTurnTime_${index}_${tIndex}`}
                                                        style={{ padding: '0px' }} className='cm-border-top cm-border-right cm-supplier-pricing-color'>
                                                        <Row className='cm-table-border-bottom cm-padding5'>
                                                            <Col span={24}>
                                                                <Typography.Text style={{ visibility: 'hidden' }}>Supplier</Typography.Text>
                                                                <Typography.Text>Supplier</Typography.Text>
                                                                <Typography.Text style={{ visibility: 'hidden' }}>Supplier</Typography.Text>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col span={12} className='cm-padding5'>
                                                                <Typography.Text title='Supplier TurnTime'>TurnTime</Typography.Text>
                                                            </Col>
                                                            <Col span={12} className='cm-padding5'>
                                                                <Typography.Text title='Supplier Unit Price'>Unit Price</Typography.Text>
                                                            </Col>
                                                        </Row>
                                                    </th>)
                                                }
                                            </React.Fragment>
                                        )))
                                })}
                                <th></th>
                                <th style={{ display: getUserView() === 'supplier' ? 'none' : '' }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {pricingData.pricings && pricingData.pricings.length > 0 && pricingData.pricings.map((item: any, pricingIndex: any) => (
                                <React.Fragment key={`fr_${pricingIndex}`}>
                                    <tr key={`trSupplier_${pricingIndex}`}
                                        className={item.isPricingSelected === true ? 'cm-selected-pricing' : ''}>
                                        <td style={{ display: getUserView() === 'supplier' ? 'none' : '' }}>
                                            <Checkbox value={item.isPricingSelected} checked={item.isPricingSelected === true ? true : false} disabled={disabled}
                                                onChange={() => {
                                                    callCheckedFunc(item.pricingId);
                                                    selectedSupplier({ pricingId: item.pricingId });
                                                }} /></td>
                                        <td style={{ padding: '10px 10px' }}>
                                            {item.supplierName ? item.supplierName : 'Supplier ' + pricingIndex}
                                            {
                                                (getUserView() === 'supplier') || item.isSameCurrency ? <></> :
                                                    <Tooltip title='Currency conversion applied'>
                                                        <RetweetOutlined style={{ color: 'blue', fontWeight: 'bolder', fontSize: '18px' }} className='ms-2' />
                                                    </Tooltip>
                                            }
                                        </td>
                                        {
                                            getUserView() !== 'supplier' &&
                                            <td style={{ padding: '10px 10px' }}>
                                                <div style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{item.supplierContactName}</div>
                                            </td>
                                        }
                                        {pricingData.quantities && pricingData.quantities.map((itemQty: any, qtyIndex: number) => {
                                            const qtyPricingItem = 'quantityPricings_' + pricingData.quantities[qtyIndex].id as any;
                                            return (
                                                pricingData.pricings[pricingIndex][qtyPricingItem].map((turnTimeObj: any, tIndex: number) => ((
                                                    <React.Fragment key={`pricing_${qtyIndex}_${tIndex}`}>
                                                        <td key={`buyerPrice_${qtyIndex}_${tIndex}`} className={pricingData.refTurnTimeIds.includes(turnTimeObj.turnTimeId).toString() === 'true' ? 'cm-border-left cm-supplier-pricing-color' : ''}>
                                                            <Row className='cm-text-right'>
                                                                <Col span={16}>
                                                                    {
                                                                        turnTimeObj.unitPrice !== null ?
                                                                            ((getUserView() === 'supplier' ? item.supplierCurrencyUnicode : item.projectCurrencyUnicode) + turnTimeObj.unitPrice) :
                                                                            ConvertOrdinal(turnTimeObj.rank)
                                                                    }
                                                                </Col>
                                                                <Col span={2}>
                                                                    {turnTimeObj.isMinimumTurnTimePrice ?
                                                                        <CheckOutlined style={{ color: 'green', fontWeight: 'bolder', fontSize: '12px' }} className='ms-2' /> : <></>}
                                                                </Col>
                                                            </Row>
                                                        </td>

                                                        {
                                                            pricingData.refTurnTimeIds.includes(turnTimeObj.turnTimeId).toString() === 'true' &&
                                                            (turnTimeObj.supplierTurnTimeAndUnitPrices && turnTimeObj.supplierTurnTimeAndUnitPrices.length > 0
                                                                ?
                                                                turnTimeObj.supplierTurnTimeAndUnitPrices.map((supplierTurnTime: any) => (
                                                                    <td key={`supplierPrice_${supplierTurnTime.turnTimeId}`} className='cm-border-right cm-supplier-pricing-color'>
                                                                        <Row>
                                                                            <Col span={12}>
                                                                                {supplierTurnTime.turnTime} {supplierTurnTime.turnTimeUOM}
                                                                            </Col>
                                                                            <Col span={12}>
                                                                                <Row className='cm-text-right'>
                                                                                    <Col span={16}>
                                                                                        {
                                                                                            supplierTurnTime.unitPrice !== null ?
                                                                                                ((getUserView() === 'supplier' ? item.supplierCurrencyUnicode : item.projectCurrencyUnicode) + supplierTurnTime.unitPrice) :
                                                                                                ConvertOrdinal(supplierTurnTime.rank)
                                                                                        }
                                                                                    </Col>
                                                                                    <Col span={2}>
                                                                                        {supplierTurnTime.isMinimumTurnTimePrice ?
                                                                                            <CheckOutlined style={{ color: 'green', fontWeight: 'bolder', fontSize: '12px' }} className='ms-2' /> : <></>}
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                        </Row>
                                                                    </td>
                                                                ))
                                                                :
                                                                <td key={`supplierNoPrice_${tIndex}`} className='cm-border-right cm-supplier-pricing-color'>No Price</td>)
                                                        }
                                                    </React.Fragment>
                                                ))))
                                        })}
                                        <td>
                                            <Row className='cm-text-right'>
                                                <Col span={16}>
                                                    {
                                                        item.nre !== null ?
                                                            ((getUserView() === 'supplier' ? item.supplierCurrencyUnicode : item.projectCurrencyUnicode) + item.nre) :
                                                            ConvertOrdinal(item.nreRank)
                                                    }
                                                </Col>
                                                <Col span={2}>
                                                    {item.isMinimumNRE ?
                                                        <CheckOutlined style={{ color: 'green', fontWeight: 'bolder', fontSize: '12px' }} className='ms-2' /> : <></>}
                                                </Col>
                                            </Row>
                                        </td>
                                        <td style={{ display: getUserView() === 'supplier' ? 'none' : '' }}>
                                            <Tooltip title="View Pricing">
                                                <Image src={Eye} alt="" width={20} height={20} preview={false} onClick={() => visiblePCBAnswer(item)} />
                                            </Tooltip>
                                        </td>
                                    </tr>
                                    {(item.supplierName && getUserView() === 'supplier' && pricingData.pricings.length > 1) && (
                                        <tr style={{ backgroundColor: '#F6F6F7' }}>
                                            <th colSpan={100}>
                                                Ranking
                                            </th>
                                        </tr>)}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            }
            {
                showPCBAnswer?.visible &&
                <PCBAnswer
                    pcbAnswerid={showPCBAnswer}
                    isPCBAnswerVisible={showPCBAnswer}
                    onCancelPCBAnswer={cancelPCBAnswer}
                    projectName={projectName}
                    supplierNotes={showPCBAnswer.supplierNotes}
                />
            }
        </>
    )
}