
import { BuyerDashBoard } from './pages/dashBoard/BuyerDashboard';
import AboutPage from './pages/AboutPage';
import ErrorPage from './pages/error/ErrorPage';
import { PcbGrid } from './pages/PcbGrid';
import { Vendor } from './pages/Vendor';
import { AddQuote } from './pages/AddQuote';
import { AddVendorContact } from './pages/AddVendorContact';
import { SupplierDashBoard } from './pages/dashBoard/SupplierDashboard';
import { Ranking } from './pages/Ranking';
import { SupplierPricing } from './components/pricing/SupplierPricing';
import CurrencyConversion from './components/currency/CurrencyConversion';

const HomeRoutes = [
  {
    path: 'buyer',
    component: <BuyerDashBoard />,
  },
  {
    path: 'supplier',
    component: <SupplierDashBoard />,

  },
  {
    path: 'supplierpricing',
    component: <SupplierPricing />,
  },
  {
    path: 'quotes',
    component: <PcbGrid />,

  },
  {
    path: 'add-quote',
    component: <AddQuote />,

  },
  {
    path: 'update-quote',
    component: <AddQuote />,

  },
  {
    path: 'vendor',
    component: <Vendor />,

  },
  {
    path: 'edit-vendorcontact',
    component: <AddVendorContact />,

  },
  {
    path: 'add-vendorcontact',
    component: <AddVendorContact />,

  },
  {
    path: 'about',
    component: <AboutPage />,

  },
  {
    path: 'error',
    component: <ErrorPage />,

  },
  {
    path: 'ranking',
    component: <Ranking />,

  },
  {
    path: 'ranking',
    component: <Ranking />,

  },
  {
    path:'currencyconversion',
    component:<CurrencyConversion />
  }
];

export default HomeRoutes;