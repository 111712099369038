import { URL_CONSTANT } from "../constants/UrlConstants";
import useCommonHttpService from "./useCommonHttpService";

export default function useQuoteService() {
    const { httpPost, httpGet, httpFilePutToS3 } = useCommonHttpService();

    function addQuote(data: any) {
        return httpPost(URL_CONSTANT.Quote.AddQuote, data);
    }

    function updateQuote(data: any) {
        return httpPost(URL_CONSTANT.Quote.EditQuote, data);
    }

    function deleteQuantity(data: any) {
        return httpPost(URL_CONSTANT.Quote.DeleteQuantity, data);
    }

    function deleteTurnTime(data: any) {
        return httpPost(URL_CONSTANT.Quote.DeleteTurnTime, data);
    }

    function getAllQuote(quoteRequest: any) {
        return httpPost(URL_CONSTANT.Quote.GetAllQuote, quoteRequest);
    }

    function getAllTurnTimeUom() {
        return httpGet(URL_CONSTANT.Quote.GetAllTurnTimeUom, null)
    }

    function getQuoteByIdAction(obj: any) {
        return httpGet(URL_CONSTANT.Quote.GetQuoteById, obj);
    }

    function getQuoteByExternalProjectIdAction(obj: any) {
        return httpGet(URL_CONSTANT.Quote.GetQuoteByExternalProjectId, obj);
    }

    function getQuoteBySupplierContact(userName: any) {
        return httpPost(URL_CONSTANT.Quote.GetQuoteBySupplierContact, userName);
    }

    return {
        addQuote, updateQuote, deleteQuantity, deleteTurnTime, getAllQuote, getQuoteByIdAction, getAllTurnTimeUom, getQuoteBySupplierContact,
        getQuoteByExternalProjectIdAction
    };
}