import { URL_CONSTANT } from "../constants/UrlConstants";
import useCommonHttpService from "./useCommonHttpService";

export default function useSupplierContactService() {
    const { httpPost, httpGet } = useCommonHttpService();

    function getAllSupplierContacts(userName: any) {
        let url = `${URL_CONSTANT.SupplierContact.GetAllSupplierContacts}?userName=${userName}`
        return httpGet(url, null);
    }

    function addSupplierContact(data: any) {
        return httpPost(URL_CONSTANT.SupplierContact.AddSupplierContact, data);
    }

    function updateSupplierContact(data: any) {
        return httpPost(URL_CONSTANT.SupplierContact.UpdateSupplierContact, data);
    }

    function getSupplierContact(data: any) {
        return httpGet(URL_CONSTANT.SupplierContact.GetSupplierContact, data);
    }

    function getPCBSupplierResponseTime(data: any, userName: any) {
        let url = `${URL_CONSTANT.SupplierContact.GetPCBSupplierResponseTime}?projectId=${data}&userName=${userName}`
        return httpGet(url, null);
    }

    function getStencilSupplierResponseTime(data: any, userName: any) {
        let url = `${URL_CONSTANT.SupplierContact.GetStencilSupplierResponseTime}?projectId=${data}&userName=${userName}`
        return httpGet(url, null);
    }

    return {
        getAllSupplierContacts, addSupplierContact, updateSupplierContact, getSupplierContact,
        getPCBSupplierResponseTime, getStencilSupplierResponseTime
    };
}