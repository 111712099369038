import { URL_CONSTANT } from '../constants/UrlConstants';
import useCommonHttpService from './useCommonHttpService'

export default function usePricingService() {
    const { httpPost, httpGet } = useCommonHttpService();

    function addPricing(data: any) {
        return httpPost(URL_CONSTANT.Pricing.AddPricing, data);
    }

    function getPCBPricingAction(projectId: any, userName: any) {
        let url = `${URL_CONSTANT.Pricing.GetPCBPricing}?projectId=${projectId} &userName=${userName}`
        return httpGet(url, null);
    }
    function getStencilProjectPricing(projectId: any, userName: any) {
        let url = `${URL_CONSTANT.Pricing.GetStencilProjectPricing}?projectId=${projectId} &userName=${userName}`
        return httpGet(url, null);
    }

    function getPricingById(data: any) {
        return httpGet(URL_CONSTANT.Pricing.GetPricingById, data);
    }

    function getPCBAnswerAction(pricingId: number) {
         let url = `${URL_CONSTANT.Pricing.GetPCBAnswer}?pricingId=${pricingId}`;
        return httpGet(url, null);
    }

    function updatePricingStatus(pricingId: number) {
        let url = `${URL_CONSTANT.Pricing.UpdatePricingStatus}?pricingId=${pricingId}`;
       return httpGet(url, null);
   }

    return { addPricing, getPCBPricingAction, getPricingById, getPCBAnswerAction, updatePricingStatus, getStencilProjectPricing };
}   