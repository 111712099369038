export const ConvertOrdinal = (rankNumber: number) => {
    var s = ["th", "st", "nd", "rd"];
    var v = rankNumber % 100;
    return rankNumber + (s[(v - 20) % 10] || s[v] || s[0]);
}

export const customTagColor = (text: string): string => {
  switch (text) {
    case "Pending":
      return "#FFB9B5";
    case "InProgress":
      return "#A1D5AE";
    case "Completed":
      return "#32DBBC";
    case "cancelled":
      return "#E8E8EA";
      case "Submitted":
      return "#32DBBC";
    default:
      return "";
  }
};

export const checkIsValidURL = (url: any) => {
  const regex = new RegExp('^(https?:\\/\\/)?' + // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
    '(\\#[-a-z\\d_]*)?$', 'i');
  return regex.test(url);
};
  