import React, { useCallback, useEffect, useState } from "react";
import type { ColumnsType } from "antd/es/table";
import useCurrencyConversionServices from "../../hooks/useCurrencyConversionServices";
import { CustomTable } from "../../pages/CustomTable";
import { PageHeader } from "antd";

export interface CustomType {
  enableSearch?: boolean;
}

export type CustomColumnsType<T> = CustomType & ColumnsType<T>;

export default function CurrencyConversion() {
  const { getCurrencyConversionRates } = useCurrencyConversionServices();
  const [currenyConversion, setCurrenyConversion] = useState<any>([]);
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 10,
  });

  const CurrenyConversionAPI = useCallback(async () => {
    const response = (await getCurrencyConversionRates()) as any;
    setCurrenyConversion(response);
  }, [getCurrencyConversionRates]);

  useEffect(() => {
    CurrenyConversionAPI();
  }, []);

  const currencyColumn: CustomColumnsType<any> = [
    {
      key: "FromCurrency",
      title: "From Currency",
      dataIndex: "fromCurrency",
      width: "25%",
      sorter: (a: any, b: any) => a.fromCurrency.localeCompare(b.fromCurrency),
      sortDirections: ["descend", "ascend"],
      enableSearch: true,
    },
    {
      title: "To Currency",
      dataIndex: "toCurrency",
      key: "toCurrency",
      width: "25%",
      sorter: (a: any, b: any) => a.toCurrency.localeCompare(b.toCurrency),
      sortDirections: ["descend", "ascend"],
      enableSearch: true,
    },
    {
      title: "Currency Rate",
      dataIndex: "rate",
      key: "rate",
      width: "25%",
      sorter: (a: any, b: any) => a.rate - b.rate,
      sortDirections: ["descend", "ascend"],
      enableSearch: true,
    },
    {
      key: "toDate",
      title: "Date (UTC)",
      dataIndex: "toDate",
      width: "25%",
      render: (text: any, record: any) => (
        <>
          {new Date(record.toDate).toLocaleString("default", {
            month: "numeric",
            day: "numeric",
            year: "numeric",
          })}
        </>
      ),
    },
  ];

  return (
    <>
      <PageHeader title="Currency Conversion" />
      <CustomTable
        columns={currencyColumn}
        dataSource={currenyConversion}
        rowKey="id"
        pagination={{
          pageSize: pagination.pageSize,
          onChange: (page: any, pageSize: any) => {
            setPagination({ current: page, pageSize: pageSize });
          },
        }}
      />
    </>
  );
}
