import { useLocation, useNavigate } from "react-router-dom";
import { Button, PageHeader, Tabs, Typography } from "antd";
import { LeftCircleOutlined } from "@ant-design/icons";
import useSessionStorageService from "../hooks/UseSessionStorageService";
import { PCBRanking } from "../components/ranking/PCBRanking";
import { useEffect, useState } from "react";
import usePricingService from "../hooks/UsePricingService";
import Swal from "sweetalert2";
import { MESSAGE_CONSTANT } from "../constants/MessageConstants";
import Notification from "../components/notification/Notification";
import StencilRanking from "../components/ranking/StencilRanking";
import PCBResponseTimeRanking from "../components/responseTimeRanking/PCBResponseTimeRanking";
import StencilResponseTimeRanking from "../components/responseTimeRanking/StencilResponseTimeRanking";
import { DATA_CONSTANT } from "../constants/DataConstants";
import { trackPromise } from "react-promise-tracker";
import UseQuoteService from "../hooks/UseQuoteService";
import { getParams } from "../components/helpers/getParams";

const { TabPane } = Tabs;

export const Ranking = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { clientcode, eprojectId } = getParams();
  const { quoteId, clientCode, externalProjectId } = location.state as any ?? { quoteId: 0 };

  const { getUserView, getDefaultView } = useSessionStorageService();
  const { updatePricingStatus } = usePricingService();
  const { getQuoteByIdAction, getQuoteByExternalProjectIdAction } = UseQuoteService();

  const [quoteDetail, setQuoteDetail] = useState<any>();
  const [selectedSupplierId, setSelectedSupplierId] = useState<Number>(0);

  const [isReloadPCBData, setIsReloadPCBData] = useState(false);
  const [isReloadStencilData, setIsReloadStencilData] = useState(false);

  const [isResetPCBData, setIsResetPCBData] = useState(false);
  const [isResetStencilData, setIsResetStencilData] = useState(false);

  const tabKeys = {
    PCBRanking: 'PCBRanking',
    StencilRanking: 'StencilRanking'
  }

  const [responseTimeRankingFlag, setResponseTimeRankingFlag] = useState('');

  useEffect(() => {
    if (clientcode && eprojectId) {
      navigate('/ranking', { replace: true, state: { clientCode: clientcode, externalProjectId: eprojectId } });
    }
  }, []);

  useEffect(() => {
    trackPromise(getQuoteById());
  }, []);

  const getQuoteById = async () => {
    let quoteDetail: any;
    if ((clientcode || clientCode) && (eprojectId || externalProjectId)) {
      let reqObj = {
        clientCode: clientcode || clientCode,
        externalProjectId: eprojectId || externalProjectId
      };
      quoteDetail = await getQuoteByExternalProjectIdAction(reqObj) as any;
    }
    else if(quoteId) {
      quoteDetail = await getQuoteByIdAction({ quoteId: quoteId }) as any;
    }
    else{
      return;
    }
    
    setQuoteDetail(quoteDetail);
    let activeKey = quoteDetail.isPCBQuote ? tabKeys.PCBRanking : tabKeys.StencilRanking;
    setResponseTimeRankingFlag(activeKey);
  }

  const handleBack = () => {
    getUserView() === "supplier" || getDefaultView() === "supplier"
      ? navigate("/supplier")
      : navigate("/quotes");
  };

  const updatePricingStatusAPI = async (element: any) => {
    Swal.fire({
      html: MESSAGE_CONSTANT.SelectAwarad,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Proceed",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        await trackPromise(updatePricingStatus(element));
        resetPricingData(responseTimeRankingFlag);
        reloadPricingData(responseTimeRankingFlag);
        setSelectedSupplierId(0);
        Notification("success", "Success", MESSAGE_CONSTANT.AwardSubmitted);
      }
    });
  };

  const selectedSupplier = (data: { pricingId: Number }): void => {
    setSelectedSupplierId(data.pricingId);
  };

  const changeTab = (key: string) => {
    setResponseTimeRankingFlag(key);
    selectedSupplier({ pricingId: 0 });
    resetPricingData(key);
  };

  const resetPricingData = (key: string) => {
    if (key === 'PCBRanking') {
      setIsResetPCBData(true);
      setIsResetStencilData(false);
    }
    else {
      setIsResetPCBData(false);
      setIsResetStencilData(true);
    }
  }

  const reloadPricingData = (key: string) => {
    if (key === 'PCBRanking') {
      setIsReloadPCBData(true);
    }
    else {
      setIsReloadStencilData(true);
    }
  }


  return (
    <>
      <PageHeader
        backIcon={<LeftCircleOutlined style={{ fontSize: "20px" }} />}
        title="Ranking"
        onBack={handleBack}
        key="RankingHeader"
      />

      <PageHeader
        title={`Project Name: ${quoteDetail?.projectName}`}
        key="TitleHeader"
        subTitle={[
          getUserView() === DATA_CONSTANT.UserTypes.Buyer && (
            <Button
              type="primary"
              size="small"
              disabled={!selectedSupplierId}
              onClick={() => updatePricingStatusAPI(selectedSupplierId)}
              key="updatePricingStatusAPI"
            >
              Award Business
            </Button>
          )
        ]}
        extra={[
          getUserView() === DATA_CONSTANT.UserTypes.Supplier && (
            <Typography.Title level={4} key="titleCustomerName">
              Customer Name: {quoteDetail?.customerName}
            </Typography.Title>
          )
        ]}
      />
      {quoteDetail && quoteDetail.id &&
        <Tabs
          defaultActiveKey="PCBRanking"
          size="large"
          tabBarStyle={{
            display: "inline-block",
            fontWeight: 600,
            fontSize: "36px",
            padding: "0 24px 0 24px",
          }}
          activeKey={responseTimeRankingFlag}
          onChange={(e) => changeTab(e)}
        >
          {quoteDetail && quoteDetail?.isPCBQuote &&
            <TabPane tab="PCB Ranking" key={tabKeys.PCBRanking}>
              <PCBRanking
                projectId={quoteDetail.id}
                selectedSupplier={selectedSupplier}
                isResetTab={isResetPCBData}
                isReloadData={isReloadPCBData}
                projectName={quoteDetail?.projectName}
              />
              <PCBResponseTimeRanking projectId={quoteDetail.id} />
            </TabPane>
          }
          {quoteDetail && quoteDetail?.isStencilQuote &&
            <TabPane tab="Stencil Ranking" key={tabKeys.StencilRanking}>
              <StencilRanking
                projectId={quoteDetail.id}
                selectedSupplier={selectedSupplier}
                isResetTab={isResetStencilData}
                isReloadData={isReloadStencilData}
              />
              <StencilResponseTimeRanking projectId={quoteDetail.id} />
            </TabPane>
          }
        </Tabs>
      }
    </>
  );
};
