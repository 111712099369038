import { useState, useEffect } from "react";
import { authService } from '../../hooks/authService';
import { Modal, Dropdown, Menu, Button, notification, Space, Tag, Image } from 'antd';
import { useNavigate } from 'react-router-dom';
import useUserService from "../../hooks/UseUserService";
import useSessionStorageService from "../../hooks/UseSessionStorageService";
import UploadGeneralDocument from "../uploadGeneralDocument/UploadGeneralDocument";
import { LogOut, Upload, User } from "../../assets/icons";
import { trackPromise } from "react-promise-tracker";

const TopNavMenu = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [userViewName, setUserViewName] = useState<string>();
    const [userName, setUserName] = useState('');
    const { getUserDetails } = useUserService();
    const { setDefaultView, setUserView, getUserView } = useSessionStorageService();
    const [isGeneralDocumentModalVisible, setIsGeneralDocumentModalVisible] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        trackPromise(checkUser());
    }, []);

    const userSignOut = async () => {
        await authService.logout();
        setIsModalVisible(false);
    }

    const checkUser = async () => {
        let user = await authService.getUser();
        if (user != null) {
            let userFullName = user?.profile?.FirstName + ' ' + user?.profile?.LastName;
            let userDetails = await getUserDetails(user?.profile?.Email) as any;

            setDefaultView(userDetails.defaultView);
            setUserView(getUserView() || userDetails.defaultView);
            setUserViewName(getUserView() || userDetails.defaultView);
            setUserName(userFullName);
        }
        else {
            console.log("401");
            // await authService.logout();
            // navigate('/login');
        }
    }

    const handleShow = () => {
        setIsModalVisible(true);
    };

    const openNotification = (viewName: string) => {
        notification['info']({
            message: 'View',
            description: `You are redirected to ${viewName} view`,
            placement: 'topRight',
        });
    };

    const handleUserView = () => {
        let viewName = getUserView() === "buyer" ? "supplier" : "buyer";
        setUserViewName(viewName);
        setUserView(viewName);
        openNotification(viewName);
        viewName === 'buyer' ? navigate('/buyer') : navigate('/supplier');
    }

    const handleProjectSpecificDocument = () => {
        setIsGeneralDocumentModalVisible(true)
    }
    const onCancelGeneralDocumentModal = () => {
        setIsGeneralDocumentModalVisible(false)
    }

    const menu = (
        <Menu
            items={[
                {
                    key: "UserSwitching",
                    icon: <Image src={User} width={20} height={20} preview={false} />,
                    onClick: handleUserView,
                    label: <> {userViewName === "buyer" ? <span style={{ marginLeft: '8px' }}>{" Switch as Supplier"}</span> : <span style={{ marginLeft: '8px' }}>{"Switch as Buyer"}</span>}</>
                },
                ...(userViewName === "buyer" ? [
                    {
                        key: "Document",
                        icon: <Image src={Upload} width={20} height={20} preview={false} />,
                        onClick: handleProjectSpecificDocument,
                        label: <span style={{ marginLeft: '8px' }}>{'Buyer Specific Documentations'}</span>
                    }
                ] : []),
                {
                    key: "Logout",
                    icon: <Image src={LogOut} width={15} height={15} preview={false} />,
                    onClick: handleShow,
                    label: <span style={{ marginLeft: '10px' }}>{"Logout"}</span>
                }
            ]}
        />
    );

    return (
        <>
            <Space>
                <Tag color="#f50">{getUserView() === "buyer" ? "Buyer" : "Supplier"}</Tag>
                <Dropdown overlay={menu} placement="bottomRight">
                    <Button id="userNameBtn_NavMenu" icon={<Image src={User} width={20} height={20} preview={false} />} className="cm-navbar-btn">
                        <span style={{ marginLeft: '10px' }}>{userName}</span>
                    </Button>
                </Dropdown>
            </Space>

            <Modal
                key={`Modal_${isModalVisible}`}
                visible={isModalVisible}
                onOk={userSignOut}
                onCancel={() => setIsModalVisible(false)}
                okText='Logout'
                maskClosable={false}
            >
                <p>Are you sure, you want to logout?</p>
            </Modal>
            
            <UploadGeneralDocument
                isGeneralDocumentModalVisible={isGeneralDocumentModalVisible}
                onCancelGeneralDocumentModal={onCancelGeneralDocumentModal}
            />
        </>
    );
}

export default TopNavMenu;