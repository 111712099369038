export default function useSessionStorageService() {

    function setUserFullName(userName: any) {
        return sessionStorage.setItem("username", userName);
    }

    function getUserFullName() {
        return sessionStorage.getItem("username");
    }

    function setUserName(userName: any) {
        return sessionStorage.setItem("username", userName);
    }

    function getUserName() {
        return sessionStorage.getItem("username");
    }

    function setUserView(viewName: any) {
        return sessionStorage.setItem("userView", viewName);
    }

    function getUserView() {
        return sessionStorage.getItem("userView");
    }

    function getDefaultView() {
        return sessionStorage.getItem("defaultView");
    }

    function setDefaultView(viewName: any) {
        return sessionStorage.setItem("defaultView", viewName);
    }

    return {
        setUserFullName, getUserFullName, setUserName, getUserName, setUserView,
        getUserView, getDefaultView, setDefaultView
    };
}