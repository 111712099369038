import React from 'react'
import { Button, Form, Input, Space, Row, Tooltip, PageHeader, Col, Typography, Alert } from "antd";
import { useEffect, useMemo, useState } from "react";
import UseQuoteService from "../../hooks/UseQuoteService";
import { QuantityPricing } from "../quantities/QuantityPricing";
import Notification from '../../components/notification/Notification';
import moment from "moment";
import useSessionStorageService from "../../hooks/UseSessionStorageService";
import usePricingService from "../../hooks/UsePricingService";
import { MESSAGE_CONSTANT } from "../../constants/MessageConstants";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import { PCB } from "../pcb/PCB";
import FormItem from 'antd/es/form/FormItem';
import { DATA_CONSTANT } from '../../constants/DataConstants';
import usePCBService from '../../hooks/UsePCBService';
import { Container } from "react-bootstrap";
import { trackPromise } from 'react-promise-tracker';

const PCBPricing = (props: any) => {

    const { quoteDate, projectId, quantities, isPricingDone, pricingId, currencyData, handlePricingCurrency, turnTimeUOM, isExternalQuote, buyerPCBSpecification } = props;
    const { getUserName } = useSessionStorageService();
    const { addPricing, getPricingById } = usePricingService();
    const { getPCBPricingQuestionsAction, getPCBSpecificationQuestionsAction } = usePCBService();

    const [quoteQuantities, setQuoteQuantities] = useState<any>([]);
    const [quantityData, setQuantityData] = useState<any>([]);
    const [pricing, setPricing] = useState<any>({});
    const [form] = Form.useForm();

    const [pcbQuestions, setPCBQuestions] = useState<any>([]);

    const [pcbAnswer, setPCBAnswer] = useState({});
    const [pcbAnswerData, setPCBAnswerData] = useState({});

    const [notes, setNotes] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        trackPromise(getPCBQuestions());
    }, []);

    useEffect(() => {
        if (isPricingDone) {
            return;
        }
        else if (buyerPCBSpecification !== null && Object.keys(JSON.parse(buyerPCBSpecification)).length > 0) {
            //Note: Set Buyer provided PCB Specification detail as answer. Supplier can edit and add their Specification in this fields.
            setPCBAnswer(JSON.parse(buyerPCBSpecification));
        }
        else if (pricingId == null || pricingId === 0) {
            var answerFields: any = {};
            pcbQuestions.forEach((question: any) => {
                question.pcbQuestionFields.forEach((pcbQuestionField: any) => {
                    answerFields[pcbQuestionField.fieldName] = (pcbQuestionField.defaultValue ?? '');
                    if (pcbQuestionField.unit) {
                        let fieldUOMName = `${pcbQuestionField.fieldName}UOM`;
                        answerFields[fieldUOMName] = pcbQuestionField.unit;
                    }
                });
            });
            setPCBAnswer(answerFields);
        }
    }, [pcbQuestions]);

    useEffect(() => {
        if (isPricingDone && pricingId > 0 && quantities && quantities.length > 0) {
            (async () => {
                await trackPromise(GetPricingDetail());
            })();
        }
        else {
            setQuoteQuantities(quantities);
        }
    }, [quantities])

    const getPCBQuestions = async () => {
        var response: any;
        if (isExternalQuote) {
            response = await getPCBSpecificationQuestionsAction() as any;
        }
        else {
            response = await getPCBPricingQuestionsAction() as any;
        }
        setPCBQuestions(response);
    }

    const GetPricingDetail = async () => {
        let response = await getPricingById({ pricingId: pricingId }) as any;
        setPricing(response);
        setPCBAnswer(JSON.parse(response.pcbAnswer));
        form.setFieldValue('nre', response.nre);

        handlePricingCurrency(response.currency);

        addPricingInTurnTime(response.pricingDetails);
    }

    const addPricingInTurnTime = (pricings: any) => {
        let newQuantityData = [...quantities];
        newQuantityData.forEach((quantity: any) => {
            quantity.turnTimes.forEach((turnTime: any) => {
                let unitPrice = pricings.find((p: any) => p.turnTimeId == turnTime.id)?.unitPrice;
                if (unitPrice != null) {
                    turnTime.price = unitPrice;
                }

                turnTime.supplierTurnTimeAndUnitPrice = pricings.filter((p: any) => p.refTurnTimeId == turnTime.id);
            });
        });
        setQuoteQuantities(newQuantityData);
    }

    const selectQuantityData = (data: any) => {
        setQuantityData(data);
    };

    const handlePCBAnswerData = (updatedPCBAnswer: any) => {
        setPCBAnswerData(updatedPCBAnswer);
    };

    const onFinish = async (values: any) => {
        let blankPrice = quantityData.some((x: any) =>
            x.turntimeUom.some((p: any) => ((!p.price || p.price == 0) && p.supplierTurnTimeAndUnitPrice.length === 0)
                || (p.supplierTurnTimeAndUnitPrice.some((s: any) => !s.turntime || s.turntime == 0 || (s.turntime > 0 && !s.unitPrice))
                )
            )
        );

        if (!blankPrice) {
            Swal.fire({
                html: MESSAGE_CONSTANT.ConfirmSubmitPrice,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                reverseButtons: true,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await submitQuotePrice(values);
                }
            });
        }
        else {
            Swal.fire({
                text: MESSAGE_CONSTANT.RequirePricing,
                icon: 'warning',
            });
        }
    }

    const submitQuotePrice = async (values: any) => {

        let diffTime = new Date().getTime() - new Date(quoteDate).getTime();
        let submittedDays = Math.round(diffTime / (1000 * 3600 * 24));
        let pricingDetails = [] as any;

        quantityData.forEach((qtyData: any) => {
            qtyData.turntimeUom.map((qtyTurnTime: any) => {
                let pricingData = {} as any;

                pricingData.ProjectId = projectId || 0;
                pricingData.QuantityId = qtyData.id;
                pricingData.TurnTimeId = qtyTurnTime.id;
                pricingData.UnitPrice = qtyTurnTime.price
                pricingData.supplierTurnTimes = qtyTurnTime.supplierTurnTimeAndUnitPrice.filter((t: any) => t.unitPrice > 0);
                pricingDetails.push(pricingData);
            });
        });

        const pricingModel = {
            ProjectId: projectId,
            SupplierContactUserName: getUserName(),
            NRE: values.nre || 0,
            Notes: notes,
            SubmittedDays: submittedDays,
            PricingDetails: pricingDetails,
            PCBAnswers: JSON.stringify(pcbAnswerData),
            Currency: currencyData.currency || '',
            IsPCBPricing: true,
            QuestionGroupId: pcbQuestions[0].questionGroupId
        };

        await trackPromise(addPricing(pricingModel));
        Notification('success', 'Success', MESSAGE_CONSTANT.PricingCompletedSuccessfully);
        navigate("/supplier");
    }

    const commonControlLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    };

    const radioControlLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 }
    };

    return (
        <Form scrollToFirstError
            form={form}
            onFinish={onFinish}
            labelWrap={true}
            labelAlign="left"
        >
            <Row style={{ padding: '0px 10px 10px 10px', margin: '0px 20px 20px 20px' }}>
                <Col span={11}>
                    <Container>
                        {pcbAnswer && Object.keys(pcbAnswer).length > 0 &&
                            pcbQuestions && pcbQuestions.length > 0 &&
                            <PCB
                                handlePCBAnswerData={handlePCBAnswerData}
                                pcbAnswer={pcbAnswer}
                                isDisabled={isPricingDone}
                                pcbQuestions={pcbQuestions}
                                form={form}
                                radioControlLayout={radioControlLayout}
                                textAreaControlLayout={radioControlLayout}
                                dropdownControlLayout={commonControlLayout}
                                textboxControlLayout={commonControlLayout}
                            />
                        }
                        <FormItem label="Notes"
                            labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
                        >
                            <Input.TextArea
                                style={{ color: 'black', maxHeight: '100px' }}
                                rows={3}
                                showCount maxLength={4000}
                                disabled={isPricingDone}
                                value={pricing?.notes}
                                onChange={(e) => setNotes(e.target.value)} />
                        </FormItem>
                    </Container>
                </Col>
                <Col span={13}>
                    <Form.Item>
                        {quoteQuantities && quoteQuantities.length > 0 &&
                            <QuantityPricing
                                handleQuantityData={selectQuantityData}
                                quoteQuantities={quoteQuantities}
                                projectId={projectId}
                                isPricingDone={isPricingDone}
                                unicode={currencyData.unicode}
                                turnTimeUOM={turnTimeUOM}
                            />
                        }
                    </Form.Item>

                    <Form.Item
                        labelAlign="left" labelCol={{ span: 3 }} wrapperCol={{ span: 21 }}
                        name="nre"
                        rules={[{ pattern: new RegExp(/^[0-9]{1,6}$/), message: 'Please enter valid numeric NRE amount' }]}
                        label={<>NRE <span style={{ fontWeight: 'bold', marginLeft: '5px' }}> {currencyData.unicode} </span></>}
                        style={{ paddingLeft: '8px' }}>
                        <Input size="middle" style={{ color: 'black' }}
                            maxLength={6} className='w-25' disabled={isPricingDone} />
                    </Form.Item>

                    {!isPricingDone &&
                        <Form.Item >
                            <Space>
                                <Button htmlType="submit" style={{ backgroundColor: "#046AED", color: "white" }}>
                                    Submit
                                </Button>

                                <Button htmlType="button" onClick={() => navigate("/supplier")}>
                                    Cancel
                                </Button>
                            </Space>
                        </Form.Item>
                    }
                </Col>
            </Row >
        </Form >
    )
}

export default PCBPricing