import { usePromiseTracker } from "react-promise-tracker";
import { Spin } from 'antd';

export const LoadingSpinner = (props: any) => {
    const { promiseInProgress } = usePromiseTracker({ area: props.area });

    if (promiseInProgress) {
        document.body.classList.add('overlay-box')
    } else {
        document.body.classList.remove('overlay-box')
    }
    return (promiseInProgress ?
        <Spin
            className="cm-spinner"
            spinning={promiseInProgress}
            size="large"
        />
        :
        <></>
    )
};