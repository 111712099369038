import { useNavigate } from 'react-router-dom';
import { authService } from './authService';

export default function useCommonHttpService() {
    const navigate = useNavigate();

    async function httpGet(requestURL: any, parameters: any) {
        const accessToken = await authService.getAccessToken();
        return new Promise((resolve, reject) => {

            const options = {
                method: 'get',
                headers: {
                    'content-type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${accessToken}` //need to change the auth token
                }
            };
            if (parameters && typeof (parameters) === "object") {
                Object.keys(parameters).forEach((property) => {
                    requestURL += requestURL.indexOf('?') > -1 ? "&" : "?";
                    requestURL += `${property}=${encodeURIComponent(parameters[property])}`;
                });
            }

            fetch(requestURL, options)
                .then(async (response) => {
                    const data = await response.json();
                    if (!response.ok) {
                        // get error message from body or default to response statusText
                        const error = (data && data.message) || response.statusText;
                        navigate('/error', { state: { status: response.status } });
                        reject(error);
                    } else {
                        resolve(data.data);
                    }
                })
        })
    }

    async function httpPost(requestURL: any, requestBody: any) {
        const accessToken = await authService.getAccessToken();
        return new Promise((resolve, reject) => {
            const options = {
                method: 'post',
                headers: {
                    'content-type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${accessToken}` //need to change the auth token
                },
                body: JSON.stringify(requestBody)
            }

            fetch(requestURL, options)
                .then(async response => {

                    const data = await response.json();

                    if (!response.ok) {
                        // get error message from body or default to response statusText
                        const error = (data && data.message) || response.statusText;
                        reject(error);
                        navigate('/error', { state: { status: response.status } });
                    } else {
                        resolve(data.data);
                    }

                });
        })
    }

    async function httpFilePost(requestURL: any, fileData: any) {
        const accessToken = await authService.getAccessToken();
        return new Promise((resolve, reject) => {
            const options = {
                method: 'post',
                headers: {
                    // 'content-type': 'multipart/form-data',
                    // 'content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${accessToken}` //need to change the auth token
                },
                body: fileData
            }
            fetch(requestURL, options)
                .then(async response => {
                    const data = await response.json();
                    if (!response.ok) {
                        // get error message from body or default to response statusText
                        const error = (data && data.Message) || response.statusText;
                        reject(error);
                        navigate('/error', { state: { status: response.status } });
                    } else {
                        // resolve(data);
                        resolve(data.data);
                    }
                });
        })
    }

    async function httpPut(requestURL: any, requestBody: any) {
        const accessToken = await authService.getAccessToken();
        return new Promise((resolve, reject) => {
            const options = {
                method: 'put',
                headers: {
                    'content-type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${accessToken}` //need to change the auth token
                },
                body: JSON.stringify(requestBody)
            }

            fetch(requestURL, options)
                .then(async response => {

                    const data = await response.json();

                    if (!response.ok) {
                        // get error message from body or default to response statusText
                        const error = (data && data.message) || response.statusText;
                        reject(error);
                        navigate('/error', { state: { status: response.status } });
                    } else {
                        // resolve(data);
                        resolve(data.data);
                    }

                });
        })
    }

    async function httpFilePutToS3(requestURL: any, fileData: any) {
        return new Promise((resolve, reject) => {
            const options = {
                method: 'PUT',
                headers: {
                    'Content-Type': ''
                },
                body: fileData
            }

            fetch(requestURL, options)
                .then(async (response: any) => {
                    // const data = await response.json();
                    if (!response.ok) {

                        // get error message from body or default to response statusText
                        const error = (response && response?.message) || response.statusText;
                        reject(error);
                        console.log(error);
                        reject(error);
                    } else {
                        resolve(response);
                    }
                }).catch(error => {
                    console.log(error);
                    reject(error);
                });

            fetch(requestURL, options)
                .then(async (data: any) => {
                    if (!data.ok) {
                        // get error message from body or default to response statusText
                        const error = (data && data.Message) || data.statusText;
                        console.log(error);
                        reject(error);
                        navigate('/error', { state: { status: data?.status } });
                    } else {
                        resolve(data);
                    }
                }).catch(error => {
                    console.log(error);
                    reject(error);
                });
        })
    }

    async function getHttpFile(requestURL: any, parameters: any) {
        const accessToken = await authService.getAccessToken();
        return new Promise((resolve, reject) => {

            const options = {
                method: 'get',
                headers: {
                    'content-type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${accessToken}` //need to change the auth token
                }
            };
            if (parameters && typeof (parameters) === "object") {
                Object.keys(parameters).forEach((property) => {
                    requestURL += requestURL.indexOf('?') > -1 ? "&" : "?";
                    requestURL += `${property}=${encodeURIComponent(parameters[property])}`;
                });
            }

            fetch(requestURL, options)
                .then(async (response: any) => {
                    // const data = await response.json();
                    if (!response.ok) {

                        // get error message from body or default to response statusText
                        const error = (response && response?.message) || response.statusText;
                        reject(error);
                        console.log(error);
                        reject(error);
                        navigate('/error', { state: { status: response?.status } });
                    } else {
                        resolve(response);
                    }
                }).catch(error => {
                    console.log(error);
                    reject(error);
                });
        })
    }


    return { httpGet, httpPost, httpFilePost, httpPut, httpFilePutToS3, getHttpFile };
}
