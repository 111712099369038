import { Button, PageHeader, PaginationProps, Tooltip, Space, Radio, RadioChangeEvent, Image, Row, Col, Tag, Dropdown, Checkbox, Menu, Avatar, Typography, Badge } from "antd";
import "../components/styles/global.css";
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import useQuoteService from '../hooks/UseQuoteService';
import useSessionStorageService from '../hooks/UseSessionStorageService';
import { trackPromise } from 'react-promise-tracker';
import { FaMedal } from "react-icons/fa";
import { MESSAGE_CONSTANT } from "../constants/MessageConstants";
import { Edit, Eye, Plus, DropdownIcon } from "../assets/icons";
import { customTagColor } from "../components/helpers/utility";
import { CustomTable } from "./CustomTable";
import { DATA_CONSTANT } from "../constants/DataConstants";
import UseBuyerDashboardService from "../hooks/UseBuyerDashboardService";

export const PcbGrid = () => {
  const navigate = useNavigate();
  const [quoteData, setQuoteData] = useState<any>(
    {
      quotes: [],
      filteredQuotes: [],
    }
  );
  const { getAllQuote } = useQuoteService();
  const { getBuyerDashboardDetail } = UseBuyerDashboardService();
  const { getUserName } = useSessionStorageService();
  const [current, setCurrent] = useState(1);
  const [quoteStatus, setQuoteStatus] = useState<any>("All");
  const location = useLocation();
  const { status } = location.state || '' as any;
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 5
  })
  const [statusCount, setStatusCount] = useState<any>({});
  const allStatusCount = statusCount.pendingProjectCount + statusCount.inprogressProjectCount + statusCount.completedProjectCount;

  useEffect(() => {
    const getDashboardDetail = async () => {
      await getDahboardDetail();
    }
    getDashboardDetail();
  }, []);

  const getDahboardDetail = async () => {
    var reqObj = { userName: getUserName() }
    var response = await getBuyerDashboardDetail(reqObj);
    setStatusCount(response);
  }

  useEffect(() => {
    status && setQuoteStatus(status);
    trackPromise(getAllQuoteData(status));
  }, []);

  const getAllQuoteData = async (status: any) => {
    let quoteRequest = {
      userName: getUserName(),
      status: status || quoteStatus
    };
    let quotesData = await getAllQuote(quoteRequest);
    setQuoteData((preState: any) => ({ ...preState, quotes: quotesData, filteredQuotes: quotesData }));
  }

  const getQuoteDetails = (quoteData: any) => {
    navigate("/update-quote", { state: { quoteId: quoteData.id, PCBQuoteChecked: quoteData.isPCBQuote, StencilQuoteChecked: quoteData.isStencilQuote } });
  }

  const getRanking = (quoteData: any) => {
    navigate("/ranking", {
      state: {
        quoteId: quoteData.id
      }
    });
  }

  const columns: any = [
    {
      key: "projectName",
      title: "Project Name",
      dataIndex: "projectName",
      width: '25%',
      sorter: (a: any, b: any) => a.projectName?.length - b.projectName?.length,
      sortDirections: ["descend", "ascend"],
      enableSearch: true,
      ellipsis: true,
    },
    {
      key: "quoteType",
      title: "Quote Type",
      dataIndex: "quoteType",
      render: (text: any, record: any) => (
        <>
          {record?.quoteTypeName}
        </>
      ),
    },
    {
      key: "quoteStatus",
      title: "Quote Status",
      dataIndex: "quoteStatus",
      render: (text: any, record: any) => (
        <>
          <Tag color={customTagColor(record?.quoteStatus)}>
            {record?.quoteStatus}
          </Tag>
        </>
      ),
    },
    {
      key: "quoteDate",
      title: "Issue Date",
      dataIndex: "quoteDate",
      render: (text: any, record: any) => (
        <>{new Date(record?.quoteDate).toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric' })}</>
      )
    },
    {
      key: "quoteDueDate",
      title: "Due Date",
      dataIndex: "quoteDueDate",
      render: (text: any, record: any) => (
        <>{new Date(record?.quoteDueDate).toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric' })}</>
      )
    },
    {
      key: "pcbQuotedSupplier",
      width: '15%',
      title: 'PCB Quoted Supplier',
      ellipsis: true,
      render: (text: any, record: any) => (
        record.isPCBQuote && record.pcbPricingDetail &&
        <Typography.Text>{record.pcbPricingDetail.quotedSupplier} <b>|</b>  {record.pcbPricingDetail.currency}</Typography.Text>
      )
    },
    {
      key: "stencilQuotedSupplier",
      width: '15%',
      title: 'Stencil Quoted Supplier',
      ellipsis: true,
      render: (text: any, record: any) => (
        record.isStencilQuote && record.stencilPricingDetail &&
        <Typography.Text>{record.stencilPricingDetail.quotedSupplier} <b>|</b> {record.stencilPricingDetail.currency}</Typography.Text>
      )
    },
    {
      key: "Action",
      title: "Action",
      width: '8%',
      align: 'center',
      render: (text: any, record: any) => (
        <Space>
          {(record.quoteStatus.toLowerCase() === MESSAGE_CONSTANT.PricingPending) ?
            <Tooltip title="Edit">
              <Button id="quote_edit" size="middle" icon={<Image src={Edit} width={20} height={20} preview={false} />} style={{ border: 'none' }} onClick={() => getQuoteDetails(record)} />
            </Tooltip>
            : <Tooltip title="View">
              <Button id="quote_view" size="middle" icon={<Image src={Eye} width={20} height={20} preview={false} />} style={{ border: 'none' }} onClick={() => getQuoteDetails(record)} />
            </Tooltip>
          }
          {(record.quoteStatus.toLowerCase() === MESSAGE_CONSTANT.PricingPending) ?
            <Tooltip title="Price pending">
              <Button id="quote_pricePending" size="middle" icon={<FaMedal />} style={{ border: 'none', backgroundColor: 'white' }} disabled />
            </Tooltip>
            : <Tooltip title="Rank">
              <Button id="quote_getRanking" size="middle" icon={<FaMedal />} style={{ border: 'none' }} onClick={() => getRanking(record)} />
            </Tooltip>
          }
        </Space >
      )
    }
  ];

  const onStatusChange = (e: RadioChangeEvent) => {
    setQuoteStatus(e.target.value);
    trackPromise(getAllQuoteData(e.target.value));
  };

  const onQuoteTypeFilterChange = (filterQuoteTypes: any) => {
    const temp = [...quoteData.quotes];
    const filterdata: any = temp.filter((item: any) => {
      return item.quoteTypeName.includes(filterQuoteTypes.toString())
    });

    if (filterQuoteTypes.length) {
      setQuoteData((preState: any) => ({
        ...preState,
        filteredQuotes: filterdata,
      }));
    } else {
      setQuoteData((preState: any) => ({
        ...preState,
        filteredQuotes: quoteData.quotes,
      }));
    }
  };

  const options: any = [
    { label: "PCB", value: 'PCB' },
    { label: "STENCIL", value: 'Stencil' },
  ];

  const menu: any = (
    <Menu>
      <Menu.Item >
        <Checkbox.Group style={{ width: '100%' }} options={options} onChange={onQuoteTypeFilterChange} />
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <PageHeader
        title="My Quotes"
        extra={[
          <Button
            id="quote_btnAddPCBQuote"
            key="quote_btnAddPCBQuote"
            icon={<Image src={Plus} width={16} height={16} preview={false} />}
            onClick={() =>
              navigate("/add-quote", { state: { PCBQuoteChecked: true } })
            }
            style={{
              float: "right",
              borderRadius: "5px",
              backgroundColor: "#046AED",
              color: "white",
            }}
          >
            <span style={{ marginLeft: "8px" }}>ADD PCB QUOTE</span>
          </Button>,
          <Button
            key='quote_btnAddStencilQuote'
            id='quote_btnAddStencilQuote'
            icon={<Image src={Plus} width={16} height={16} preview={false} />}
            style={{
              float: "right",
              borderRadius: "5px",
              backgroundColor: "#046AED",
              color: "white",
            }}
            onClick={() =>
              navigate("/add-quote", { state: { StencilQuoteChecked: true } })
            }
          >
            <span style={{ marginLeft: "8px" }}>ADD STENCIL QUOTE</span>
          </Button>,
        ]}
      ></PageHeader>

      <Row
        align="middle"
        justify="space-between"
        style={{ margin: "0 10px 10px 20px" }}
      >
        <Col key='1'>
          <Dropdown
            overlay={menu}
            placement="bottomRight"
            trigger={["hover"]}
            getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
          >
            <Button> Filter Quotes
              <Image
                src={DropdownIcon}
                alt="dropdown"
                height={24}
                width={24}
                style={{ marginLeft: '8px' }}
                preview={false}
              /></Button>
          </Dropdown>
        </Col>
        <Col key='2'>
          <Space>
            <label style={{ fontWeight: "bold" }}>Status :</label>
            <Radio.Group onChange={onStatusChange} value={quoteStatus}>
              <Radio key='All' className="cm-statusRadioAll" value={DATA_CONSTANT.QuoteStatus.All}><Badge size="small" offset={[5, -8]} overflowCount={allStatusCount} count={allStatusCount} showZero >All</Badge></Radio>
              <Radio key='Pending' className="cm-statusRadio" value={DATA_CONSTANT.QuoteStatus.Pending}><Badge size="small" offset={[5, -8]} count={statusCount.pendingProjectCount} showZero >Pending</Badge></Radio>
              <Radio key='InProgress' className="cm-statusRadio" value={DATA_CONSTANT.QuoteStatus.InProgress}><Badge size="small" offset={[5, -8]} count={statusCount.inprogressProjectCount} showZero >InProgress</Badge></Radio>
              <Radio key='Completed' className="cm-statusRadio" value={DATA_CONSTANT.QuoteStatus.Completed}><Badge size="small" offset={[5, -8]} count={statusCount.completedProjectCount} showZero >Completed</Badge></Radio>
            </Radio.Group>
          </Space>
        </Col>
      </Row>

      <CustomTable
        columns={columns}
        dataSource={quoteData.filteredQuotes}
        rowKey="id"
        pagination={{
          pageSize: pagination.pageSize,
          showSizeChanger: true,
          onChange: (page: any, pageSize: any) => {
            setPagination({ current: page, pageSize: pageSize });
          },
        }}
      />
    </>
  );
}