import { URL_CONSTANT } from '../constants/UrlConstants';
import useCommonHttpService from './useCommonHttpService'

export default function useUserService() {
    const { httpGet, httpPost } = useCommonHttpService();

    function addUserDetailsAction(data: any) {
        return httpPost(URL_CONSTANT.User.AddUserDetails, data);
    }

    function getUserDetails(userName: any) {
        return httpPost(URL_CONSTANT.User.GetUserDetails, userName);
    }

    function addUserAccessibleClientsAction(reqObj: any) {
        return httpGet(URL_CONSTANT.User.AddUserAccessibleClients, reqObj);
    }

    return { addUserDetailsAction, getUserDetails, addUserAccessibleClientsAction };
}   