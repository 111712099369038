import { Button, Image, Form, DatePicker, Space, Input, PageHeader, Select, Upload, UploadProps, UploadFile, Spin, Alert, Row, Col, Tooltip, Typography, Radio, RadioChangeEvent, Checkbox, Collapse, message } from "antd";
import { useContext, useEffect, useState } from "react";
import { PaperClipOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import "../components/styles/addquote.css";
import { MESSAGE_CONSTANT } from "../constants/MessageConstants";
import UseQuoteService from "../hooks/UseQuoteService";
import UseSupplierContactService from "../hooks/UseSupplierContactService";
import Notification from "../components/notification/Notification";
import useSessionStorageService from "../hooks/UseSessionStorageService";
import UseDocumentDetailService from "../hooks/UseDocumentDetailService";
import moment from "moment";
import { Container } from "react-bootstrap";
import { QuantityList } from "../components/quantities/QuantityList";
import { DATA_CONSTANT } from "../constants/DataConstants";
import "../components/styles/InputDatePicker.css";
import Swal from "sweetalert2";
import { UploadOutlined, LeftCircleOutlined, WarningOutlined, CloseCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { format } from "react-string-format";
import FormItem from "antd/es/form/FormItem";
import { PlusCircleOutlined } from "@ant-design/icons";
import { AddVendorContact } from "./AddVendorContact";
import UseSupplierService from "../hooks/UseSupplierService";
import { checkIsValidURL } from "../components/helpers/utility";
import StencilQuote from "../components/stencil/StencilQuote";
import { BoardCQContext } from "../context/Context";
import { PlusWithCircle } from "../assets/icons";
import { PCB } from "../components/pcb/PCB";
import usePCBService from "../hooks/UsePCBService";
import _, { reduce } from "underscore";
import { getParams } from "../components/helpers/getParams";
import { trackPromise } from "react-promise-tracker";

export const AddQuote = () => {

  const { isCalledFromWidget } = getParams();

  const { addQuote, getQuoteByIdAction, updateQuote, getQuoteByExternalProjectIdAction } = UseQuoteService();
  const { generatePreSignedURL, uploadFilesToS3, insertDocumentNames, deleteDocument, getGeneralDocuments } = UseDocumentDetailService();
  const { getAllSupplierWithContact, getSupplierTypeAction } = UseSupplierService();
  const { getPCBSpecificationQuestionsAction } = usePCBService();
  const { getUserName } = useSessionStorageService();

  const [projectId, setProjectId] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [quoteInfo, setQuoteInfo] = useState<any>({});
  const [supplierWithContacts, setSupplierWithContacts] = useState<any>([]);
  const [quantityListData, setQuantityListData] = useState([]);
  const [isFileUploadInProgress, setIsFileUploadInProgress] = useState(false);
  const [supplierContactId, setSupplierContactId] = useState({});
  const [isSupplierContactModalVisible, setIsSupplierContactModalVisible] = useState(false);
  const [supplierType, setSupplierType] = useState([]);
  const [selectedSupplierView, setSelectedSupplierView] = useState<any>([]);
  const [selectedSupplierElement, setSelectedSupplierElement] = useState([]);
  const [generalDocument, setGeneralDocument] = useState([]);
  const [isQuoteDisabled, setIsQuoteDisabled] = useState(false);
  const [pcbQuestions, setPCBQuestions] = useState([]);
  const [pcbAnswer, setPCBAnswer] = useState<any>({});
  const [quantityData, setQuantityData] = useState<any>([]);

  const [fileListData, setFileListData] = useState<UploadFile[]>([]);
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const [form] = Form.useForm();

  const navigate = useNavigate();
  const location = useLocation();
  const { refreshData, refreshApi } = useContext(BoardCQContext);

  const { OptGroup, Option } = Select;
  const { Panel } = Collapse;

  const documentLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 10 },
  };

  const gerberFilePathLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

  const maximumFileSizeInMB = 30;
  let uploadFileSize30MB = maximumFileSizeInMB * 1024 * 1024;
  let maximumUploadFiles = 3;
  const gerberFileOptions = [
    { key: "UploadGerberFile", value: "Upload Gerber File" },
    { key: "AddGerberFilePath", value: "Add Gerber File Path" },
  ];
  const [gerberFileOption, setGerberFileOption] = useState<string>(gerberFileOptions[0].key);
  const [isGerberFileOptionChanged, setIsGerberFileOptionChanged] = useState(false);
  const [gerberFilePaths, setGerberFilePaths] = useState<any>([{ key: Math.random(), value: "", isValidPath: false }]);

  const { quoteId } = location.state as any ?? { quoteId: 0 };
  const { StencilQuoteChecked, PCBQuoteChecked } = location.state as any ?? { StencilQuoteChecked: false, PCBQuoteChecked: true };

  const [isQuantityCheckboxChecked, setIsQuantityCheckboxChecked] = useState<boolean>(PCBQuoteChecked || false);
  const [isStencilCheckboxChecked, setIsStencilCheckboxChecked] = useState<boolean>(StencilQuoteChecked || false);
  const [showStencilPanel, setShowStencilPanel] = useState<boolean>(true);
  const stencilDefaultValue = { SteppedStencil: 'No' };
  const [defaultQuantities, setDefaultQuantities] = useState([]);
  const [widgetData, setWidgetData] = useState<any>();

  const uploadProps: UploadProps = {
    name: "file",
    accept: ".zip, .rar, .pdf, .dwg",
    multiple: true,
    maxCount: maximumUploadFiles,
    fileList: fileListData,
    beforeUpload: (file: any) => {
      return false;
    },
    onChange({ file, fileList }) {
      if (fileListData.length === maximumUploadFiles || file.status === "removed") {
        return;
      }
      let totalSize = reduce(fileList, (total: number, b: any) => total + b.size, 0);
      if (totalSize > uploadFileSize30MB) {
        Notification("warning", "Warning", format(MESSAGE_CONSTANT.MaximumUploadSize, maximumFileSizeInMB));
        return;
      }
      else {
        setFileListData(fileList);
      }
    },
    onRemove: (file: any) => {
      Swal.fire({
        text: MESSAGE_CONSTANT.DeleteGerberFile,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (file?.id != null && file?.id > 0) {
            let reqObj = {
              id: file.id,
              s3FileName: file.uid,
            };
            await deleteDocument(reqObj);
          }

          const index = fileListData.indexOf(file);
          const newFileList = fileListData.slice();
          newFileList.splice(index, 1);
          setFileListData(newFileList);
          Notification(
            "success",
            "Success",
            MESSAGE_CONSTANT.GerberFileDeletedSuccessfully
          );
        }
      });
    },
  };

  const projectDocumentsCount = 2;
  const maximumProjectDocumentSizeInMB = 5;
  const projectDocumentSize = maximumProjectDocumentSizeInMB * 1024 * 1024;
  const [projectDocumentsData, setProjectDocumentsData] = useState<UploadFile[]>([]);

  const projectDocumentProps: UploadProps = {
    name: "file",
    accept:
      ".pdf, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    multiple: true,
    maxCount: projectDocumentsCount,
    fileList: projectDocumentsData,
    beforeUpload: (file: any) => {
      return false;
    },
    onChange({ file, fileList }) {
      if (projectDocumentsData.length === projectDocumentsCount || file.status === "removed") {
        return;
      }

      let totalSize = reduce(fileList, (total: number, b: any) => total + b.size, 0);
      if (totalSize > projectDocumentSize) {
        Notification("warning", "Warning", format(MESSAGE_CONSTANT.MaximumDocumentUploadSize, maximumProjectDocumentSizeInMB));
        return;
      }
      else {
        setProjectDocumentsData(fileList);
      }
    },
    onRemove: (file: any) => {
      Swal.fire({
        text: MESSAGE_CONSTANT.DeleteDocument,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (file?.id != null && file?.id > 0) {
            let reqObj = {
              id: file.id,
              s3FileName: file.uid,
            };
            await deleteDocument(reqObj);
          }

          const index = fileListData.indexOf(file);
          const newFileList = projectDocumentsData.slice();
          newFileList.splice(index, 1);
          setProjectDocumentsData(newFileList);
          Notification(
            "success",
            "Success",
            MESSAGE_CONSTANT.DocumentDeletedSuccessfully
          );
        }
      });
    },
  };

  const messageEventHandler = (event: any) => {
    if (event.data && event.data.setDefaultData) {
      setWidgetData(() => event.data);
    }
    else if (event.data && event.data.closeModalWindow) {
      clearApplicationData();
    }
  }

  // NOTES: USED WHEN APP IS WIDGET
  useEffect(() => {
    (async () => {
      if (widgetData) {
        if (widgetData.isPCBQuoteCreatedInBoardCQ) {
          await trackPromise(getQuoteByExternalProjectId(widgetData.clientCode, widgetData.externalProjectId));
        }
        else {
          setExternalProjectData();
        }
        setShowStencilPanel(false);
        setIsLoaded(true);
      }
    })();
  }, [widgetData]);

  useEffect(() => {
    if (isCalledFromWidget || (quoteInfo && quoteInfo.clientCode)) {
      trackPromise(getPCBQuestions());
    }
  }, [quoteInfo]);

  useEffect(() => {
    trackPromise(getSupplierWithContact());
    trackPromise(getSupplierType());
    trackPromise(getGeneralDocument());

    if (!isCalledFromWidget) {
      if (quoteId > 0) {
        trackPromise(getQuoteById());
      }
      else {
        setIsLoaded(true);
      }
    }
    else {
      // Used when App is Widget.
      window.addEventListener('message', messageEventHandler, false);

      setTimeout(() => {
        window.parent.postMessage({ childWindowLoaded: true }, "*");
      }, 500);
    }
  }, []);

  useEffect(() => {
    if (refreshData) {
      trackPromise(getGeneralDocument());
      refreshApi(false);
    }
  }, [refreshData]);

  useEffect(() => {
    window.onbeforeunload = function (event) {
      if (isFileUploadInProgress) {
        return "File upload is in progress. Please wait to complete it.";
      }
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const clearApplicationData = () => {
    sessionStorage.clear();
    window.removeEventListener('message', messageEventHandler);
  }

  const getSupplierType = async () => {
    let supplierTypes = await getSupplierTypeAction() as any;
    setSupplierType(() => supplierTypes);
  };

  const getQuoteById = async () => {
    let reqQuoteByIDObj = {
      quoteId: quoteId
    };
    let response = (await getQuoteByIdAction(reqQuoteByIDObj)) as any;
    setQuoteDetails(response);
    setProjectId(response?.id);
    setIsLoaded(true);
  };

  const getQuoteByExternalProjectId = async (clientCode: any, externalProjectId: any) => {
    let reqQuoteByIDObj = {
      clientCode: clientCode,
      externalProjectId: externalProjectId
    };
    let response = (await getQuoteByExternalProjectIdAction(reqQuoteByIDObj)) as any;
    if (response !== null) {
      setQuoteDetails(response);
      setProjectId(response?.id);
    }
  };

  const setExternalProjectData = () => {
    let quoteDetail = widgetData.quoteDetail;
    quoteDetail.projectDetail.quoteDueDate = moment(quoteDetail.projectDetail.quoteDueDate);
    form.setFieldsValue(quoteDetail.projectDetail);
    setDefaultQuantities(quoteDetail.quantities);
  }

  const setQuoteDetails = (response: any) => {
    setIsQuoteDisabled(DATA_CONSTANT.QuoteStatus.Pending !== response.quoteStatus);
    setQuoteInfo(response);
    setQuantityListData(response.quantities);
    setGerberFileOptionAndData(response.projectGerberFiles);
    setProjectDocuments(response.projectDocuments);
    setGeneralDocument(response.generalDocuments);
    setIsQuantityCheckboxChecked(response.isPCBQuote);
    setIsStencilCheckboxChecked(response.isStencilQuote);
    setPCBAnswer(JSON.parse(response.pcbSpecificationData));
    setShowStencilPanel(response.clientCode === null);
  }

  const setGerberFileOptionAndData = (gerberFileAndPath: any) => {
    let isGerberFileExists = gerberFileAndPath.some(
      (obj: any) => obj.s3FileName != ""
    );

    let isGerberFilePathExists = gerberFileAndPath.some(
      (obj: any) => obj.filePath != ""
    );

    if (isGerberFileExists) {
      setGerberFileData(gerberFileAndPath);
      setGerberFileOption(gerberFileOptions[0].key);
    }

    else if (isGerberFilePathExists) {
      let newGerberFilePaths = gerberFileAndPath.map((obj: any) => ({
        id: obj.id,
        key: obj.id,
        value: obj.filePath,
        isValidPath: true,
      }));
      setGerberFilePaths(newGerberFilePaths);
      setGerberFileOption(gerberFileOptions[1].key);
    }
  };

  const setGerberFileData = (projectGerberFiles: any[]) => {
    let fileData = projectGerberFiles.map((file) => {
      return {
        uid: file.s3FileName,
        name: file.fileName,
        id: file.id,
        size: file.documentSize
      };
    });
    setFileListData(fileData);
  };

  const setProjectDocuments = (projectDocuments: any[]) => {
    let fileData = projectDocuments.map((file) => {
      return {
        uid: file.s3FileName,
        name: file.fileName,
        id: file.id,
        size: file.documentSize
      };
    });
    setProjectDocumentsData(fileData);
  };

  const onFinish = async (values: any) => {
    if (!isQuantityCheckboxChecked && !isStencilCheckboxChecked) {
      Notification(
        "warning",
        "Warning",
        MESSAGE_CONSTANT.SelectOneQuoteType
      );
      return;
    }

    if (gerberFileOption === gerberFileOptions[0].key) {
      if (fileListData.length == 0) {
        Notification(
          "warning",
          "Warning",
          MESSAGE_CONSTANT.UploadAtleastOneGerberFile
        );
        return;
      }
    } else {
      let isInvalidFilePath = gerberFilePaths.some(
        (obj: any) => obj.value !== "" && obj.isValidPath === false
      );
      if (isInvalidFilePath) {
        Notification(
          "warning",
          "Warning",
          MESSAGE_CONSTANT.InvalidGerberFilePath
        );
        return;
      }
      let inValidPaths = gerberFilePaths.filter((obj: any) => obj.value === "");
      if (inValidPaths.length === gerberFilePaths.length) {
        Notification(
          "warning",
          "Warning",
          MESSAGE_CONSTANT.UploadAtleastOneGerberFilePath
        );
        return;
      }
    }

    if (projectId > 0) {
      await submitQuoteInfo(values);
    } else {
      Swal.fire({
        html: MESSAGE_CONSTANT.ConfirmSubmitQuote,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Send Quote",
        cancelButtonText: "Go Back",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await submitQuoteInfo(values);
        }
      });
    }
  };

  const submitQuoteInfo = async (values: any) => {
    let quantityObj = [] as any;
    quantityData && quantityData.boardQuantities && quantityData.boardQuantities.forEach((qtyData: any) => {
      let Quantity = {} as any;
      Quantity.Id = qtyData.id || 0;
      Quantity.Qty = qtyData.quantity;
      Quantity.ProjectId = projectId || 0;
      Quantity.TurnTimes = [];
      qtyData.turntimeUom.map((qtyTurnTime: any) => {
        let turntime = {} as any;
        turntime.Id = qtyTurnTime.id || 0;
        turntime.QuantityId = qtyData.id || 0;
        turntime.TurnTimeUOMId = qtyTurnTime.timeUom;
        turntime.TurnTime = qtyTurnTime.timeUomValue;
        turntime.ExternalTurnTimeId = qtyTurnTime.externalTurnTimeId;
        Quantity.TurnTimes.push(turntime);
      });
      quantityObj.push(Quantity);
    });

    const quoteModel = {
      Id: projectId,
      ProjectName: values.projectName,
      UserName: getUserName(),
      SupplierContactIds: values.supplierContacts,
      Description: values.description,
      Notes: values.notes,
      QuoteDueDate: moment(values.quoteDueDate),
      IsPCBQuote: isQuantityCheckboxChecked,
      IsStencilQuote: isStencilCheckboxChecked,
      Quantities: isQuantityCheckboxChecked ? quantityObj : [],
      JsonStencilData: isStencilCheckboxChecked ? JSON.stringify(values.stencil) : '',
      JsonPCBSpecificationData: pcbAnswer?.DimensionWidth === null || pcbAnswer?.DimensionWidth === '' ? '' : JSON.stringify(pcbAnswer),
      ClientCode: widgetData?.clientCode ?? quoteInfo.clientCode,
      ExternalProjectId: widgetData?.externalProjectId ?? quoteInfo.externalProjectId,
      ProjectCurrency: widgetData?.quoteDetail?.projectDetail?.currency ?? quoteInfo.projectCurrency
    };

    setLoadingSpinner(true);
    if (projectId > 0) {
      (await trackPromise(updateQuote(quoteModel)));

      if (gerberFileOption === gerberFileOptions[1].key) {
        await trackPromise(deletePreviousGerberFileData());
        await trackPromise(saveGerberFilePaths(projectId));
      } else {
        await trackPromise(deletePreviousGerberFilePathData());
      }

      await trackPromise(uploadDocument(projectId));
      Notification(
        "success",
        "Success",
        MESSAGE_CONSTANT.ProjectUpdatedSuccessfully
      );
    } else {
      let newProjectId = (await addQuote(quoteModel)) as number;
      if (gerberFileOption === gerberFileOptions[1].key) {
        await trackPromise(saveGerberFilePaths(newProjectId));
      }
      await trackPromise(uploadDocument(newProjectId));
      Notification(
        "success",
        "Success",
        MESSAGE_CONSTANT.ProjectCreatedSuccessfully
      );
    }
    setLoadingSpinner(false);
    if (isCalledFromWidget) {
      sendSuccessMessageToParent();
    }
    else {
      navigate("/quotes");
    }
  };

  const generatePreSignedURLForFile = async (fileCount: number) => {
    const reqObj = {
      fileCount: fileCount,
    };
    const presignedURLs = await generatePreSignedURL(reqObj);
    return presignedURLs;
  };

  const uploadDocument = async (projectId: number) => {
    let fileListForUpload = fileListData.filter(
      (file: any) => file.id == null
    ) as any;
    let projectDocumentForUpload = projectDocumentsData?.filter(
      (file: any) => file.id == null
    );

    if (fileListForUpload.length == 0 && projectDocumentForUpload.length == 0)
      return [];

    setIsFileUploadInProgress(true);

    fileListForUpload.forEach(
      (obj: any) =>
        (obj.documentTypeId = DATA_CONSTANT.DocumentType.ProjectGerberFiles)
    );
    if (
      projectDocumentForUpload !== null &&
      projectDocumentForUpload.length > 0
    ) {
      projectDocumentForUpload.forEach(
        (obj: any) =>
          (obj.documentTypeId = DATA_CONSTANT.DocumentType.ProjectDocuments)
      );
      if (gerberFileOption === gerberFileOptions[0].key) {
        fileListForUpload = [...fileListForUpload, ...projectDocumentForUpload];
      } else {
        fileListForUpload = [...projectDocumentForUpload];
      }
    }

    const presignedURLs = (await generatePreSignedURLForFile(fileListForUpload.length)) as [];

    const filesForUpload = presignedURLs.map((urlObj: any, index: number) => {
      let extention = fileListForUpload[index].name.substring(
        fileListForUpload[index].name.lastIndexOf(".") + 1
      );
      return {
        presignedURL: urlObj.value,
        file: fileListForUpload[index].originFileObj,
        contentLength: fileListForUpload[index].size,
        documentName: urlObj.key,
        extention: extention,
        name: fileListForUpload[index].name,
        documentSize: fileListForUpload[index].size,
        documentTypeId: fileListForUpload[index].documentTypeId,
      };
    });

    let uploadDocResponse = (await uploadFilesToS3(filesForUpload)) as any;
    const errors = uploadDocResponse.filter((response: any) => !response.ok);
    if (errors.length === 0) {
      let fileNames = filesForUpload.map((file: any) => {
        return {
          FileName: file.name,
          S3FileName: file.documentName,
          Extention: file.extention,
          DocumentSize: file.documentSize,
          DocumentTypeId: file.documentTypeId,
        };
      });
      await saveDocumentNames(projectId, fileNames);
    } else {
      Notification("error", "Error", MESSAGE_CONSTANT.GerberFileUploadFailed);
    }
    setIsFileUploadInProgress(false);
  };

  const saveDocumentNames = async (projectId: number, fileNames: any) => {
    let reqObj = {
      UserName: getUserName(),
      ProjectId: projectId,
      DocumentDetails: fileNames,
    };
    await insertDocumentNames(reqObj);
  };

  const saveGerberFilePaths = async (projectId: number) => {
    let validFilePaths = gerberFilePaths.filter((obj: any) => {
      return (
        (obj.id == null || obj.id === 0) &&
        obj.value !== "" &&
        obj.isValidPath === true
      );
    });
    if (validFilePaths.length === 0) return;

    validFilePaths = validFilePaths.map((obj: any) => ({
      filePath: obj.value,
      documentTypeId: DATA_CONSTANT.DocumentType.ProjectGerberFiles,
    }));
    await saveDocumentNames(projectId, validFilePaths);
  };

  const handleQuantityData = (data: any) => {
    setQuantityData({ ...data });
  };

  const handleCancelQuote = () => {
    form.resetFields();
    navigate("/quotes");
  };

  const showSupplierContactModal = () => {
    setIsSupplierContactModalVisible(true);
    setSupplierContactId(0);
  };

  const cancelSupplierContactModel = () => {
    setIsSupplierContactModalVisible(false);
  };

  const hideSupplierContactModel = () => {
    setIsSupplierContactModalVisible(false);
    getSupplierWithContact();
  };

  const getSupplierWithContact = async () => {
    let reqObj = {
      userEmailId: getUserName(),
    };
    const supplierWithContact = await getAllSupplierWithContact(reqObj) as any;
    setSupplierWithContacts(() => supplierWithContact);
    setSelectedSupplierView(() => supplierWithContact);

    if (isCalledFromWidget && supplierWithContact && supplierWithContact.length === 0) {
      Swal.fire({
        text: MESSAGE_CONSTANT.NoSuppliersExists,
        icon: 'warning'
      });
    }
  };

  const selectedView = (e: any) => {
    const temp = [...supplierWithContacts];
    const dd: any = temp.filter((item: any) => {
      if (
        item.supplierTypeMappings
          .split(",")
          ?.some((element: any) => e.includes(element))
      ) {
        return true;
      }
    });
    if (e.length > 0) {
      setSelectedSupplierView(dd);
    } else {
      setSelectedSupplierView([...supplierWithContacts]);
    }
    setSelectedSupplierElement(e);
  };

  const getGeneralDocument = async () => {
    let reqObj = {
      UserName: getUserName(),
    };
    const response = await getGeneralDocuments(reqObj) as any;
    if (response !== null) {
      setGeneralDocument(() => response);
    } else {
      setGeneralDocument([]);
    }
  };

  const onChangeGerberFileOption = (e: RadioChangeEvent) => {
    setGerberFileOption(e.target.value);
    if (projectId > 0 && !isGerberFileOptionChanged) {
      Notification(
        "warning",
        "Warning",
        MESSAGE_CONSTANT.GerberFileOptionChangeWarning
      );
      setIsGerberFileOptionChanged(true);
    }
  };

  const onChangeGerberFilePath = (filePath: any, index: number) => {
    const newData = [...gerberFilePaths];
    newData[index].value = filePath;
    newData[index].isValidPath = checkIsValidURL(filePath);
    setGerberFilePaths(newData);
  };

  const addGerberFilePath = () => {
    const newData = [
      ...gerberFilePaths,
      { key: Math.random(), value: "", isValidPath: false },
    ];
    setGerberFilePaths(newData);
  };

  const removeGerberFilePath = (index: number, pathObj: any) => {
    Swal.fire({
      text: MESSAGE_CONSTANT.DeleteGerberFilePath,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (projectId > 0 && pathObj.id != null && pathObj.id > 0) {
          let reqObj = {
            id: pathObj.id,
          };
          await deleteDocument(reqObj);
        }

        const newData = [...gerberFilePaths];
        newData.splice(index, 1);
        setGerberFilePaths(newData);
        Notification(
          "success",
          "Success",
          MESSAGE_CONSTANT.GerberFilePathDeletedSuccessfully
        );
      }
    });
  };

  const deletePreviousGerberFileData = async () => {
    (fileListData as any).forEach(async (file: any) => {
      if (file.id > 0)
        await deleteDocument({ id: file.id, s3FileName: file.uid });
    });
  };

  const deletePreviousGerberFilePathData = async () => {
    gerberFilePaths.forEach(async (path: any) => {
      if (path.id > 0) await deleteDocument({ id: path.id });
    });
  };

  const supplierContactFilter = (input: any, option: any) => {
    if (option?.label) {
      return (
        (option?.label as any)?.props?.children
          ?.toLowerCase()
          ?.includes(input.toLowerCase()) ?? false
      );
    }
    return (option?.children as any)?.some((c: string) =>
      c
        ?.toString()
        ?.toLowerCase()
        ?.includes(input.toLowerCase())
    );
  }

  const handlePCBAnswerData = (updatedPCBAnswer: any) => {
    setPCBAnswer(updatedPCBAnswer);
  };

  const getPCBQuestions = async () => {
    let response = await getPCBSpecificationQuestionsAction() as any;
    setPCBQuestions(() => response);

    if (quoteId === 0) {
      var answerFields: any = {};
      response.forEach((question: any) => {
        question.pcbQuestionFields.forEach((pcbQuestionField: any) => {
          answerFields[pcbQuestionField.fieldName] = (pcbQuestionField.defaultValue ?? '');
          if (pcbQuestionField.unit) {
            let fieldUOMName = `${pcbQuestionField.fieldName}UOM`;
            answerFields[fieldUOMName] = pcbQuestionField.unit;
          }
        });
      });
      setPCBAnswer(() => answerFields);
    }
  }

  const sendSuccessMessageToParent = () => {
    clearApplicationData();
    window.parent.postMessage({ isSuccessful: true }, "*");
  }

  const pcbQuestionsLayout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 17 }
  };

  const radioControlLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 }
  };

  const textAreaControlLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 }
  };

  return (
    <>
      <PageHeader
        title={
          projectId > 0
            ? isQuoteDisabled
              ? "Quote Information"
              : "Edit Quote"
            : "Add New Quote"
        }
        style={isCalledFromWidget && { paddingLeft: '6%' }}
        backIcon={<LeftCircleOutlined className="addQuoteBackIcon" style={{ fontSize: "20px" }} />}
        onBack={() => !isCalledFromWidget && navigate("/quotes")}
      />
      <Container>
        {isLoaded && (
          <Form
            scrollToFirstError
            form={form}
            onFinish={onFinish}
            disabled={isQuoteDisabled}
            labelWrap={true}
            labelAlign="left"
            initialValues={{
              projectName: quoteInfo?.projectName || "",
              quoteDueDate: moment(quoteInfo?.quoteDueDate) || "",
              notes: quoteInfo?.notes || "",
              description: quoteInfo?.description || "",
              supplierContacts: quoteInfo.supplierContactIds,
              stencil: quoteInfo.jsonStencilData && JSON.parse(quoteInfo?.jsonStencilData) || stencilDefaultValue,
            }}
          >
            <Space direction="vertical" style={{ width: "100%" }}>

              <Collapse defaultActiveKey={['1']} className="cm-quote-title">
                <Panel key="1"
                  header={<Typography.Title level={5}>Quote Information</Typography.Title>}>
                  <Row gutter={16} >
                    <Col span={10} offset={1}>
                      <Typography.Text>
                        <span style={{ color: "red" }}>*</span>Project Name
                      </Typography.Text>
                      <Form.Item
                        name="projectName"
                        key="projectName"
                        rules={[
                          { required: true, message: "Project Name is Required" },
                        ]}
                      >
                        <Input
                          size="middle"
                          placeholder="Project Name"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Typography.Text>
                        <span style={{ color: "red" }}>*</span>Quote Due Date
                      </Typography.Text>
                      <Form.Item
                        name="quoteDueDate"
                        rules={[
                          { required: true, message: "Quote Due Date Required" },
                        ]}
                      >
                        <DatePicker
                          size="middle"
                          className="w-75"
                          getPopupContainer={(trigger) =>
                            trigger.parentNode as HTMLElement
                          }
                          disabledDate={(current) => {
                            return (
                              moment().add(-1, "days") >= current ||
                              moment().add(1, "month") <= current
                            );
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={10} offset={1}>
                      <Typography.Text>Notes</Typography.Text>
                      <Form.Item name="notes">
                        <Input.TextArea rows={3} showCount maxLength={4000} />
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Typography.Text>Description</Typography.Text>
                      <Form.Item name="description">
                        <Input.TextArea rows={3} showCount maxLength={4000} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>

              <Collapse defaultActiveKey={['1']} className="cm-quote-title">
                <Panel key="1"
                  header={<Typography.Title level={5}>Supplier Information</Typography.Title>}>
                  <Row gutter={16}>
                    <Col span={10} offset={1}>
                      <Typography.Text> Supplier Filter </Typography.Text>
                      <Form.Item>
                        <Select
                          mode="multiple"
                          size="middle"
                          getPopupContainer={(trigger) =>
                            trigger.parentNode as HTMLElement
                          }
                          placeholder="Filter Supplier Type..."
                          value={selectedSupplierElement}
                          onChange={(element: any) => selectedView(element)}
                        >
                          {supplierType &&
                            supplierType.map((item: any) => (
                              <Option key={item.id} value={item.type}>
                                {item.type}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={10}>
                      <Typography.Text>
                        <span style={{ color: "red" }}>*</span>Supplier Contacts
                      </Typography.Text>
                      <Form.Item
                        name="supplierContacts"
                        rules={[
                          {
                            required: true,
                            message: "Please select supplier contacts",
                            type: "array",
                          },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          size="middle"
                          optionFilterProp="children"
                          getPopupContainer={(trigger) =>
                            trigger.parentNode as HTMLElement
                          }
                          placeholder="Search Supplier Contacts..."
                          filterOption={supplierContactFilter}
                        >
                          {selectedSupplierView &&
                            selectedSupplierView.length > 0 &&
                            selectedSupplierView.map((item: any, oIndex: number) => (
                              <OptGroup
                                key={`${item.supplierUserAssociationId}_${oIndex}`}
                                label={
                                  <Typography.Title
                                    style={{ backgroundColor: "#E5E5E5" }}
                                    level={5}
                                  >
                                    {item.supplierName}
                                  </Typography.Title>
                                }
                              >
                                {item.supplierContacts.map((contact: any, iIndex: number) => (
                                  <Option key={`${contact.id}_${iIndex}_${oIndex}`} value={contact.id}>
                                    {" "}
                                    {contact.fullName} - {contact.emailId}
                                  </Option>
                                ))}
                              </OptGroup>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Tooltip title="Add Supplier">
                        <Button
                          style={{
                            fontSize: "24px",
                            paddingTop: "14px",
                            border: "none",
                            background: "none",
                          }}
                          onClick={showSupplierContactModal}
                          icon={
                            <Image
                              src={PlusWithCircle}
                              width={24}
                              height={24}
                              preview={false}
                            />
                          }
                        />
                      </Tooltip>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>

              {(!isQuoteDisabled || (isQuoteDisabled && isQuantityCheckboxChecked)) &&
                <Collapse defaultActiveKey={['1']} className="cm-quote-title">
                  <Panel key="1"
                    header={<Typography.Title level={5}>Quantity Quoted</Typography.Title>}
                    extra={
                      <Checkbox onChange={(e) => setIsQuantityCheckboxChecked(e.target.checked)} checked={isQuantityCheckboxChecked}
                        style={{ paddingLeft: '10px' }} />
                    }
                  >
                    {isQuantityCheckboxChecked &&
                      <Row>
                        <Col span={24} offset={1}>
                          <QuantityList
                            handleQuantityData={handleQuantityData}
                            quantityListData={quantityListData}
                            projectId={quoteId || projectId}
                            isPricingDone={isQuoteDisabled}
                            defaultQuantities={defaultQuantities}
                          />
                        </Col>
                      </Row>
                    }
                  </Panel>
                </Collapse>
              }

              {showStencilPanel && (!isQuoteDisabled || (isQuoteDisabled && isStencilCheckboxChecked)) &&
                <Collapse defaultActiveKey={['1']} className="cm-quote-title">
                  <Panel key="1"
                    header={<Typography.Title level={5}>Stencil</Typography.Title>}
                    extra={
                      <Checkbox onChange={(e) => setIsStencilCheckboxChecked(e.target.checked)} checked={isStencilCheckboxChecked}
                        style={{ paddingLeft: '10px' }} />
                    }
                  >
                    {isStencilCheckboxChecked &&
                      <Row>
                        <Col span={24} offset={1}>
                          <StencilQuote />
                        </Col>
                      </Row>
                    }
                  </Panel>
                </Collapse>
              }

              {(isCalledFromWidget || (projectId > 0 && quoteInfo.clientCode)) &&
                <Collapse defaultActiveKey={['1']} className="cm-quote-title">
                  <Panel key="1"
                    header={<Typography.Title level={5}>PCB Specification</Typography.Title>}
                  >
                    <Row>
                      <Col span={16} offset={1}>
                        {pcbQuestions && pcbQuestions.length > 0 &&
                          pcbAnswer && Object.keys(pcbAnswer).length > 0 &&
                          <PCB
                            handlePCBAnswerData={handlePCBAnswerData}
                            pcbAnswer={pcbAnswer}
                            isDisabled={isQuoteDisabled}
                            pcbQuestions={pcbQuestions}
                            form={form}
                            textboxControlLayout={pcbQuestionsLayout}
                            dropdownControlLayout={pcbQuestionsLayout}
                            radioControlLayout={radioControlLayout}
                            textAreaControlLayout={textAreaControlLayout}
                          />
                        }
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              }

              <Collapse defaultActiveKey={['1']} className="cm-quote-title">
                <Panel key="1"
                  header={<Typography.Title level={5}>Attach Document</Typography.Title>}>
                  <Row>
                    <Col span={24} offset={1}>
                      <Form.Item {...documentLayout} label="Select Gerber File Option">
                        <Radio.Group
                          defaultValue={gerberFileOption}
                          onChange={(e) => onChangeGerberFileOption(e)}
                        >
                          {gerberFileOptions.map((option: any) => (
                            <Radio key={option.key} value={option.key}>
                              {option.value}
                            </Radio>
                          ))}
                        </Radio.Group>
                      </Form.Item>

                      {gerberFileOption === gerberFileOptions[0].key && (
                        <>
                          <Form.Item
                            label="Upload Gerber File"
                            {...documentLayout}
                          >
                            <Upload {...uploadProps}>
                              <Button icon={<UploadOutlined />}>Upload</Button>
                            </Upload>
                          </Form.Item>
                          <Col span={14} offset={4}>
                            <FormItem style={{ marginTop: "-15px" }}
                            >
                              <Alert
                                type="warning"
                                icon={<WarningOutlined />}
                                showIcon
                                message="Note: We are only allowed zip, rar, pdf, dwg files. It limits up to 30 mb total size at once."
                              />
                            </FormItem>
                          </Col>
                        </>
                      )}
                      {gerberFileOption === gerberFileOptions[1].key && (
                        <Form.Item
                          {...gerberFilePathLayout}
                          label="Gerber File Path"
                          tooltip={{
                            title:
                              "The file link should work without authentication.",
                            icon: <InfoCircleOutlined />,
                          }}
                        >
                          {gerberFilePaths.map((obj: any, index: number) => (
                            <Row key={index}>
                              <Col span={12}>
                                <Input
                                  size="middle"
                                  key={"GerberFilePath_" + obj.key}
                                  defaultValue={obj.value}
                                  placeholder="Enter Gerber File Path"
                                  style={{
                                    marginTop: "5px",
                                    color: "rgba(0, 0, 0, 0.85)",
                                  }}
                                  onChange={(e) =>
                                    onChangeGerberFilePath(e.target.value, index)
                                  }
                                  disabled={obj.id && obj.id > 0}
                                  title={obj.id > 0 ? obj.value : ""}
                                />
                                {obj.value && !obj.isValidPath && (
                                  <Typography.Text type="danger">
                                    Invalid Gerber File Path
                                  </Typography.Text>
                                )}
                              </Col>
                              {maximumUploadFiles !== gerberFilePaths.length &&
                                gerberFilePaths.length === index + 1 &&
                                !isQuoteDisabled && (
                                  <Col span={1}>
                                    <Tooltip
                                      title="Add File Path"
                                      placement="right"
                                    >
                                      <PlusCircleOutlined
                                        style={{
                                          fontSize: "25px",
                                          paddingTop: "10px",
                                          paddingLeft: "10px",
                                        }}
                                        onClick={addGerberFilePath}
                                      />
                                    </Tooltip>
                                  </Col>
                                )}
                              {gerberFilePaths.length > 1 && !isQuoteDisabled && (
                                <Col span={11}>
                                  <Tooltip
                                    title="Remove File Path"
                                    placement="right"
                                  >
                                    <CloseCircleOutlined
                                      style={{
                                        fontSize: "25px",
                                        paddingTop: "10px",
                                        paddingLeft: "10px",
                                      }}
                                      onClick={() =>
                                        removeGerberFilePath(index, obj)
                                      }
                                    />
                                  </Tooltip>
                                </Col>
                              )}
                            </Row>
                          ))}
                        </Form.Item>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} offset={1}>
                      <Form.Item
                        {...documentLayout}
                        label="Upload Quote Specific Documents"
                      >
                        <Upload {...projectDocumentProps}>
                          <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} offset={1}>
                      <FormItem
                        {...documentLayout}
                        label="Buyer General Documentations"
                      >
                        {generalDocument &&
                          generalDocument?.map((element: any) => (
                            <Row key={element.id}>
                              <Space>
                                <PaperClipOutlined />
                                <span>{element.fileName}</span>
                              </Space>
                            </Row>
                          ))}
                      </FormItem>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>

              <Row>
                <Col span={24}>
                  <Form.Item wrapperCol={isCalledFromWidget ? { offset: 11 } : { offset: 8 }}>
                    <Space>
                      {!isQuoteDisabled &&
                        <>
                          <Button
                            type="primary"
                            htmlType="submit"
                            disabled={loadingSpinner}
                          >
                            Submit
                          </Button>
                          {!isCalledFromWidget &&
                            <Button htmlType="button" disabled={loadingSpinner} onClick={handleCancelQuote}>
                              Cancel
                            </Button>
                          }
                        </>
                      }
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
            </Space>
          </Form>
        )}
      </Container>

      {isSupplierContactModalVisible &&
        <AddVendorContact
          isSupplierContactModalVisible={isSupplierContactModalVisible}
          cancelSupplierContactModel={cancelSupplierContactModel}
          supplierContactId={supplierContactId}
          hideSupplierContactModel={hideSupplierContactModel}
        />
      }
    </>
  );
};
