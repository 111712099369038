import { Row, Col } from "antd";
import { Footer } from "antd/lib/layout/layout";
import "../styles/Footer.css";
import _map from 'lodash/map';
import { MESSAGE_CONSTANT } from "../../constants/MessageConstants";
import { FooterLinks } from "../helpers/FooterLink";
import { useTranslation } from "react-i18next";

export const BoardCQFooter = () => {
    // const { t } = useTranslation();

    return (
        <Footer className="footer">
            {/* <Row className="footer-row">
                <Col className="footer-links" span={24}>
                    {_map(FooterLinks.Links, (conf) => <a href={conf.href} key={conf.key}
                        rel="noreferrer" target={"_blank"} >{t(conf.children)}</a>)}
                </Col>
            </Row> */}
            <Row className="footer-row">
                <Col span={24}>{MESSAGE_CONSTANT.CopyRightContent}</Col>
            </Row>
        </Footer>
    )
}