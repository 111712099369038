import { Checkbox, Radio, RadioChangeEvent, Form, Typography } from "antd";
import { Col, Input, Row, Select } from "antd";
import { DATA_CONSTANT } from "../../constants/DataConstants";

function StencilQuote(props: any) {

  const layoutL = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
  };

  const layoutR = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  return (
    <>
      <Row>
        <Col span={8}>
          <Col>
            <Form.Item
              name={["stencil", "Apertures"]}
              label='Apertures'
              {...layoutL}
              rules={[
                { pattern: new RegExp(/^[0-9]+$/), message: "Please Input Valid Number" },
                { required: true, message: 'Please enter Apertures' },
                { min: 1, message: 'Apertures must be greater than 0' }
              ]}
            >
              <Input style={{ width: "300px" }} maxLength={5} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              {...layoutL}
              name={["stencil", "FrameType"]}
              label='Frame Type'
              rules={[
                { required: true, message: 'Please enter Frame Type' }
              ]}
            >
              <Select
                style={{ width: 300 }}
                getPopupContainer={(trigger) =>
                  trigger.parentNode as HTMLElement
                }
                options={DATA_CONSTANT.StencilFrameType}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              {...layoutL}
              name={["stencil", "MaterialThickness"]}
              label='Material Thickness'
              rules={[
                { required: true, message: 'Please enter Material Thickness' }
              ]}
            >
              <Select
                style={{ width: 300 }}
                getPopupContainer={(trigger) =>
                  trigger.parentNode as HTMLElement
                }
                options={DATA_CONSTANT.StenccilMaterilThicknes}
              />
            </Form.Item>
          </Col>
        </Col>
        <Col span={12} offset={2}>
          <Col>
            <Form.Item
              {...layoutR}
              name={["stencil", "MaterialType"]}
              label='Material Type'
              rules={[
                { required: true, message: 'Please enter Material Type' }
              ]}
            >
              <Select
                style={{ width: 300 }}
                getPopupContainer={(trigger) =>
                  trigger.parentNode as HTMLElement
                }
                options={DATA_CONSTANT.StencilMaterialType}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              {...layoutR}
              name={["stencil", "SteppedStencil"]}
              label='Stepped Stencil'
            >
              <Radio.Group>
                <Radio value='Yes'>Yes</Radio>
                <Radio value='No'>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name={["stencil", "Steps"]}
              label='Steps'
              {...layoutR}
              rules={[
                { pattern: new RegExp(/^[0-9]+$/), message: "Please Input  Valid Number" },
                { required: true, message: 'Please enter Steps' }
              ]}
            >
              <Input style={{ width: "300px" }} maxLength={1} />
            </Form.Item>
          </Col>
        </Col>
      </Row>
    </>
  );
}

export default StencilQuote;
