import { URL_CONSTANT } from "../constants/UrlConstants";
import useCommonHttpService from "./useCommonHttpService";

export default function useCurrencyConversionServices() {
  const { httpGet } = useCommonHttpService();

  function getCurrencyConversionRates() {
    return httpGet(
      URL_CONSTANT.CurrencyConversion.GetCurrencyConversionRates,
      null
    );
  }
  function getAllCurrency() {
    return httpGet(URL_CONSTANT.CurrencyMaster.GetAllCurrency, null);
  }
  return { getCurrencyConversionRates, getAllCurrency };
}
