import { Avatar, Col, Row } from "antd"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import "../../components/styles/buyerdashboard.css";
import UseBuyerDashboardService from "../../hooks/UseBuyerDashboardService";
import useSessionStorageService from "../../hooks/UseSessionStorageService";

export const BuyerDashBoard = () => {
    const { getBuyerDashboardDetail } = UseBuyerDashboardService();
    const { getUserName } = useSessionStorageService();
    const navigate = useNavigate();

    const [dashboardData, setDashboardData] = useState<any>({});

    useEffect(() => {
        const getDashboardDetail = async () => {
            await getDahboardDetail();
        }
        getDashboardDetail();
    }, []);

    const getDahboardDetail = async () => {
        var reqObj = { userName: getUserName() }
        var response = await getBuyerDashboardDetail(reqObj);
        setDashboardData(response);
    }

    return (
        <>
            <Row>
                <Col span={6}>
                    <Avatar shape="square" className="box_count" onClick={() => dashboardData?.pendingProjectCount > 0 ? navigate('/quotes', { state: { status: "Pending" } }) : ''}>
                        <strong className="box_count_text">Pending Quotes
                            <br />
                            {dashboardData?.pendingProjectCount ?? 0}
                        </strong>
                    </Avatar>
                </Col>
                <Col span={6}>
                    <Avatar shape="square" className="box_count" onClick={() => dashboardData?.inprogressProjectCount > 0 ? navigate('/quotes', { state: { status: "InProgress" } }) : ''}>
                        <strong className="box_count_text">InProgress Quotes
                            <br />
                            {dashboardData?.inprogressProjectCount ?? 0}
                        </strong>
                    </Avatar>
                </Col>
                <Col span={6}>
                    <Avatar shape="square" className="box_count" onClick={() => dashboardData?.completedProjectCount > 0 ? navigate('/quotes', { state: { status: "Completed" } }) : ''}>
                        <strong className="box_count_text">Completed Quotes
                            <br />
                            {dashboardData?.completedProjectCount ?? 0}
                        </strong>
                    </Avatar>
                </Col>
                <Col span={6}>
                    <Avatar shape="square" className="box_count" onClick={() => navigate('/vendor')}>
                        <strong className="box_count_text">Total Suppliers
                            <br />
                            {dashboardData?.supplierCount ?? 0}
                        </strong>
                    </Avatar>
                </Col>
            </Row>
        </>
    )
}