import { useEffect } from "react";
import { authService } from '../hooks/authService';
import { Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import useSessionStorageService from "../hooks/UseSessionStorageService";
import { getParams } from "../components/helpers/getParams";

export const Login = () => {
    const navigate = useNavigate();
    const { getUserView, setUserView } = useSessionStorageService();

    let loadingSpinner = true;
    useEffect(() => {
        checkUserLoggedIn();
    }, [])

    const checkUserLoggedIn = async () => {
        const user = await authService.getUser();
        getParams().view && setUserView(getParams().view);

        if (!user) {
            const returl=encodeURIComponent(window.location.href)
            authService.login(returl)
        } else {  
            setTimeout(() => {
                switch (getUserView()) {
                    case "buyer":
                        navigate('/buyer')
                        break;
                        
                    case "supplier":
                        navigate('/supplier')
                        break;
    
                    default:
                        navigate('/buyer')
                        break;
                }
            }, 1000);
        }
    }

    return (loadingSpinner ?
        <Spin
            className="cm-spinner"
            spinning={loadingSpinner}
            size="large"
        /> : <></>);
}