import React, { useEffect, useState } from "react";
import useSessionStorageService from "../../hooks/UseSessionStorageService";
import useSupplierContactService from "../../hooks/UseSupplierContactService";
import { ResponseTimeRanking } from "./ResponseTimeRanking";

const StencilResponseTimeRanking = (props: any) => {
  const { projectId } = props;
  const [supplierRankData, setSupplierRankData] = useState<any>([]);
  const { getStencilSupplierResponseTime } = useSupplierContactService();
  const { getUserView, getDefaultView, getUserName } =
    useSessionStorageService();

  const getStencilRanking = async () => {
    const userName =
      getUserView() === "supplier" || getDefaultView() === "supplier"
        ? getUserName()
        : "";
    let response = (await getStencilSupplierResponseTime(
      projectId,
      userName
    )) as any;
    setSupplierRankData(response);
  };

  useEffect(() => {
    getStencilRanking();
  }, []);

  return <ResponseTimeRanking supplierRankData={supplierRankData} />;
};

export default StencilResponseTimeRanking;
