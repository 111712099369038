import { Button, Col, Descriptions, Form, Input, Row, Select, Space, Typography } from "antd";
import FormItem from "antd/es/form/FormItem";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DATA_CONSTANT } from "../../constants/DataConstants";
import usePricingService from "../../hooks/UsePricingService";
import UseQuoteService from "../../hooks/UseQuoteService";
import useSessionStorageService from "../../hooks/UseSessionStorageService";
import Notification from '../../components/notification/Notification';
import { MESSAGE_CONSTANT } from "../../constants/MessageConstants";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import { trackPromise } from "react-promise-tracker";

const StencilPricing = ({ projectId, quoteDate, quoteStencilData, isPricingDone, pricingId, currencyData, handlePricingCurrency }: any) => {

  const stencilQuoteProperties = DATA_CONSTANT.StencilQuoteProperties as any;

  const { Option } = Select;
  const { getAllTurnTimeUom } = UseQuoteService();
  const { getUserName } = useSessionStorageService();
  const { addPricing, getPricingById } = usePricingService();

  const navigate = useNavigate();

  const [turnTimeUOM, setTurnTimeUOM] = useState<any>([]);

  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    Swal.fire({
      html: MESSAGE_CONSTANT.ConfirmSubmitPrice,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await submitQuotePrice(values);
      }
    });
  }

  const submitQuotePrice = async (values: any) => {
    let diffTime = new Date().getTime() - new Date(quoteDate).getTime();
    let submittedDays = Math.round(diffTime / (1000 * 3600 * 24));

    const pricingModel = {
      projectId: projectId,
      supplierContactUserName: getUserName(),
      notes: values.notes,
      submittedDays: submittedDays,
      submittedDate: moment(new Date()),
      currency: currencyData.currency || '',
      stencilPricingDetail: { unitPrice: values.unitPrice, turnTime: values.turnTime, turnTimeUOMId: values.turnTimeUOMId },
      isStencilPricing: true
    };

    await trackPromise(addPricing(pricingModel));
    Notification('success', 'Success', MESSAGE_CONSTANT.PricingCompletedSuccessfully);
    navigate("/supplier");
  }


  useEffect(() => {
    (async () => {
      await trackPromise(GetAllUOM());
    })();
  }, []);

  useEffect(() => {
    if (isPricingDone)
      trackPromise(getPricingDetail());
  }, [])

  const GetAllUOM = async () => {
    let uomList = await getAllTurnTimeUom() as any;
    setTurnTimeUOM(uomList);
    form.setFieldValue('turnTimeUOMId', uomList[0].id)
  };

  const getPricingDetail: any = async () => {
    let response = await getPricingById({ pricingId: pricingId }) as any;
    handlePricingCurrency(response.currency);
    form.setFieldsValue(response.stencilPricingDetail);
    form.setFieldValue('notes', response.notes);
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      disabled={isPricingDone}
    >
      <Row gutter={16}>
        <Col span={11}>
          {quoteStencilData && Object.keys(quoteStencilData)?.map((key: any, index: number) => {
            return (
              <Row key={index} style={{ padding: '5px' }}>
                <Col span={7}>
                  <b>{stencilQuoteProperties[key]}</b>
                </Col>
                <Col span={17}>
                  <Input value={quoteStencilData[key]} disabled />
                </Col>
              </Row>
            );
          })}
        </Col>
        <Col span={12}>
          <Row gutter={16}>
            <Col span={9}>
              <Form.Item
                name='turnTime'
                label='Turn Time'
                rules={[
                  { required: true, message: '' },
                  { pattern: new RegExp(/^[0-9]{1,3}$/), message: '' }
                ]}
              >
                <Input placeholder="Turn Time" maxLength={3} />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                name='turnTimeUOMId'
                rules={[
                  { required: true, message: '' },
                ]}
              >
                <Select placeholder='Turn Time UOM'>
                  {turnTimeUOM && turnTimeUOM.map((item: any) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                name='unitPrice'
                label={<span>Unit Price <b>{currencyData.unicode}</b></span>}
                rules={[
                  { required: true, message: '' },
                  { pattern: new RegExp(/^[0-9]{1,6}(\.[0-9]{0,3})?$/), message: '' }
                ]}
              >
                <Input placeholder="Unit Price" maxLength={10} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item name='notes' label="Notes"
                labelCol={{ span: 3 }} wrapperCol={{ span: 21 }}>
                <Input.TextArea
                  rows={3}
                  showCount
                  maxLength={4000}
                />
              </Form.Item>
            </Col>
          </Row>
          <FormItem>
            {!isPricingDone &&
              <Space>
                <Button htmlType="submit" style={{ backgroundColor: "#046AED", color: "white" }}>
                  Submit
                </Button>

                <Button htmlType="button" >
                  Cancel
                </Button>
              </Space>
            }
          </FormItem>
        </Col>

      </Row>

    </Form>
  );
};

export default StencilPricing;
