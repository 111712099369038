
const ROOT_URL = process.env.REACT_APP_API_URL;
export const URL_CONSTANT = {
    User: {
        AddUserDetails: ROOT_URL + "/User/AddUserDetails",
        GetUserDetails: ROOT_URL + "/User/GetUserDetails",
        AddUserAccessibleClients: ROOT_URL + "/User/AddUserAccessibleClients"
    },
    Quote: {
        AddQuote: ROOT_URL + "/Project/AddQuote",
        EditQuote: ROOT_URL + "/Project/EditQuote",
        GetAllQuote: ROOT_URL + "/Project/GetAllQuote",
        DeleteQuantity: ROOT_URL + "/Project/DeleteQuantity",
        DeleteTurnTime: ROOT_URL + "/Project/DeleteTurnTime",
        GetAllTurnTimeUom: ROOT_URL + "/Project/GetAllTurnTimeUom",
        GetQuoteById: ROOT_URL + "/Project/GetQuoteByID",
        GetQuoteByExternalProjectId: ROOT_URL + "/Project/GetQuoteByExternalProjectId",
        GetQuoteBySupplierContact: ROOT_URL + "/Project/GetQuoteBySupplierContact",
    },
    Supplier: {
        getSupplierNamesForDropdown: ROOT_URL + "/Supplier/GetSupplierNamesForDropdown",
        addSupplier: ROOT_URL + "/Supplier/Create",
        getAllSupplierWithContact: ROOT_URL + "/Supplier/GetAllSupplierWithContact",
        importSupplierWithContact: ROOT_URL + "/Supplier/ImportSupplierWithContact",
        getSupplierById: ROOT_URL + "/Supplier/GetSupplierById",
        updateSupplierType: ROOT_URL + "/Supplier/UpdateSupplierType",
    },
    SupplierContact: {
        GetAllSupplierContacts: ROOT_URL + "/SupplierContact/GetAllSupplierContacts",
        AddSupplierContact: ROOT_URL + "/SupplierContact/Create",
        UpdateSupplierContact: ROOT_URL + "/SupplierContact/Update",
        GetSupplierContact: ROOT_URL + "/SupplierContact/GetById",
        GetPCBSupplierResponseTime:ROOT_URL + "/SupplierContact/GetPCBSupplierResponseTime",
        GetStencilSupplierResponseTime:ROOT_URL + "/SupplierContact/GetStencilSupplierResponseTime",
    },
    Pricing: {
        AddPricing: ROOT_URL + "/Pricing/AddPricing",
        GetPCBPricing: ROOT_URL + "/Pricing/GetPCBPricing",
        GetPricingById: ROOT_URL + "/Pricing/GetPricingById",
        GetPCBAnswer: ROOT_URL + "/Pricing/GetPCBAnswers",
        UpdatePricingStatus:ROOT_URL + "/Pricing/UpdatePricingStatus",
        GetStencilProjectPricing: ROOT_URL + "/Pricing/GetStencilProjectPricing",
    },
    DocumentDetail: {
        GeneratePreSignedURL: ROOT_URL + "/DocumentDetail/GeneratePreSignedURL",
        InsertDocumentNames: ROOT_URL + "/DocumentDetail/Create",
        DeleteDocument: ROOT_URL + "/DocumentDetail/Delete",
        DownloadFile: ROOT_URL + "/DocumentDetail/DownloadFile",
        GetGeneralDocuments: ROOT_URL + "/DocumentDetail/GetGeneralDocuments"
    },
    Dashboard: {
        GetBuyerDashboardDetail: ROOT_URL + '/Dashboard/GetBuyerDashboardDetail'
    },
    SupplierType: {
        GetSupplierType: ROOT_URL + '/SupplierType/GetAll'
    },
    PCB: {
        GetPCBPricingQuestions: ROOT_URL + '/PCB/GetPCBPricingQuestions',
        GetPCBSpecificationQuestions: ROOT_URL + '/PCB/GetPCBSpecificationQuestions'
    },
    CurrencyConversion: {
        GetCurrencyConversionRates: ROOT_URL + '/CurrencyConversion/GetCurrencyConversionRates'
    },
    CurrencyMaster:{
        GetAllCurrency:ROOT_URL + '/CurrencyMaster/GetAll'
    }
};