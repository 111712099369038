import { URL_CONSTANT } from '../constants/UrlConstants';
import useCommonHttpService from './useCommonHttpService'

export default function useDocumentDetailService() {
    const { getHttpFile, httpPost, httpGet, httpFilePutToS3 } = useCommonHttpService();

    function downloadFile(data: any) {
        return getHttpFile(URL_CONSTANT.DocumentDetail.DownloadFile, data);
    }

    function insertDocumentNames(data: any) {
        return httpPost(URL_CONSTANT.DocumentDetail.InsertDocumentNames, data);
    }

    function generatePreSignedURL(data: any) {
        return httpGet(URL_CONSTANT.DocumentDetail.GeneratePreSignedURL, data);
    }

    function deleteDocument(data: any) {
        return httpGet(URL_CONSTANT.DocumentDetail.DeleteDocument, data);
    }

    function getGeneralDocuments(data:any) {
        return httpGet(URL_CONSTANT.DocumentDetail.GetGeneralDocuments,data);
    }

    function uploadFilesToS3(s3FileList: any) {
        return new Promise((resolve, reject) => {
            const requests = s3FileList.map((obj: any) => httpFilePutToS3(obj.presignedURL, obj.file));

            Promise.all(requests).then((responses) => {
                resolve(responses);
            })
                .catch((errors) => {
                    errors.forEach((error: any) => console.error(error));
                    reject(errors);
                });
        })
    }

    return { downloadFile, insertDocumentNames, uploadFilesToS3, deleteDocument, generatePreSignedURL, getGeneralDocuments};
}   