import { Input, Table, InputRef, Space, Button } from 'antd';
import type { ColumnsType, ColumnType, TableProps } from 'antd/es/table';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { useRef, useState } from 'react';
import { SearchOutlined } from "@ant-design/icons";

export const CustomTable = ({ columns, dataSource, ...props }: any) => {
    const searchInput = useRef<InputRef>(null);

    let newColumns: any = [];

    const getColumnSearchProps = (dataIndex: string, title: string): ColumnType<any> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    autoFocus
                    ref={searchInput}
                    placeholder={`Search ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '16px' }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]?.toString().toLowerCase().includes((value as string).toLowerCase())
        ,
        render: text => typeof (text) === "boolean" ? text.toString() : text
    });

    columns.forEach((column: any) => {
        let tempColumn = column;
        if (column?.enableSearch) {
            tempColumn = { ...column, ...getColumnSearchProps(column.dataIndex, column.title) };
        }
        newColumns.push(tempColumn);
    });

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: string,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    return (
        <>
            <Table
                columns={newColumns}
                dataSource={dataSource}
                {...props}
                bordered
                style={{ padding: '5px', margin: '2px 15px' }}
                size="middle"
                scroll={{ x: true }}
            >
            </Table>
        </>
    )
}