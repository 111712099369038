import {
    Row,
    Image,
    PageHeader,
    Col,
    Tabs,
    Collapse,
    Form,
    Typography,
    Tooltip,
    Button,
    Input,
    Space,
    Popover,
} from "antd";
import { useLocation } from "react-router-dom";
import {
    DownloadOutlined,
    PaperClipOutlined,
    RightOutlined,
    DownOutlined,
    InfoCircleOutlined
} from "@ant-design/icons";
import PCBPricing from "./PCBPricing";
import StencilPricing from "./StencilPricing";
import { useEffect, useMemo, useState } from "react";
import AllCurrency from "../currency/AllCurrency";
import UseQuoteService from "../../hooks/UseQuoteService";
import FormItem from "antd/es/form/FormItem";
import useSessionStorageService from "../../hooks/UseSessionStorageService";
import useDocumentDetail from "../../hooks/UseDocumentDetailService";
import "../../components/styles/supplierpricing.css"

export const SupplierPricing = () => {

    const location = useLocation();
    const { quoteInfo } = location.state as any;
    const { TabPane } = Tabs;

    const { getQuoteByIdAction } = UseQuoteService();

    const [collapse, setCollapse] = useState<boolean>(false);
    const [quoteData, setQuoteData] = useState<any>({});

    const [currencyData, setCurrencyData] = useState<any>({});
    const [pricingSelectedCurrency, setPricingSelectedCurrency] = useState('');
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [downloadFileIndex, setDownloadFileIndex] = useState({});

    const [isGerberFileExists, setIsGerberFileExists] = useState(false);
    const [gerberFileData, setGerberFileData] = useState<any>([]);
    const [quoteSpecificDocuments, setQuoteSpecificDocuments] = useState<any>([]);
    const [buyerSpecificDocuments, setBuyerSpecificDocuments] = useState<any>([]);
    const [turnTimeUOM, setTurnTimeUOM] = useState<any>([]);

    const { getUserName } = useSessionStorageService();
    const { downloadFile } = useDocumentDetail();
    const { getAllTurnTimeUom } = UseQuoteService();

    const [form] = Form.useForm();

    useEffect(() => {
        (async () => {
            await GetAllUOM();
            await GetQuoteDetails(quoteInfo.id);
        })();
    }, []);

    const GetAllUOM = async () => {
        let uomList = await getAllTurnTimeUom();
        setTurnTimeUOM(uomList);
    };

    const GetQuoteDetails = async (id: any) => {
        let response = await getQuoteByIdAction({ quoteId: id }) as any;
        setQuoteData(response);

        setGerberFileAndData(response.projectGerberFiles);
        setQuoteSpecificDocuments(response.projectDocuments);
        setBuyerSpecificDocuments(response.generalDocuments);
    }

    const setGerberFileAndData = (gerberFileAndPath: any) => {
        let isGerberFileExists = gerberFileAndPath.some((obj: any) => obj.s3FileName != "");
        setIsGerberFileExists(isGerberFileExists);
        setGerberFileData(gerberFileAndPath);
    }

    const onClickCollapseFunc = () => {
        setCollapse(!collapse);
    };

    const selectedView = (element: any) => {
        setCurrencyData(element);
    }

    const onClickDownloadFile = async (file: any) => {
        const reqObj = {
            s3FileName: file.s3FileName,
            userEmailId: getUserName(),
            fileName: file.fileName
        }
        setDownloadFileIndex(file.id);
        setLoadingSpinner(true);
        const response = await downloadFile(reqObj) as any;

        const contentType = response.headers.get('content-type');
        const fileName = file.fileName;
        const blobObj = await response.blob();
        const blob = new Blob([blobObj],
            {
                type: contentType
            });
        const objectUrl = (window.URL || window.webkitURL).createObjectURL(blob);
        let link = document.createElement('a') as any;
        link.id = "downloadGerberFileLink"
        link.href = objectUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        if (response) {
            setDownloadFileIndex(0);
        }
        setLoadingSpinner(false);
    }

    const [pcbCurrency, setPCBCurrency] = useState<string>('');
    const [stencilCurrency, setStencilCurrency] = useState<string>('');
    const tabKeys = {
        PCBPricing: 'PCBPricing',
        StencilPricing: 'StencilPricing'
    }
    const [activeKey, setActiveKey] = useState<string>(tabKeys.PCBPricing);

    const handlePCBPricingCurrency = (currency: string) => {
        setPCBCurrency(currency);
        setPricingSelectedCurrency(currency);
    }

    const handleStencilPricingCurrency = (currency: string) => {
        setStencilCurrency(currency);
        setPricingSelectedCurrency(currency);
    }

    const onTabChange = (activeKey: string) => {
        let currency = tabKeys.PCBPricing === activeKey ? pcbCurrency : stencilCurrency;
        setActiveKey(activeKey);
        setPricingSelectedCurrency(currency);
    }

    const currencyTag = useMemo(() => {
        const properties = {
            selectedView: selectedView,
            disabled: (activeKey === tabKeys.PCBPricing) ? (quoteInfo.pcbPricingDetail !== null) : (quoteInfo.stencilPricingDetail !== null),
            ...(quoteData.quoteStatus !== "Pending" && { selectedCurrency: pricingSelectedCurrency }),
        }
        return (
            <AllCurrency {...properties} />
        );
    }, [pricingSelectedCurrency]);

    return (
        <>
            <div style={{ margin: "0 20px 0 20px" }}>
                <Form
                    form={form}
                    layout="vertical"
                    style={{ border: "1px solid #d9d9d9" }}>
                    <Row gutter={16}>
                        <Col span={1}>
                            <Button
                                style={{ border: "none", background: "white" }}
                                onClick={onClickCollapseFunc}
                                icon={collapse ? <DownOutlined /> : <RightOutlined />}
                            />
                        </Col>
                        <Col span={7} style={{ borderRight: "1px solid rgb(217, 217, 217)" }}>
                            <>
                                {
                                    isGerberFileExists &&
                                    <FormItem
                                        label={<b>Gerber File(s)</b>}
                                        colon={false}
                                        className='mb-0'
                                    >
                                        {gerberFileData &&
                                            gerberFileData.map((element: any, index: number) => (
                                                <Row key={index}>
                                                    <Col span={1}>
                                                        <PaperClipOutlined />
                                                    </Col>
                                                    <Col span={21} className='cm-ellipses'>
                                                        <Tooltip title={element.fileName}>
                                                            <Typography.Text>
                                                                {element.fileName}
                                                            </Typography.Text>
                                                        </Tooltip>
                                                    </Col>
                                                    <Col span={2}>
                                                        <Tooltip title="Download">
                                                            <Button
                                                                size="middle"
                                                                style={{ border: "none", background: "none" }}
                                                                id="supplierPricing_downloadGerberfile"
                                                                onClick={() => onClickDownloadFile(element)}
                                                                disabled={loadingSpinner}
                                                                loading={element.id === downloadFileIndex}
                                                                icon={<DownloadOutlined color="#87d068" />}
                                                            />
                                                        </Tooltip>
                                                    </Col>
                                                </Row>
                                            ))}
                                    </FormItem>
                                }

                                {
                                    !isGerberFileExists &&
                                    <FormItem
                                        label={<b>Gerber File Link(s)</b>}
                                        colon={false}
                                        className='mb-0'
                                    >
                                        {
                                            <Row key="GerberFileLink">
                                                {
                                                    gerberFileData && gerberFileData.map((element: any, index: number) => (
                                                        <Col span={4} key={index}>
                                                            <a target="_blank" title={element.filePath} href={element.filePath}>Link {index + 1}</a>
                                                        </Col>
                                                    ))
                                                }
                                            </ Row>
                                        }
                                    </FormItem>
                                }
                            </>
                        </Col>
                        <Col span={7} style={{ borderRight: "1px solid rgb(217, 217, 217)" }}>
                            <Form.Item
                                label={<b>Quote Specific Documentation</b>}
                                colon={false}
                                className='mb-0'
                            >
                                {quoteSpecificDocuments && quoteSpecificDocuments.map((element: any, index: number) => (
                                    <Row key={index}>
                                        <Col span={1}>
                                            <PaperClipOutlined />
                                        </Col>
                                        <Col span={21} className='cm-ellipses'>
                                            <Tooltip title={element.fileName}>
                                                <Typography.Text>
                                                    {element.fileName}
                                                </Typography.Text>
                                            </Tooltip>
                                        </Col>
                                        <Col span={2}>
                                            <Tooltip title="Download">
                                                <Button
                                                    size="middle"
                                                    style={{ border: "none", background: "none" }}
                                                    id="supplierPricing_projectDocumentfile"
                                                    onClick={() => onClickDownloadFile(element)}
                                                    disabled={loadingSpinner}
                                                    loading={element.id === downloadFileIndex}
                                                    icon={<DownloadOutlined color="#87d068" />}
                                                />
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                ))}
                            </Form.Item>
                        </Col>
                        <Col span={7} style={{ borderRight: "1px solid rgb(217, 217, 217)" }}>
                            <Form.Item
                                label={<b>Buyer Specific Documentations</b>}
                                colon={false}
                                className='mb-0'
                            >
                                {buyerSpecificDocuments &&
                                    buyerSpecificDocuments?.map((element: any, index: number) => (
                                        <Row key={index}>
                                            <Col span={1}>
                                                <PaperClipOutlined />
                                            </Col>
                                            <Col span={21} className='cm-ellipses'>
                                                <Tooltip title={element.fileName}>
                                                    <Typography.Text>
                                                        {element.fileName}
                                                    </Typography.Text>
                                                </Tooltip>
                                            </Col>
                                            <Col span={2}>
                                                <Tooltip title="Download">
                                                    <Button
                                                        size="middle"
                                                        style={{ border: "none", background: "none" }}
                                                        id="supplierPricing_generalDocumentfile"
                                                        onClick={() => onClickDownloadFile(element)}
                                                        disabled={loadingSpinner}
                                                        loading={element.id === downloadFileIndex}
                                                        icon={<DownloadOutlined color="#87d068" />}
                                                    />
                                                </Tooltip>
                                            </Col>
                                        </Row>
                                    ))}
                            </Form.Item>
                            {/* <Row>
                                <Col span={12}>Description</Col>
                                <Col span={12}>Notes</Col>
                            </Row> */}
                        </Col>
                        <Col span={2} style={{ padding: '4px 16px 0px 8px' }}>{currencyTag}</Col>
                    </Row>
                </Form>
                <div>
                    {collapse && (
                        <Form>
                            <Row
                                style={{
                                    border: "1px solid #d9d9d9",
                                }}
                            >
                                <Col span={12}>
                                    <Row style={{ paddingLeft: '7px' }}>
                                        <Col span={22}>
                                            <FormItem
                                                label={<b>Project Name</b>}
                                                className='mb-0 cm-ellipses'
                                            >
                                                {quoteData?.projectName}
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row style={{ paddingLeft: '7px' }}>
                                        <Col span={22}>
                                            <FormItem
                                                label={<b>Customer Name</b>}
                                                className='mb-0 cm-ellipses'
                                            >
                                                {quoteData?.customerName}
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Row>
                                        <Col span={22}>
                                            <FormItem
                                                label={<b>Description</b>}
                                                className='mb-0 cm-ellipses'
                                            >
                                                {quoteData?.description}
                                            </FormItem>
                                        </Col>
                                        <Col span={2}>
                                            {quoteData?.description &&
                                                <Popover content={quoteData?.description} title={<b>Description</b>} trigger="click">
                                                    <Tooltip title="View Description">
                                                        <Button id="SupplierPricing_view_description" size="middle" icon={<InfoCircleOutlined />}
                                                            style={{ border: "none", background: 'none' }} />
                                                    </Tooltip>
                                                </Popover>
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={22}>
                                            <FormItem
                                                label={<b>Notes</b>}
                                                className='mb-0 cm-ellipses'
                                            >
                                                {quoteData?.notes}
                                            </FormItem>
                                        </Col>
                                        <Col span={2}>
                                            {quoteData?.notes &&
                                                <Popover placement="bottomRight" content={quoteData?.notes} title={<b>Notes</b>} trigger="click">
                                                    <Tooltip title="View Notes">
                                                        <Button id="SupplierPricing_view_notes" size="middle" icon={<InfoCircleOutlined />}
                                                            style={{ border: "none", background: 'none' }} />
                                                    </Tooltip>
                                                </Popover>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </div>

                <Row>
                    <Col span={24}>
                        <Tabs onChange={onTabChange}
                            defaultActiveKey={tabKeys.PCBPricing}
                            size="large"
                            tabBarStyle={{
                                display: "inline-block",
                                fontWeight: 600,
                                fontSize: "36px",
                            }}
                        >
                            {
                                quoteData.isPCBQuote &&
                                <TabPane tab="PCB Pricing" key={tabKeys.PCBPricing}>
                                    {quoteData && quoteData.quantities && quoteData.quantities.length > 0 &&
                                        <PCBPricing
                                            projectId={quoteInfo?.id}
                                            quoteDate={quoteInfo.quoteDate}
                                            quantities={quoteData.quantities}
                                            currencyData={currencyData}
                                            isPricingDone={quoteInfo.pcbPricingDetail != null}
                                            pricingId={quoteInfo.pcbPricingDetail?.pricingId}
                                            handlePricingCurrency={handlePCBPricingCurrency}
                                            turnTimeUOM={turnTimeUOM}
                                            isExternalQuote={quoteData.clientCode != null}
                                            buyerPCBSpecification={quoteData.pcbSpecificationData}
                                        />
                                    }
                                </TabPane>
                            }

                            {quoteData.isStencilQuote &&
                                <TabPane tab="Stencil Pricing" key={tabKeys.StencilPricing}>
                                    {quoteInfo.isStencilQuote &&
                                        <StencilPricing
                                            projectId={quoteInfo?.id}
                                            quoteDate={quoteInfo.quoteDate}
                                            currencyData={currencyData}
                                            isPricingDone={quoteInfo.stencilPricingDetail != null}
                                            pricingId={quoteInfo.stencilPricingDetail?.pricingId}
                                            handlePricingCurrency={handleStencilPricingCurrency}
                                            quoteStencilData={quoteData.jsonStencilData && JSON.parse(quoteData.jsonStencilData) || {}}
                                            turnTimeUOM={turnTimeUOM}
                                        />
                                    }
                                </TabPane>
                            }
                        </Tabs>
                    </Col>
                </Row>
            </div>
        </>
    );
};
