import { Checkbox, Col, Row, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import usePricingService from "../../hooks/UsePricingService";
import useSessionStorageService from "../../hooks/UseSessionStorageService";
import { RetweetOutlined, CheckOutlined } from "@ant-design/icons";
import { ConvertOrdinal } from "../helpers/utility";
import "../styles/supplierRankingList.css";
import { trackPromise } from "react-promise-tracker";

const StencilRanking = (props: any) => {
  const { projectId, selectedSupplier, isResetTab, isReloadData } = props;

  const { getStencilProjectPricing } = usePricingService();
  const { getUserName } = useSessionStorageService();
  const { getUserView, getDefaultView } = useSessionStorageService();
  const [stencilPricingList, setStencilPricingList] = useState<any>([]);
  const [isChkDisabled, setIsChkDisabled] = useState<boolean>(false);

  useEffect(() => {
    trackPromise(getStencilPricingList());
  }, []);

  useEffect(() => {
    if (isReloadData) {
      trackPromise(getStencilPricingList());
    }
  }, [isReloadData]);

  useEffect(() => {
    if (isResetTab && !isChkDisabled) {
      let updatedStencilPricingList = { ...stencilPricingList };
      updatedStencilPricingList.stencilProjectPricings?.forEach((p: any) => p.isPricingSelected = false);
      setStencilPricingList(updatedStencilPricingList);

      selectedSupplier({ pricingId: 0 });
    }
  }, [isResetTab])


  const getStencilPricingList = async () => {
    let userName =
      getUserView() === "supplier" || getDefaultView() === "supplier"
        ? getUserName()
        : "";
    let response = (await getStencilProjectPricing(projectId, userName)) as any;
    setStencilPricingList(response);

    let isPricingSelected = response.stencilProjectPricings.some((x: any) => x.isPricingSelected === true);
    setIsChkDisabled(isPricingSelected);
  };

  const onCheckboxChange = (pricingId: any) => {
    let newStencilPricingList = { ...stencilPricingList };

    const updatedStencilProjectPricings = newStencilPricingList.stencilProjectPricings.map((item: any) => {
      item.isPricingSelected = (item.pricingId === pricingId)
      return item;
    });
    newStencilPricingList.stencilProjectPricings = updatedStencilProjectPricings;
    setStencilPricingList(newStencilPricingList);
    selectedSupplier({ pricingId: pricingId });
  };

  return (
    <>
      {stencilPricingList.stencilProjectPricings &&
        stencilPricingList.stencilProjectPricings.length > 0 && (
          <>
            <table className="table table-bordered table-hover ranking-table">
              <thead>
                <tr>
                  <th
                    style={{
                      display: getUserView() === "supplier" ? "none" : "",
                    }}
                  >
                    Select Supplier
                  </th>
                  <th>Supplier Name</th>
                  {getUserView() !== "supplier" && (<th>Supplier Contact Name</th>)}
                  <th>Turn Time</th>
                  <th>Unit Price</th>
                </tr>
              </thead>
              <tbody >
                {stencilPricingList.stencilProjectPricings &&
                  stencilPricingList.stencilProjectPricings.length > 0 &&
                  stencilPricingList.stencilProjectPricings.map(
                    (stencilPricingListData: any, index: any) => (
                      <>
                        <tr key={index} className={stencilPricingListData.isPricingSelected === true ? 'cm-selected-pricing' : ''}>
                          <td
                            style={{
                              display:
                                getUserView() === "supplier" ? "none" : "",
                            }}
                          >
                            <Checkbox
                              value={stencilPricingListData.isPricingSelected}
                              checked={stencilPricingListData.isPricingSelected === true ? true : false}
                              disabled={isChkDisabled}
                              onChange={() => {
                                onCheckboxChange(stencilPricingListData.pricingId);
                              }}
                            />
                          </td>
                          <td>
                            {stencilPricingListData.supplierName
                              ? stencilPricingListData.supplierName
                              : "Supplier " + index}
                            {getUserView() === "supplier" ||
                              stencilPricingListData.isSameCurrency ? (
                              <></>
                            ) : (
                              <Tooltip title="Currency conversion applied">
                                <RetweetOutlined
                                  style={{
                                    color: "blue",
                                    fontWeight: "bolder",
                                    fontSize: "18px",
                                  }}
                                  className="ms-2"
                                />
                              </Tooltip>
                            )}
                          </td>
                          {getUserView() !== "supplier" && (
                            <td>
                              {stencilPricingListData.supplierContactName}
                            </td>
                          )}
                          <td>
                            {stencilPricingListData.turnTime} &nbsp;
                            {stencilPricingListData.turnTimeUOM}
                          </td>
                          <td>
                            <Row className="cm-text-right">
                              <Col span={16}>
                                {stencilPricingListData.unitPrice !== null
                                  ? (getUserView() === "supplier"
                                    ? stencilPricingListData.supplierCurrencyUnicode
                                    : stencilPricingList.projectCurrencyUnicode) +
                                  stencilPricingListData.unitPrice
                                  : ConvertOrdinal(stencilPricingListData.rank)}
                              </Col>
                              <Col span={2}>
                                {stencilPricingListData.isMinumumPrice ? (
                                  <CheckOutlined
                                    style={{
                                      color: "green",
                                      fontWeight: "bolder",
                                      fontSize: "12px",
                                    }}
                                    className="ms-2"
                                  />
                                ) : (
                                  <></>
                                )}
                              </Col>
                            </Row>
                          </td>
                        </tr>
                        {stencilPricingListData.supplierName &&
                          getUserView() === "supplier" &&
                          stencilPricingList.stencilProjectPricings.length >
                          1 && (
                            <tr style={{ backgroundColor: "#F6F6F7" }}>
                              <th colSpan={100}>Ranking</th>
                            </tr>
                          )}
                      </>
                    )
                  )}
              </tbody>
            </table>
          </>
        )}
    </>
  );
};

export default StencilRanking;
