import { useState, useEffect } from "react";
import { ErrorConstants } from '../../constants/ErrorConstants';
import { useLocation, useNavigate } from 'react-router-dom';
import { BiX } from "react-icons/bi";
import './Error.css';
import { Button, Row, Col } from "antd";

const ErrorPage = () => {
    interface CustomizedState {
        state: any
    }

    const { state } = useLocation() as CustomizedState;
    let statusCode = state === undefined ? 500 : state.status;
    const navigate = useNavigate();
    const [errorDetail, setErrorDetails] = useState({ errorTitle: "", errorMessage: "", hasError: false });

    useEffect(() => {
        getErrorPageDetail(statusCode);
    }, []);

    const getErrorPageDetail = (statusCode: number) => {
        switch (statusCode) {
            case ErrorConstants.pageNotFound.statusCode:
                setErrorDetails({
                    errorTitle: ErrorConstants.pageNotFound.title,
                    errorMessage: ErrorConstants.pageNotFound.message,
                    hasError: true
                });
                break;
            case ErrorConstants.accessDenied.statusCode:
                setErrorDetails({
                    errorTitle: ErrorConstants.accessDenied.title,
                    errorMessage: ErrorConstants.accessDenied.message,
                    hasError: true
                });
                break;
            default:
                setErrorDetails({
                    errorTitle: ErrorConstants.internalServer.title,
                    errorMessage: ErrorConstants.internalServer.message,
                    hasError: true
                });
                break;
        }
    }

    const backToHome = () => {
        navigate('/');
    }

    return (
        <>
            {errorDetail.hasError &&
                <div>
                    <Row>
                        <Col span={24} className="text-center">
                            <h1>Oops! Something went wrong.</h1>
                            <BiX size="5em" color="red" />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} className="text-center">
                            <h1>{errorDetail.errorTitle}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} className="text-center">
                            <h3 className="content-space">
                                {statusCode === ErrorConstants.internalServer.statusCode ?
                                    (<>
                                        {errorDetail.errorMessage.split('-')[0]}
                                        <a className="ml10" href={`mailto:${errorDetail.errorMessage.split('-')[1]}`}><span className="cm-email">{errorDetail.errorMessage.split('-')[1]}</span></a>
                                        {errorDetail.errorMessage.split('-')[2]}
                                    </>) : (<>{errorDetail.errorMessage}</>)
                                }
                            </h3>
                        </Col>
                    </Row>
                    {statusCode !== ErrorConstants.accessDenied.statusCode &&
                        <div className="text-center content-space">
                            <Button id="backToHomeBtn_ErrorPage" name="Back to Home" onClick={() => backToHome()} />
                        </div>
                    }
                </div>
            }
        </>
    );
};

export default ErrorPage;
