import React, { useContext, useEffect, useState } from "react";
import { Alert, Modal, Space, Spin } from "antd";
import { Upload, UploadProps, UploadFile } from "antd";
import { format } from "react-string-format";
import Swal from "sweetalert2";
import { MESSAGE_CONSTANT } from "../../constants/MessageConstants";
import UseDocumentDetailService from "../../hooks/UseDocumentDetailService";
import useSessionStorageService from "../../hooks/UseSessionStorageService";
import { DATA_CONSTANT } from "../../constants/DataConstants";
import Notification from '../notification/Notification';
import { WarningOutlined } from "@ant-design/icons";
import { BoardCQContext } from "../../context/Context";
import { trackPromise } from "react-promise-tracker";
import { reduce } from "underscore";

const { Dragger } = Upload;

function UploadGeneralDocument({ isGeneralDocumentModalVisible, onCancelGeneralDocumentModal, }: any) {

  const { generatePreSignedURL, uploadFilesToS3, insertDocumentNames, deleteDocument, getGeneralDocuments } = UseDocumentDetailService();
  const { getUserName } = useSessionStorageService();
  const [isFileUploadInProgress, setIsFileUploadInProgress] = useState(false);
  const generalDocumentCount = 2;
  const maximumGeneralDocumentizeInMB = 5;
  const generalDocumentize = maximumGeneralDocumentizeInMB * 1024 * 1024;
  const [generalDocumentData, setGeneralDocumentData] = useState<UploadFile[]>([]);
  const [updatedDataFlag, setUpdatedDataFlag] = useState(false);
  const { refreshApi } = useContext(BoardCQContext);

  const generalDocumentProps: UploadProps = {
    name: 'file',
    accept: '.pdf, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    multiple: true,
    maxCount: generalDocumentCount,
    fileList: generalDocumentData,
    beforeUpload: (file: any) => {
      return false;
    },
    onChange({ file, fileList }) {
      if (generalDocumentData.length === generalDocumentCount || file.status === 'removed') return;

      let totalSize = reduce(fileList, (total: number, b: any) => total + b.size, 0);
      if (totalSize > generalDocumentize) {
        Notification("warning", "Warning", format(MESSAGE_CONSTANT.MaximumDocumentUploadSize, maximumGeneralDocumentizeInMB));
        return;
      }
      else {
        setGeneralDocumentData(fileList);
      }
    },
    onRemove: (file: any) => {
      Swal.fire({
        text: MESSAGE_CONSTANT.DeleteDocument,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (file?.id != null && file?.id > 0) {
            let reqObj = {
              id: file.id,
              s3FileName: file.uid
            }
            await deleteDocument(reqObj);
            setUpdatedDataFlag(true);
          }

          const index = generalDocumentData.indexOf(file);
          const newFileList = generalDocumentData.slice();
          newFileList.splice(index, 1);
          setGeneralDocumentData(newFileList);

          Notification('success', 'Success', MESSAGE_CONSTANT.DocumentDeletedSuccessfully);
        }
      })
    },

  }

  const generatePreSignedURLForFile = async (fileCount: number) => {
    const reqObj = {
      fileCount: fileCount
    }
    const presignedURLs = await generatePreSignedURL(reqObj);
    return presignedURLs;
  }

  const uploadDocument = async () => {
    let generalDocumentForUpload = generalDocumentData?.filter((file: any) => file.id == null) as any;

    if (generalDocumentForUpload.length === 0) return [];
    await setUpdatedDataFlag(true);
    setIsFileUploadInProgress(true);

    if (generalDocumentForUpload !== null && generalDocumentForUpload.length > 0) {
      generalDocumentForUpload.forEach((obj: any) => obj.documentTypeId = DATA_CONSTANT.DocumentType.GeneralDocuments);
      generalDocumentForUpload = [...generalDocumentForUpload];
    }

    const presignedURLs = await generatePreSignedURLForFile(generalDocumentForUpload.length) as [];

    const filesForUpload = presignedURLs.map((urlObj: any, index: number) => {
      let extention = generalDocumentForUpload[index].name.substring(generalDocumentForUpload[index].name.lastIndexOf('.') + 1);
      return {
        presignedURL: urlObj.value,
        file: generalDocumentForUpload[index].originFileObj,
        contentLength: generalDocumentForUpload[index].size,
        documentName: urlObj.key,
        extention: extention,
        name: generalDocumentForUpload[index].name,
        documentSize: generalDocumentForUpload[index].size,
        documentTypeId: generalDocumentForUpload[index].documentTypeId
      };
    });

    let uploadDocResponse = await uploadFilesToS3(filesForUpload) as any;
    const errors = uploadDocResponse.filter((response: any) => !response.ok);
    if (errors.length === 0) {
      let fileNames = filesForUpload.map((file: any) => {
        return {
          FileName: file.name,
          S3FileName: file.documentName,
          Extention: file.extention,
          DocumentSize: file.documentSize,
          DocumentTypeId: file.documentTypeId
        }
      })
      await saveDocumentNames(fileNames);
      Notification('success', 'Success', MESSAGE_CONSTANT.BuyerSpecficDocumentUploadSuccessfully);
    }
    else {
      Notification('error', 'Error', MESSAGE_CONSTANT.GerberFileUploadFailed);
    }
    setIsFileUploadInProgress(false);
  }

  const saveDocumentNames = async (fileNames: any) => {
    let reqObj = {
      UserName: getUserName(),
      ProjectId: null,
      DocumentDetails: fileNames
    }
    await insertDocumentNames(reqObj);
  }

  window.onbeforeunload = function (event) {
    if (isFileUploadInProgress) {
      return 'File upload is in progress. Please wait to complete it.';
    }
  }

  useEffect(() => {
    if (isGeneralDocumentModalVisible) {
      trackPromise(getGeneralDocument());
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [isGeneralDocumentModalVisible]);

  const getGeneralDocument = async () => {
    let reqObj = {
      UserName: getUserName()
    }
    const response = await getGeneralDocuments(reqObj) as any;
    if (response) {
      setGeneralDocumentFile(response);
    }
  }

  const setGeneralDocumentFile = (generalDocumentsfile: any[]) => {
    let fileData = generalDocumentsfile.map((file) => {
      return {
        uid: file.s3FileName,
        name: file.fileName,
        id: file.id,
        size: file.documentSize
      }
    })
    setGeneralDocumentData(fileData);
  }

  const onSubmit = async () => {
    await uploadDocument();
    onCancelGeneralDocumentModal();
    if (updatedDataFlag) {
      refreshApi(true);
    }
  };

  const fileUploadProcessAlert = () => {
    Swal.fire({
      text: MESSAGE_CONSTANT.DocumentUploadGenaralfile,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        onCancelGeneralDocumentModal(false);
      }
    })

  }

  const btnProps = () => {
    let func: any;
    if (isFileUploadInProgress) {
      func = () => fileUploadProcessAlert();
    } else {
      func = () => {
        onCancelGeneralDocumentModal(false);
        setGeneralDocumentData([]);
        refreshApi(updatedDataFlag);
      }
    }
    return func;
  }

  return (
    <>
      <Spin className="cm-spinner" spinning={isFileUploadInProgress} size="large" />
      <Modal
        visible={isGeneralDocumentModalVisible}
        maskClosable={false}
        width="45%"
        title="Upload Buyer Specific Documents"
        okText="Submit"
        cancelText="Cancel"
        onOk={onSubmit}
        okButtonProps={{
          disabled: isFileUploadInProgress,
        }}
        cancelButtonProps={{ onClick: btnProps() }}
        onCancel={btnProps()}
        destroyOnClose={true}
      >
        <div className="uploadFileHeight">
          <Dragger {...generalDocumentProps}>
            <p className="ant-upload-text content-text">
              Drag and drop a file here to upload
            </p>
            <p className="content-text">or</p>
            <p className="ant-upload-hint select-file">Select a file</p>
          </Dragger>
        </div>
        <Alert type="warning" style={{ marginTop: '10px' }} icon={<WarningOutlined size={24} />} showIcon
          message={`Note: These documents are required to upload only once. This will be visible in all your quotes. It limits up to ${maximumGeneralDocumentizeInMB} mb total size at once.`} />
      </Modal>
    </>
  );
}

export default UploadGeneralDocument;
