import { URL_CONSTANT } from "../constants/UrlConstants";
import useCommonHttpService from "./useCommonHttpService";

export default function usePCBService() {
    const { httpGet } = useCommonHttpService();

    function getPCBPricingQuestionsAction() {
        return httpGet(URL_CONSTANT.PCB.GetPCBPricingQuestions, null);
    }

    function getPCBSpecificationQuestionsAction() {
        return httpGet(URL_CONSTANT.PCB.GetPCBSpecificationQuestions, null);
    }

    return { getPCBPricingQuestionsAction, getPCBSpecificationQuestionsAction };
}