import { Layout, Menu, Image } from 'antd';
import { DashboardFilled, TeamOutlined, ProfileFilled, DoubleRightOutlined, DoubleLeftOutlined, SettingOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useNavigate } from "react-router-dom";
import useSessionStorageService from '../../hooks/UseSessionStorageService';
import { Currency, Customer, Dashboard, Quote } from '../../assets/icons';
import { DATA_CONSTANT } from '../../constants/DataConstants';

export const SideBar = () => {
    const { Sider } = Layout;
    const [collapsed, setCollapsed] = useState(true);
    const navigate = useNavigate();
    const { getUserView, setUserView } = useSessionStorageService();

    const [defaultSelectedKeysBuyer, setDefaultSelectedKeysBuyer] = useState<string>("buyer");
    const [defaultSelectedKeysSupplier, setDefaultSelectedKeysSupplier] = useState<string>("supplier");

    const buyerMenuItems = useMemo(() => {
        return [
            { label: 'Dashboard', key: 'buyer', icon: <span style={{ paddingRight: '8px' }}><Image src={Dashboard} width={20} height={20} preview={false} /></span> },
            { label: 'My Suppliers', key: 'vendor', icon: <span style={{ paddingRight: '8px' }}><Image src={Customer} width={20} height={20} preview={false} /></span> },
            { label: 'My Quotes', key: 'quotes', icon: <span style={{ paddingRight: '8px' }}><Image src={Quote} width={20} height={20} preview={false} /></span> },
            { label: 'Currency Conversion', key: 'currencyconversion', icon: <span style={{ paddingRight: '8px' }}><Image src={Currency} width={20} height={20} preview={false} /></span> }
        ]
    }, []);
    const supplierMenuItems = useMemo(() => {
        return [
            { label: 'Dashboard', key: 'supplier', icon: <span style={{ paddingRight: '8px' }}><Image src={Dashboard} width={20} height={20} preview={false} /></span> },
        ];
    }, []);

    const onMenuChange = useCallback((e: any) => {
        if (e === 'supplier') {
            setDefaultSelectedKeysSupplier("supplier")
        } else {
            setDefaultSelectedKeysBuyer(e.key)
        }
        navigate(`/${e.key}`);
    }, [navigate]);

    const menuTag = useMemo(() => {
        let tag: React.ReactNode;
        if (getUserView() === DATA_CONSTANT.UserTypes.Buyer) {
            tag = (
                <Menu
                    key={Math.random().toString()}
                    mode="inline"
                    style={{ height: '100%', fontSize: '16px' }}
                    selectedKeys={[defaultSelectedKeysBuyer]}
                    onClick={(event) => { onMenuChange(event) }}
                    items={buyerMenuItems} />
            )
        } else {
            tag = (
                <Menu
                    key={Math.random().toString()}
                    mode="inline"
                    style={{ height: '100%', fontSize: '16px' }}
                    selectedKeys={[defaultSelectedKeysSupplier]}
                    onClick={(event) => { onMenuChange(event) }}
                    items={supplierMenuItems} />
            )
        }
        return tag;
    }, [defaultSelectedKeysBuyer, defaultSelectedKeysSupplier, getUserView, buyerMenuItems, onMenuChange, supplierMenuItems])

    useEffect(() => {
        if (menuTag.props.items.length === 1) {
            setDefaultSelectedKeysSupplier("supplier")
        } else {
            setDefaultSelectedKeysBuyer("buyer")
        }
    }, [menuTag.props.items])

    let checkUser = "buyer";
    const checkUrl = useMemo(() => {
        let data = '';
        if (getUserView() === DATA_CONSTANT.UserTypes.Buyer) {
            checkUser = "buyer";
            data = window.location.pathname.split('/')[1];
        } else {
            checkUser = "supplier";
            data = window.location.pathname.split('/')[1];
        }
        return data;
    }, [getUserView]);

    useEffect(() => {
        updateUserView();
        if (checkUser === DATA_CONSTANT.UserTypes.Buyer) {
            setDefaultSelectedKeysBuyer(checkUrl)
        } else {
            setDefaultSelectedKeysSupplier(checkUrl)
        }
    }, [checkUser, checkUrl])

    const updateUserView = () => {
        const key = window.location.pathname.split('/')[1];
        const isBuyer = buyerMenuItems.some((menu: any) => menu.key === key);
        const isSupplier = supplierMenuItems.some((menu: any) => menu.key === key);

        if (isBuyer && getUserView() === DATA_CONSTANT.UserTypes.Supplier) {
            setUserView(DATA_CONSTANT.UserTypes.Buyer);
            window.location.reload();
        }
        else if (isSupplier && getUserView() === DATA_CONSTANT.UserTypes.Buyer) {
            setUserView(DATA_CONSTANT.UserTypes.Supplier);
            window.location.reload();
        }
    }

    return (
        <Sider width={200}
            trigger={null} collapsible collapsed={collapsed}
            className="site-layout-background cm-aside">
            <div className="sider-logo">
                {collapsed ?
                    <DoubleRightOutlined
                        className='sider-trigger'
                        onClick={() => setCollapsed(!collapsed)} /> :
                    <DoubleLeftOutlined
                        className='sider-trigger'
                        onClick={() => setCollapsed(!collapsed)} />}
            </div>
            {menuTag}

        </Sider>
    )
}