import React, { useEffect, useState } from "react";
import useSessionStorageService from "../../hooks/UseSessionStorageService";
import useSupplierContactService from "../../hooks/UseSupplierContactService";
import { ResponseTimeRanking } from "./ResponseTimeRanking";

const PCBResponseTimeRanking = (props: any) => {
  const { projectId } = props;
  const [supplierRankData, setSupplierRankData] = useState<any>([]);
  const { getPCBSupplierResponseTime } = useSupplierContactService();
  const { getUserView, getDefaultView, getUserName } =
    useSessionStorageService();

  const getPCBRanking = async () => {
    const userName =
      getUserView() === "supplier" || getDefaultView() === "supplier"
        ? getUserName()
        : "";
    let response = (await getPCBSupplierResponseTime(
      projectId,
      userName
    )) as any;
    setSupplierRankData(response);
  };

  useEffect(() => {
    getPCBRanking();
  }, []);

  return <ResponseTimeRanking supplierRankData={supplierRankData} />;
};

export default PCBResponseTimeRanking;
