import { useCallback, useEffect, useState } from "react";
import { Tooltip, Button, PageHeader, PaginationProps, Tag, Space, Image, Row, Radio, RadioChangeEvent, Col, Avatar, Typography, Dropdown, Menu, Checkbox, Badge } from "antd";
import useQuoteService from "../../hooks/UseQuoteService";
import useSessionStorageService from "../../hooks/UseSessionStorageService";
import { trackPromise } from 'react-promise-tracker';
import { DollarCircleOutlined } from '@ant-design/icons';
import { MESSAGE_CONSTANT } from "../../constants/MessageConstants";
import { FaMedal } from "react-icons/fa";
import { useNavigate } from 'react-router';
import { DropdownIcon, Eye, Win } from '../../assets/icons/index';
import { CustomTable } from "../CustomTable";
import { ColumnsType } from "antd/lib/table";
import { customTagColor } from "../../components/helpers/utility";
import { DATA_CONSTANT } from "../../constants/DataConstants";
import "../../components/styles/global.css";

export interface CustomType {
    enableSearch?: boolean
}

export type CustomColumnsType<T> = CustomType & ColumnsType<T>

export const SupplierDashBoard = () => {
    const navigate = useNavigate();
    const { getQuoteBySupplierContact } = useQuoteService();
    const { getUserName } = useSessionStorageService();
    const filterOptions: any = DATA_CONSTANT.QuoteTypes;

    const [quoteData, setQuoteData] = useState<any>({
        quotes: [],
        filteredQuotes: [],
        status: 'All',
    });
    const [statusCount, setStatusCount] = useState<any>({
        allStatusCount: 0, pendingStatusCount: 0, inProgressStatusCount: 0, submittedStatusCount: 0
    });
    const [current, setCurrent] = useState(1);
    const [pagination, setPagination] = useState<any>({
        current: 1,
        pageSize: 5
    });


    useEffect(() => {
        trackPromise(getSupplierQuotes());
    }, []);

    const getSupplierQuotes = async () => {
        let userName = getUserName();
        let quotesData = await getQuoteBySupplierContact(userName);
        setQuoteData((preState: any) => ({ ...preState, quotes: quotesData, filteredQuotes: quotesData }));
    };

    const providePricing = (data: any) => {
        navigate("/supplierpricing", { state: { quoteInfo: data } });
    };

    const getRanking = (data: any) => {
        navigate("/ranking", {
            state: {
                quoteId: data.id
            }
        });
    };

    const columns: any = [
        {
            key: "rewardStatus",
            name: "rewardStatus",
            dataInddex: "rewardStatus",
            title: () => {
                return (
                    <>
                        <Row>Reward Status</Row>
                        <Row style={{ borderTop: '1px solid #f0f0f0', marginBottom: '-5px', paddingTop: '5px' }}>
                            <Col span={12}>
                                <Tooltip title="PCB">
                                    <Avatar icon='P' size={26} style={{ backgroundColor: 'green' }} />
                                </Tooltip>
                            </Col>
                            <Col span={12} style={{ borderLeft: '1px solid #f0f0f0' }}>
                                <Tooltip title="Stencil">
                                    <Avatar icon='S' size={26} style={{ backgroundColor: '#0d6efd' }} />
                                </Tooltip>
                            </Col>
                        </Row>
                    </>
                )
            },
            fixed: "left",
            width: "14%",
            align: 'center',
            render: (text: any, record: any) => (
                <Row>
                    <Col span={12}>
                        {record.isPCBQuote && record.isPCBPricingCompleted && (
                            record.pcbPricingDetail && record.pcbPricingDetail.isPricingSelected === true ?
                                <Tooltip title="Quote Win"><Image src={Win} width={24} height={24} preview={false} /></Tooltip>
                                :
                                <span className="cm-rewardStatus"> <Tooltip title="Quote Loss"><Tag color="red">Loss</Tag></Tooltip></span>
                        )
                        }
                    </Col>
                    <Col span={12} style={{ borderLeft: '1px solid #f0f0f0' }}>
                        {record.isStencilQuote && record.isStencilPricingCompleted && (
                            record.stencilPricingDetail && record.stencilPricingDetail.isPricingSelected === true ?
                                <Tooltip title="Quote Win"><Image src={Win} width={24} height={24} preview={false} /></Tooltip>
                                :
                                <span className="cm-rewardStatus"> <Tooltip title="Quote Loss"><Tag color="red">Loss</Tag></Tooltip></span>
                        )
                        }
                    </Col>
                </Row>
            )
        },
        {
            key: "projectName",
            title: "Project Name",
            dataIndex: "projectName",
            width: "20%",
            sorter: (a: any, b: any) =>
                a.projectName?.length - b.projectName?.length,
            sortDirections: ["descend", "ascend"],
            enableSearch: true,
            ellipsis: true
        },
        {
            key: "quoteTypeName",
            title: "Quote Type",
            dataIndex: "quoteTypeName",
            width: "10%",
        },
        {
            key: "quoteDate",
            title: "Issue Date",
            dataIndex: "quoteDate",
            width: "12%",
            render: (text: any, record: any) => (
                <>
                    {new Date(record.quoteDate).toLocaleString("default", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                    })}
                </>
            ),
        },
        {
            key: "quoteDueDate",
            title: "Due Date",
            dataIndex: "quoteDueDate",
            width: "12%",
            render: (text: any, record: any) => (
                <>
                    {new Date(record.quoteDueDate).toLocaleString("default", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                    })}
                </>
            ),
        },
        {
            key: "submittedDate",
            title: () => {
                return (
                    <>
                        <Row>
                            <Col className="cm-text-center" span={24}>Submitted Date</Col>
                        </Row>
                        <Row style={{ borderTop: '1px solid #f0f0f0', marginBottom: '-5px', paddingTop: '5px' }}>
                            <Col span={12} className="cm-text-center">
                                <Tooltip title="PCB">
                                    <Avatar icon='P' size={26} style={{ backgroundColor: 'green' }} />
                                </Tooltip>
                            </Col>
                            <Col span={12} className="cm-text-center" style={{ borderLeft: '1px solid #f0f0f0' }}>
                                <Tooltip title="Stencil">
                                    <Avatar icon='S' size={26} style={{ backgroundColor: '#0d6efd' }} />
                                </Tooltip>
                            </Col>
                        </Row>
                    </>
                )
            },
            width: "25%",
            render: (text: any, record: any) => (
                <Row>
                    <Col span={12}>
                        {record.isPCBQuote && record.pcbPricingDetail &&
                            new Date(record.pcbPricingDetail.submittedDate).toLocaleString("default", {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                            })}
                    </Col>
                    <Col span={12} style={{ borderLeft: '1px solid #f0f0f0', paddingLeft: '10px' }}>
                        {record.isStencilQuote && record.stencilPricingDetail &&
                            new Date(record.stencilPricingDetail.submittedDate).toLocaleString("default", {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                            })}
                    </Col>
                </Row >
            )

        },
        {
            key: "status",
            title: "Pricing Status",
            width: "10%",
            align: 'center',
            render: (text: any, record: any) => (
                <Tag color={customTagColor(record.pricingStatus)}>
                    {record.pricingStatus}
                </Tag>
            ),
        },
        {
            key: "Action",
            title: "Action",
            width: "7%",
            align: 'center',
            render: (text: any, record: any) =>
                record.quoteStatus.toLowerCase() ===
                    MESSAGE_CONSTANT.PricingPending ? (
                    <Tooltip title="Pricing Pending">
                        <Button
                            id='supplierDashboard_pricePending'
                            size="large"
                            style={{ border: "none", background: 'none' }}
                            onClick={() => providePricing(record)}
                            icon={<DollarCircleOutlined />}
                        />
                    </Tooltip>
                ) : (
                    <Space>
                        <Tooltip title="View Pricing">
                            <Image
                                style={{ border: "none", background: 'none' }}
                                src={Eye}
                                alt=""
                                width={20}
                                height={20}
                                preview={false}
                                onClick={() => providePricing(record)}
                                className='cm-cursor-pointer'
                            />
                        </Tooltip>
                        <Tooltip title="Rank">
                            <Button
                                id='supplierDashboard_rank'
                                size="middle"
                                style={{ border: "none", background: 'none' }}
                                onClick={() => getRanking(record)}
                                icon={<FaMedal />}
                            />
                        </Tooltip>
                    </Space>
                ),
        },
    ];

    const onStatusChange = useCallback((e: RadioChangeEvent) => {
        if (e.target.value === 'All') {
            setQuoteData((preState: any) => ({
                ...preState,
                filteredQuotes: quoteData.quotes,
                status: e.target.value,
            }));
        } else {
            const FilterByData: any = [...quoteData.quotes.filter((ele: any) => ele.pricingStatus.includes(e.target.value))];
            setQuoteData((preState: any) => ({
                ...preState,
                filteredQuotes: FilterByData,
                status: e.target.value,
            }));
        }
    }, [quoteData.quotes]);

    const onQuoteTypeFilterChange = (filterQuoteTypes: any) => {
        if (filterQuoteTypes.length > 0) {
            const filteredQuotesCopy = [...quoteData.quotes];
            const filteredQuotes = filteredQuotesCopy.filter((item: any) => item.quoteTypeName.includes(filterQuoteTypes.toString()));
            setQuoteData((preState: any) => ({ ...preState, filteredQuotes: filteredQuotes }));
        }
        else {
            setQuoteData((preState: any) => ({ ...preState, filteredQuotes: preState.quotes }));
        }
    }

    useEffect(() => {
        setStatusCount({
            allStatusCount: [...quoteData.quotes]?.length,
            pendingStatusCount: [...quoteData.quotes]?.filter((item: any) => item.pricingStatus === DATA_CONSTANT.QuoteStatus.Pending).length,
            inProgressStatusCount: [...quoteData.quotes]?.filter((item: any) => item.pricingStatus === DATA_CONSTANT.QuoteStatus.InProgress).length,
            submittedStatusCount: [...quoteData.quotes]?.filter((item: any) => item.pricingStatus === DATA_CONSTANT.QuoteStatus.Submitted).length,
        });

    }, [quoteData.quotes]);

    const menu: any = (
        <Menu>
            <Menu.Item >
                <Checkbox.Group style={{ width: '100%' }} options={filterOptions} onChange={onQuoteTypeFilterChange} />
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <PageHeader title="DashBoard" />
            <Row align="middle" justify="space-between" style={{ margin: "0 10px 10px 20px" }}>
                <Col>
                    <Dropdown
                        overlay={menu}
                        placement="bottomRight"
                        trigger={["hover"]}
                        getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                    >
                        <Button> Filter Quotes <Image
                            src={DropdownIcon}
                            alt="dropdown"
                            height={24}
                            width={24}
                            style={{ marginLeft: '8px' }}
                            preview={false}
                        /></Button>
                    </Dropdown>
                </Col>
                <Col>
                    <Space>
                        <label style={{ fontWeight: "bold" }}>Status :</label>
                        <Radio.Group onChange={(e) => onStatusChange(e)} value={quoteData.status}>
                            <Radio className="cm-statusRadioAll" value={DATA_CONSTANT.QuoteStatus.All}><Badge size="small" offset={[5, -8]} overflowCount={statusCount.allStatusCount} count={statusCount.allStatusCount} showZero >All</Badge></Radio>
                            <Radio className="cm-statusRadio" value={DATA_CONSTANT.QuoteStatus.Pending}><Badge size="small" offset={[5, -8]} count={statusCount.pendingStatusCount} showZero >Pending</Badge></Radio>
                            <Radio className="cm-statusRadio" value={DATA_CONSTANT.QuoteStatus.InProgress}><Badge size="small" offset={[5, -8]} count={statusCount.inProgressStatusCount} showZero >InProgress</Badge></Radio>
                            <Radio className="cm-statusRadio" value={DATA_CONSTANT.QuoteStatus.Submitted}><Badge size="small" offset={[5, -8]} count={statusCount.submittedStatusCount} showZero >Submitted</Badge></Radio>
                        </Radio.Group>
                    </Space>
                </Col>
            </Row>
            <CustomTable
                style={{ padding: '5px', margin: '0px 15px' }}
                columns={columns}
                dataSource={quoteData.filteredQuotes}
                rowKey='id'
                pagination={{
                    pageSize: pagination.pageSize,
                    showSizeChanger: true,
                    onChange: (page: any, pageSize: any) => {
                        setPagination({ current: page, pageSize: pageSize })
                    }
                }}
            />
        </>
    )
}