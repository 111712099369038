import { useState, useEffect } from "react";
import { Row, Col, Form, Space, Input, Button, Select, Typography } from "antd";
import UseQuoteService from "../../hooks/UseQuoteService";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import FormItem from "antd/es/form/FormItem";


const { Option } = Select;

export const QuantityPricing = (props: any) => {
    const { unicode, quoteQuantities, turnTimeUOM } = props;
    const [boardQuantities, setBoardQuantities] = useState<any>([]);

    useEffect(() => {
        props.handleQuantityData(boardQuantities);
    }, [boardQuantities]);


    useEffect(() => {
        setQuantityDefaultData(turnTimeUOM);
    }, [quoteQuantities])

    const handleTimeUomChanged = (
        value: any,
        turnTimeIndex: any,
        qtyIndex: any,
        supplierTurnTimeIndex: any,
    ) => {
        let copiedBoardQuantities = [...boardQuantities];

        let copiedTurnTime = copiedBoardQuantities[qtyIndex].turntimeUom;

        let supplierTurnTimes = copiedTurnTime[turnTimeIndex].supplierTurnTimeAndUnitPrice;
        supplierTurnTimes[supplierTurnTimeIndex] = { ...supplierTurnTimes[supplierTurnTimeIndex], turnTimeUOMId: value };

        copiedBoardQuantities[qtyIndex].turntimeUom = copiedTurnTime;
        setBoardQuantities(copiedBoardQuantities);
    };

    const setQuantityDefaultData = (turnTimeUOMs: any[]) => {
        if (props.projectId > 0 && quoteQuantities.length > 0) {
            let quantityListObj = [] as any;

            quoteQuantities.forEach((element: any, index: any) => {
                let quantity = {} as any;
                quantity.id = element.id;
                quantity.customQuantityId = index;
                quantity.quantity = element.qty;
                quantity.turntimeUom = [] as any;
                element.turnTimes.forEach((turnTime: any, index: any) => {
                    let turntime = {} as any;
                    turntime.id = turnTime.id;
                    turntime.customTurnTimeId = index;
                    turntime.timeUomValue = turnTime.turntime;
                    turntime.price = turnTime.price;
                    turntime.turnTimeUOMName = turnTimeUOMs.find((x: any) => x.id === turnTime.turnTimeUOMId)?.name;
                    turntime.supplierTurnTimeAndUnitPrice = turnTime.supplierTurnTimeAndUnitPrice ?? [];
                    quantity.turntimeUom.push(turntime);
                });
                quantityListObj.push(quantity);
            });
            setBoardQuantities(quantityListObj);
        }
    };

    const handlePriceChanged = (
        qtyIndex: any,
        turntimeIndex: any,
        sTurnTimeIndex: number,
        event: any
    ) => {
        let value = event.target.value;

        let copiedBoardQuantities = [...boardQuantities];

        let copiedTurnTime = copiedBoardQuantities[qtyIndex].turntimeUom;

        if (sTurnTimeIndex === -1) {
            copiedTurnTime[turntimeIndex] = { ...copiedTurnTime[turntimeIndex], price: value };
        } else {
            let supplierTurnTimes = copiedTurnTime[turntimeIndex].supplierTurnTimeAndUnitPrice;
            let name = event.target.name;
            supplierTurnTimes[sTurnTimeIndex] = { ...supplierTurnTimes[sTurnTimeIndex], [name]: value };
        }

        copiedBoardQuantities[qtyIndex].turntimeUom = copiedTurnTime;
        setBoardQuantities(copiedBoardQuantities);
    };


    const addSupplierTurnTime = (qtyIndex: any, turnTimeIndex: any) => {
        let newBoardQuantities = boardQuantities.slice();
        (newBoardQuantities[qtyIndex]['turntimeUom'][turnTimeIndex]['supplierTurnTimeAndUnitPrice']).push({
            'unitPrice': 0, 'turntime': 1, 'turnTimeUOMId': turnTimeUOM[0].id
        })
        setBoardQuantities(newBoardQuantities);
    };

    const removeSupplierTurnTime = (qtyIndex: any, turnTimeIndex: any, supplierTurnTimeIndex: any) => {
        let newBoardQuantities = boardQuantities.slice();
        (newBoardQuantities[qtyIndex]['turntimeUom'][turnTimeIndex]['supplierTurnTimeAndUnitPrice']).splice(supplierTurnTimeIndex, 1);
        setBoardQuantities(newBoardQuantities);
    };

    return (
        <>
            {boardQuantities.length > 0 &&
                boardQuantities.map((qty: any, qtyIndex: any) => (
                    <Row key={`qty_${qtyIndex}`}
                        style={{ borderBottom: "1px solid #d9d9d9", padding: "8px" }}
                    >
                        <Col span={6} className='cm-pr-10'>
                            <Form.Item
                                key={qty.customQuantityId}
                                label="Quantity:"
                                name={[qtyIndex, "Quantity"]}
                                initialValue={qty.quantity}
                                className="mb-0"
                            >
                                <Input value={qty.quantity} disabled />
                            </Form.Item>
                        </Col>
                        <Col span={18}>
                            {qty.turntimeUom.map((turntime: any, turnTimeIndex: any) => (
                                <div key={`divturnTime_${turnTimeIndex}_${qtyIndex}`} style={{ border: "1px solid #d9d9d9", padding: "8px 20px 0px 8px", marginBottom: '8px' }}>
                                    <Row key={`turnTime_${turnTimeIndex}_${qtyIndex}`}>
                                        <Col span={13}>
                                            <Row>
                                                <Col span={16} className='cm-pr-10'>
                                                    <FormItem label={<span>&nbsp;&nbsp; Turn Time</span>} className="mb-2">
                                                        <Input defaultValue={turntime.timeUomValue} value={turntime.timeUomValue} disabled />
                                                    </FormItem>
                                                </Col>
                                                <Col span={8}>
                                                    <FormItem className="mb-2">
                                                        <Input defaultValue={turntime.turnTimeUOMName} value={turntime.turnTimeUOMName} disabled />
                                                    </FormItem>
                                                </Col>

                                            </Row>
                                        </Col>
                                        <Col span={11} className='cm-pl-10'>
                                            <Row>
                                                <Col span={23}>
                                                    <Form.Item
                                                        name={["unitPrice", turnTimeIndex, qtyIndex]}
                                                        className="mb-2"
                                                        initialValue={turntime?.price}
                                                        rules={[{ required: true, message: '' },
                                                        { pattern: new RegExp(/^[0-9]{1,6}(\.[0-9]{0,3})?$/), message: '' }
                                                        ]}
                                                        label={
                                                            <span>Unit Price {" "}<b>{unicode}</b></span>
                                                        }
                                                    >
                                                        <Input
                                                            onChange={(event: any) => handlePriceChanged(qtyIndex, turnTimeIndex, -1, event)}
                                                            maxLength={10}
                                                            disabled={props.isPricingDone}
                                                        />

                                                    </Form.Item>
                                                </Col>
                                                <Col span={1}>
                                                    {
                                                        !props.isPricingDone && turntime.supplierTurnTimeAndUnitPrice.length === 0 &&
                                                        (< FormItem className="mb-2">
                                                            {turntime.id !== 0 &&
                                                                <Button
                                                                    title="Add Your TurnTime"
                                                                    style={{ border: "none", background: 'none' }}
                                                                    onClick={() => { addSupplierTurnTime(qtyIndex, turnTimeIndex) }}
                                                                    icon={<PlusOutlined />}
                                                                    disabled={props.isPricingDone}
                                                                />}
                                                        </FormItem>)
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    {turntime?.supplierTurnTimeAndUnitPrice &&
                                        turntime?.supplierTurnTimeAndUnitPrice?.map((sTurnTime: any, sTurnTimeIndex: number) => (
                                            <Row key={`supplierTurnTime_${sTurnTimeIndex}_${turnTimeIndex}_${qtyIndex}`} style={{ marginBottom: '-16px' }}>
                                                <Col span={13}>
                                                    <Row>
                                                        <Col span={16} className='cm-pr-10'>
                                                            <Form.Item label="Turn Time"
                                                                name={["supplierTurnTime", sTurnTimeIndex, turnTimeIndex, qtyIndex]}
                                                                initialValue={sTurnTime.turntime}
                                                                rules={[{ required: true, message: '' },
                                                                { pattern: new RegExp(/^[0-9]{1,3}$/), message: '' }]}
                                                            >
                                                                <Input
                                                                    placeholder="Turn time"
                                                                    name="turntime"
                                                                    onChange={(event: any) => handlePriceChanged(qtyIndex, turnTimeIndex, sTurnTimeIndex, event)}
                                                                    maxLength={3}
                                                                    disabled={props.isPricingDone}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={8}>
                                                            <Form.Item
                                                                name={["supplierTurnTimeUOMName", sTurnTimeIndex, turnTimeIndex, qtyIndex]}
                                                                initialValue={sTurnTime.turnTimeUOMId}
                                                            >
                                                                <Select
                                                                    onChange={(selectedValue) => handleTimeUomChanged(selectedValue, turnTimeIndex, qtyIndex, sTurnTimeIndex)}
                                                                    disabled={props.isPricingDone}
                                                                >
                                                                    {turnTimeUOM && turnTimeUOM.map((item: any) => (
                                                                        <Option value={item.id} key={item.id}>
                                                                            {item.name}
                                                                        </Option>
                                                                    ))}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>

                                                    </Row>
                                                </Col>
                                                <Col span={11} className='cm-pl-10'>
                                                    <Row>
                                                        <Col span={23}>
                                                            <Form.Item
                                                                name={["supplierUnitPrice", sTurnTimeIndex, turnTimeIndex, qtyIndex]}
                                                                initialValue={sTurnTime.unitPrice}
                                                                rules={[{ required: true, message: '' },
                                                                { pattern: new RegExp(/^[0-9]{1,6}(\.[0-9]{0,3})?$/), message: '' }
                                                                ]}
                                                                label={
                                                                    <span>Unit Price {" "}<b>{unicode}</b></span>
                                                                }
                                                            >
                                                                <Input name="unitPrice"
                                                                    onChange={(event: any) => handlePriceChanged(qtyIndex, turnTimeIndex, sTurnTimeIndex, event)}
                                                                    maxLength={10}
                                                                    disabled={props.isPricingDone}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={1}>
                                                            {
                                                                !sTurnTime.id && (
                                                                    <Form.Item>
                                                                        {turntime.id !== 0 &&
                                                                            <Button style={{ border: "none", background: 'none' }}
                                                                                title="Remove Your TurnTime"
                                                                                onClick={() => { removeSupplierTurnTime(qtyIndex, turnTimeIndex, sTurnTimeIndex) }}
                                                                                icon={<CloseOutlined style={{ color: 'red' }} />}
                                                                                disabled={props.isPricingDone}
                                                                            />}
                                                                    </Form.Item>
                                                                )
                                                            }
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        ))
                                    }
                                </div>
                            ))
                            }
                        </Col >
                    </Row >
                ))}
        </>
    );
};
