export const DATA_CONSTANT = {
  notificationType: {
    Success: 'success',
    Info: 'info',
    Warning: 'warning',
    Error: 'error'
  },

  supplierImportColumns: {
    SupplierName: 'Supplier Name',
    PreferenceOrder: 'Preferred Order',
    ExternalId: 'External Id',
    ContactPersonName: 'Contact Person Name'
  },

  DocumentType: {
    ProjectGerberFiles: 1,
    ProjectDocuments: 2,
    GeneralDocuments: 3
  },

  QuoteStatus: {
    All: 'All',
    Pending: 'Pending',
    InProgress: 'InProgress',
    Completed: 'Completed',
    Submitted: 'Submitted',
  },

  UserTypes: {
    Buyer: 'buyer',
    Supplier: 'supplier'
  },

  QuoteTypes: [
    { label: "PCB", value: 'PCB' },
    { label: "STENCIL", value: 'Stencil' },
  ],

  StencilQuoteProperties: {
    Apertures: 'Apertures',
    FrameType: 'Frame Type',
    MaterialThickness: 'Material Thickness',
    MaterialType: 'Material Type',
    SteppedStencil: 'Stepped Stencil',
    Steps: 'Steps'
  },

  StenccilMaterilThicknes: [
    {
      value: "76",
      label: "76",
    },
    {
      value: "80",
      label: "80",
    },
    {
      value: "90",
      label: "90",
    },
    {
      value: "100",
      label: "100",
    },
    {
      value: "110",
      label: "110",
    },
    {
      value: "120",
      label: "120",
    },
    {
      value: "127",
      label: "127",
    },
    {
      value: "130",
      label: "130",
    },
    {
      value: "140",
      label: "140",
    },
    {
      value: "150",
      label: "150",
    },
    {
      value: "180",
      label: "180",
    },
    {
      value: "200",
      label: "200",
    },
    {
      value: "250",
      label: "250",
    },
    {
      value: "350",
      label: "350",
    },
  ],
  StencilFrameType: [
    {
      value: "ZelFlex",
      label: "ZelFlex",
    },
    {
      value: "ZelFlexProtect",
      label: "ZelFlex Protect",
    },
    {
      value: "AlphaTetraOriginal23X23",
      label: "AlphaTetra Original 23 X 23",
    },
    {
      value: "Apshan23X23",
      label: "Apshan23X23 ",
    },
    {
      value: "Techfoil",
      label: "Techfoil",
    },
    {
      value: "QuatroFlex",
      label: "QuatroFlex",
    },
    {
      value: "SpaceSaverFrame",
      label: "Space Saver Frame",
    },
    {
      value: "Foil",
      label: "Foil",
    },
  ],
  StencilMaterialType: [
    {
      value: "StandardFoil",
      label: "Standard Foil",
    },
    {
      value: "FinegrainFoil",
      label: "Fine grain Foil",
    },
  ],
}