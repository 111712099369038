import { UserManager } from "oidc-client"

const config = {
    authority: process.env.REACT_APP_AUTHORITY,
    client_id: process.env.REACT_APP_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    response_type: process.env.REACT_APP_RESPONSE_TYPE,
    scope: process.env.REACT_APP_SCOPE,
    post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_URL,
    automaticSilentRenew: true,
    silent_redirect_uri: process.env.REACT_APP_SILENT_RENEW_URL,
    accessTokenExpiringNotificationTime: 300
};

const userManager: UserManager = new UserManager(config);

userManager.events.addUserSignedOut(() => {
    userManager.getUser().then((user) => {
        if (user) {
            userManager.removeUser();
            userManager.signoutRedirect({ 'id_token_hint': user.id_token });
        }
    });
});

export const authService = {
    login: async (userState: any = null) => {
        return userManager.signinRedirect({ state: userState });
    },

    logout: async () => {
        userManager.getUser().then((user) => {
            if (user) {
                userManager.removeUser();
                return userManager.signoutRedirect({ 'id_token_hint': user.id_token });
            }
        });
        sessionStorage.clear();
    },

    getUser: async () => {
        return userManager.getUser();
    },

    getAccessToken: async () => {
        const user = await userManager.getUser();
        return user?.access_token;
    }
};