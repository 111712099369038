import { useContext, useState } from 'react';
import { Upload } from 'antd';
import * as xlsx from "xlsx";
import Modal from 'antd/lib/modal/Modal';
import useSessionStorageService from "../hooks/UseSessionStorageService";
import UseSupplierService from "../hooks/UseSupplierService";
import Notification from '../components/notification/Notification';
import { MESSAGE_CONSTANT } from '../constants/MessageConstants';
import { DATA_CONSTANT } from '../constants/DataConstants';

const { Dragger } = Upload;

export const ImportSupplier = ({ cancelImportSupplierModal, isImportSupplierModalVisible }: any) => {

    const [supplierList, setSupplierList] = useState<any>([]);
    const [supplierContactList, setSupplierContactList] = useState<any>([]);
    const { getUserName } = useSessionStorageService();
    const { importSupplierWithContact } = UseSupplierService();

    const uploadConfigProps = {
        name: 'file',
        maxCount: 1,
        beforeUpload: (file: any) => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = (e: any) => {
                let data = e.target.result;
                const workbook = xlsx.read(data, { type: "binary" });
                setSupplierListFromExcelFile(workbook);
                setSupplierContactListFromExcelFile(workbook);
            }
            return false;
        }
    }

    const onCancelImportSupplierModal = (isSuccess : any) => {
        setSupplierList([]);
        setSupplierContactList([]);
        cancelImportSupplierModal(isSuccess);
    }

    const setSupplierListFromExcelFile = (workbook: xlsx.WorkBook) => {
        const supplierSheetName = workbook.SheetNames[0];
        const supplierWorksheet = workbook.Sheets[supplierSheetName];
        let supplierList = xlsx.utils.sheet_to_json(supplierWorksheet, { raw: true, rawNumbers: true, defval: "", skipHidden: false });
        // Data starts from second row
        let rowNo = 1;
        supplierList = supplierList.map((supplier: any) => {
            return {
                RowNo: rowNo++,
                SupplierName: supplier[DATA_CONSTANT.supplierImportColumns.SupplierName],
                ExternalId: supplier[DATA_CONSTANT.supplierImportColumns.ExternalId],
                PreferenceOrder: (supplier[DATA_CONSTANT.supplierImportColumns.PreferenceOrder] == '') ? null : supplier[DATA_CONSTANT.supplierImportColumns.PreferenceOrder],
                Active: (supplier.Active == '') ? null : supplier.Active,
                Currency: supplier.Currency,
            }
        })
        setSupplierList(supplierList);
    }

    const setSupplierContactListFromExcelFile = (workbook: xlsx.WorkBook) => {
        const supplierContactSheetName = workbook.SheetNames[1];
        const supplierContactWorksheet = workbook.Sheets[supplierContactSheetName];
        let supplierContactList = xlsx.utils.sheet_to_json(supplierContactWorksheet, { raw: true, rawNumbers: true, defval: "", skipHidden: false });
        // Data starts from second row
        let rowNo = 1;
        supplierContactList = supplierContactList.map((supplierContact: any) => {
            return {
                RowNo: rowNo++,
                SupplierName: supplierContact[DATA_CONSTANT.supplierImportColumns.SupplierName],
                FullName: supplierContact[DATA_CONSTANT.supplierImportColumns.ContactPersonName],
                EmailId: supplierContact.Email,
                Address1: supplierContact.Address1,
                Address2: supplierContact.Address2,
                Address3: supplierContact.Address3,
                Phone: supplierContact.Phone,
                ExternalId: supplierContact[DATA_CONSTANT.supplierImportColumns.ExternalId],
                Active: (supplierContact.Active == '') ? null : supplierContact.Active
            }
        })
        setSupplierContactList(supplierContactList);
    }

    const onSubmit = async () => {
        if (supplierList?.length == 0 ?? true) {
            Notification('warning', 'Warning', MESSAGE_CONSTANT.invalidExcelFile);
            return;
        }

        let reqObj = {
            UserName: getUserName(),
            Suppliers: supplierList,
            SupplierContacts: supplierContactList
        }
        var response = await importSupplierWithContact(reqObj) as any;
        if (response != null && (response.supplierErrors.length > 0 || response.supplierContactErrors.length > 0)) {
            Notification('warning', 'Warning', MESSAGE_CONSTANT.invalidExcelFileData);
            return;
        }
        Notification('success', 'Success', MESSAGE_CONSTANT.supplierDetailsImportedSuccessfully);
        onCancelImportSupplierModal(true);
    }

    return (
        <Modal
            visible={isImportSupplierModalVisible}
            maskClosable={false}
            title='Upload Supplier Data'
            okText='Submit'
            cancelText='Cancel'
            onOk={onSubmit}
            onCancel={() => onCancelImportSupplierModal(false)}
            destroyOnClose={true}
        >
            <div className='uploadFileHeight'>
                <Dragger {...uploadConfigProps} accept=".xlsx, .xls">
                    <p className="ant-upload-text content-text">Drag and drop an Excel file here to upload
                    </p>
                    <p className="content-text">or</p>
                    <p className="ant-upload-hint select-file">
                        Select a file
                    </p>
                </Dragger>
            </div>
        </Modal>
    );
}

