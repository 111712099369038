import React, { createContext, useState } from "react";

export const BoardCQContext = createContext({
  refreshData: false,
  refreshApi: (refreshData) => {},
});

const BoardCQProvider = (data) => {
  const refreshApi = (refreshData) => {
    setBoardCQContext((prevState) => ({
      ...prevState,
      refreshData: refreshData,
    }));
  };

  const initState = {
    refreshData: false,
    refreshApi: refreshApi,
  };
  const [boardCQContext, setBoardCQContext] = useState(initState);

  return (
    <BoardCQContext.Provider value={boardCQContext}>
      {data.children}
    </BoardCQContext.Provider>
  );
};

export default BoardCQProvider;
