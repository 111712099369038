import useSessionStorageService from '../../hooks/UseSessionStorageService';
import { Navigate } from 'react-router';
interface IProps {
    children: any
}

const PrivateRoutes = ({ children }: IProps) => {
    const { getUserFullName } = useSessionStorageService();

    const user = getUserFullName();

    return user ? children : <Navigate to='/login' />;
}
export default PrivateRoutes;