import { URL_CONSTANT } from "../constants/UrlConstants";
import useCommonHttpService from "./useCommonHttpService";

export default function useSupplierService() {
    const { httpGet, httpPost } = useCommonHttpService();

    function getBuyerDashboardDetail(data: any) {
        return httpGet(URL_CONSTANT.Dashboard.GetBuyerDashboardDetail, data);
    }

    return { getBuyerDashboardDetail };
}