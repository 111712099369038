import 'antd/dist/antd.css';
import { notification } from 'antd';
import { DATA_CONSTANT } from '../../constants/DataConstants';

const displayNotification = (type: string, message: string, description?: string) => {
    switch (type) {
        case DATA_CONSTANT.notificationType.Success:
            notification['success']({
                message: message,
                description: description,
                placement: 'topRight'
            });
            return;
        case DATA_CONSTANT.notificationType.Info:
            notification['info']({
                message: message,
                description: description,
                placement: 'topRight'
            });
            return;
        case DATA_CONSTANT.notificationType.Warning:
            notification['warning']({
                message: message,
                description: description,
                placement: 'topRight'
            });
            return;
        case DATA_CONSTANT.notificationType.Error:
            notification['error']({
                message: message,
                description: description,
                placement: 'topRight'
            });

    }
};

export default displayNotification;