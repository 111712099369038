
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './components/routes/PrivateRoute';
import routes from './HomeRoutes';
import { Login } from './pages/Login';
import ErrorPage from './pages/error/ErrorPage';


const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<Login />} path='/login' />
      {
        routes?.map((el: { path: string, component: any }, index: number) => (
          <Route
            path={`${el.path}`}
            key={index.toString()}
            element={<PrivateRoute>{el.component}</PrivateRoute>}
          />
        ))
      }
      <Route element={<PrivateRoute><ErrorPage /></PrivateRoute>} path='/error' />
    </Routes>
  );
};

export default AppRoutes;