import { Header } from 'antd/lib/layout/layout';
import { Row, Col } from "antd";
import CalcuQuoteLogo from "../../images/calcuquote_logo.png";
import "../styles/Header.css";
import { LINK_HOST } from "../../constants/LinkConstants";
import TopNavMenu from './TopNavMenu';
export const BoardCQHeader = () => {
    return (
        <Header className="cm-header">
            <Row justify="space-between">
                <Col>
                    <a href={LINK_HOST} target="_blank" rel="noreferrer">
                        <img src={CalcuQuoteLogo} className="calcuquote-logo-image" alt="CalcuQuote Logo" />
                    </a>
                </Col>
                <Col>
                    <TopNavMenu />
                </Col>
            </Row>
        </Header>
    )
}