import { Col, List, Row, Space } from 'antd';
import { ConvertOrdinal } from "../helpers/utility";
import { FaMedal } from "react-icons/fa";
import useSessionStorageService from "../../hooks/UseSessionStorageService";
import moment from "moment";
import "../styles/ranking.css";

export const ResponseTimeRanking = (props: any) => {
    const { supplierRankData } = props;
    const { getUserView, getDefaultView } = useSessionStorageService();
    
    const getRelativeTime = (startDate: any, endDate: any) => {
        let momentStartAt = moment(startDate);
        let momentEndAt = moment(endDate);
        let duration = moment.duration(momentEndAt.diff(momentStartAt));
        let days = duration.days();
        let hours = duration.hours();
        let minutes = duration.minutes();
        return `${days} ${(days > 1 ? 'days' : 'day')} ` +
            `${hours} hour ` +
            `${minutes} ${(minutes > 1 ? 'minutes' : 'minute')}`
    }

    return (
        <>
            <Row gutter={1} style={{ border: '1px solid #f0f0f0', borderRadius: '10px', margin: '2%' }}>
                <Col span={9}>
                    <h2><Space style={{ padding: '15%' }}>Response Time Ranking<FaMedal /> </Space></h2>
                </Col>
                <Col span={15}>
                    <div>
                        {getUserView() === 'supplier' || getDefaultView() === 'supplier' ?

                            <List
                                size="large"
                                className={supplierRankData.length == 1 ? 'default-rank' : ''}
                                style={{ width: '80%', marginTop: '5%' }}
                                dataSource={supplierRankData}
                                renderItem={(item: any) => (
                                    supplierRankData.length == 1 ?
                                        <List.Item>
                                            <Space>
                                                <div>
                                                    <b style={{ fontSize: '80px' }}>{ConvertOrdinal(item.supplierRank)} </b>
                                                    <sub style={{ fontSize: '15px', fontWeight: '500' }}>{getRelativeTime(item.quoteDate, item.submittedDate)} </sub>
                                                </div>

                                            </Space>
                                        </List.Item> :
                                        <>
                                            <List.Item>
                                                <Space>
                                                    <b style={{ fontSize: '20px' }}>
                                                        {item.supplierRank == 1 ? 'Fastest Response :'
                                                            : 'Your Rank : ' + ConvertOrdinal(item.supplierRank)}
                                                    </b>
                                                    <span style={{ fontWeight: '500' }}>{getRelativeTime(item.quoteDate, item.submittedDate)} </span>
                                                </Space>
                                            </List.Item>
                                        </>
                                )} />

                            : (supplierRankData.length > 0 ?
                                <List
                                    size="large"
                                    style={{ width: '80%', marginTop: '2%' }}
                                    dataSource={supplierRankData}
                                    renderItem={(item: any) =>
                                        <List.Item>
                                            <Space style={{ fontSize: '20px' }}> <b>{ConvertOrdinal(item.supplierRank)}</b> {item.supplierName}
                                                <span style={{ fontSize: '12px', fontWeight: '500' }}>{getRelativeTime(item.quoteDate, item.submittedDate)} </span>
                                            </Space>
                                        </List.Item>} />
                                : <div style={{ margin: '12%' }}>Pricing not submitted</div>)
                        }
                    </div>
                </Col>
            </Row>
        </>
    )
}