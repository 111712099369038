import { URL_CONSTANT } from "../constants/UrlConstants";
import useCommonHttpService from "./useCommonHttpService";

export default function useSupplierService() {
    const { httpGet, httpPost } = useCommonHttpService();

    function getSupplierNamesForDropdown(obj: any) {
        return httpGet(URL_CONSTANT.Supplier.getSupplierNamesForDropdown, obj);
    }

    function getAllSupplierWithContact(obj: any) {
        return httpGet(URL_CONSTANT.Supplier.getAllSupplierWithContact, obj);
    }

    function addSupplier(obj: any) {
        return httpPost(URL_CONSTANT.Supplier.addSupplier, obj);
    }

    function importSupplierWithContact(obj: any) {
        return httpPost(URL_CONSTANT.Supplier.importSupplierWithContact, obj);
    }

    function getSupplierTypeAction() {
        return httpGet(URL_CONSTANT.SupplierType.GetSupplierType, {});
    }

    function getSupplierById(req: any) {
        return httpGet(URL_CONSTANT.Supplier.getSupplierById, req);
    }

    function updateSupplierType(req: any) {
        return httpPost(URL_CONSTANT.Supplier.updateSupplierType, req);
    }

    return { getSupplierNamesForDropdown, getAllSupplierWithContact, addSupplier, importSupplierWithContact, getSupplierTypeAction, getSupplierById, updateSupplierType };
}