import { Form, Select } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useCurrencyConversionServices from "../../hooks/useCurrencyConversionServices";

function AllCurrency(props: any) {
  const { selectedView, disabled, selectedCurrency } = props;
  const { getAllCurrency } = useCurrencyConversionServices();
  const [allcurrency, setAllCurrency] = useState<any>([]);
  const [unicode, setUnicode] = useState<string>('');
  const { Option } = Select;

  const getAllCurrencyAPI = async () => {
    const response = (await getAllCurrency()) as any;
    setAllCurrency(response);
  };

  const filterbyCurrency = useMemo(() => {
    let value: any = null;
    if (selectedCurrency !== undefined) {
      value = allcurrency?.find((el: any) => el.currency === selectedCurrency);
    }
    return value;
  }, [allcurrency, selectedCurrency]);

  const defaultCurrency = useMemo(() => {
    let value: any = null;
    if (allcurrency.length) {
      value = allcurrency?.find((el: { default: boolean }) => el.default);
    }
    return value;
  }, [allcurrency]);

  const setCurrency = useCallback(
    (data: any) => {
      if (data) {
        setUnicode(data.currency);
        selectedView?.(data);
      }
    },
    [selectedView]
  );

  useEffect(() => {
    if (defaultCurrency !== null) {
      setCurrency(defaultCurrency);
    }
  }, [setCurrency, defaultCurrency]);

  useEffect(() => {
    if (filterbyCurrency !== null) {
      setCurrency(filterbyCurrency);
    }
  }, [setCurrency, filterbyCurrency]);

  useEffect(() => {
    getAllCurrencyAPI();
  }, []);

  const onchangeCurrencyhandler = (ele: any) => {
    const data = allcurrency.find((i: any) => i.currency === ele) as any;
    selectedView?.(data);
    setUnicode(data?.currency);
  };

  return (
    <Form.Item>
      <Select value={unicode}
        size="small"
        getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
        onChange={onchangeCurrencyhandler}
        disabled={disabled}
      >
        {allcurrency &&
          allcurrency.map((item: any) => (
            <Option key={item.id} value={item.currency}>
              {item.currency}
            </Option>
          ))}
      </Select>
    </Form.Item>
  );
}

export default AllCurrency;
