import React, { useState, useEffect, useMemo, useCallback } from "react";
import { MESSAGE_CONSTANT } from "../../constants/MessageConstants";
import { max, some } from "underscore";
import "./QuantityList.css";
import UseQuoteService from "../../hooks/UseQuoteService";
import useSessionStorageService from "../../hooks/UseSessionStorageService";
import Swal from "sweetalert2";
import { CloseOutlined } from "@ant-design/icons";
import { Col, Form, Input, Row, Select, Space, Tooltip, Typography, } from "antd";
import { trackPromise } from "react-promise-tracker";

export const QuantityList = (props: any) => {
  const { isPricingDone } = props;
  const { Option } = Select;
  const [boardQuantities, setBoardQuantities] = useState<any>([]);
  const [turnTimeUom, setTurnTimeUom] = useState<any>([]);
  const { getAllTurnTimeUom, deleteQuantity, deleteTurnTime } = UseQuoteService();
  const { getUserName } = useSessionStorageService();
  const [defaultTurnTimeUOMId, setDefaultTurnTimeUOMId] = useState(0);

  useEffect(() => {
    props.handleQuantityData({
      boardQuantities: boardQuantities,
    });
  }, [boardQuantities]);

  const getAllUOM = async () => {
    let uomList = await getAllTurnTimeUom() as any[];
    setDefaultTurnTimeUOMId(uomList[0]?.id);
    setTurnTimeUom(uomList);
    setQuantityDefaultData(uomList);
  };

  useEffect(() => {
    (async () => {
      await trackPromise(getAllUOM());
    })();
  }, []);

  const handleQtyChanged = (index: any, event: any) => {
    const qty = event.target.value;
    const copiedBoardQuantities = [...boardQuantities];
    copiedBoardQuantities[index].quantity = qty;
    setBoardQuantities(copiedBoardQuantities);
  };

  const setQuantityDefaultData = (uomList: any) => {
    if (props.projectId > 0 && props.quantityListData.length > 0) {
      let quantityListObj = [] as any;
      props.quantityListData.forEach((element: any, index: any) => {
        let quantity = {} as any;
        quantity.id = element.id;
        quantity.customQuantityId = Math.random();
        quantity.quantity = element.qty;
        quantity.turntimeUom = [] as any;
        quantity.externalQuantityId = some(element.turnTimes, function (vTurnTime: any) { return vTurnTime.externalTurnTimeId != null });
        element.turnTimes.forEach((turntimeUom: any, index: any) => {
          let turntime = {} as any;
          turntime.id = turntimeUom.id;
          turntime.customTurnTimeId = Math.random();
          turntime.timeUomValue = turntimeUom.turntime;
          turntime.timeUom = turntimeUom.turnTimeUOMId;
          turntime.externalTurnTimeId = turntimeUom.externalTurnTimeId;
          quantity.turntimeUom.push(turntime);
        });
        quantityListObj.push(quantity);
      });
      setBoardQuantities(quantityListObj);
    } else {
      let defaultTurnTimeUOMId = uomList[0]?.id;
      let defaultQuantities = props.defaultQuantities.length > 0 ? setTurnTimeUOMIdInDefaultQuantities(props.defaultQuantities, uomList) :
        [{
          id: 0,
          customQuantityId: Math.random(),
          quantity: "",
          turntimeUom: [
            {
              id: 0,
              customTurnTimeId: Math.random(),
              timeUomValue: "",
              timeUom: defaultTurnTimeUOMId,
            },
          ],
        }]

      setBoardQuantities(defaultQuantities);
    }
  }

  const setTurnTimeUOMIdInDefaultQuantities = (defaultQuantities: any, uomList: any) => {
    let quantities = defaultQuantities;
    quantities.forEach((qty: any) => {
      qty.turntimeUom.forEach((turnTime: any) => {
        let existingUOMId = uomList.find((uom: any) => uom.name == turnTime.timeUom)?.id;
        if (existingUOMId) {
          turnTime.timeUom = existingUOMId;
        }
        //When TurnTime UOM is not exist and If it is Month then convert it to Week UOM.
        else if (turnTime.timeUom.toLowerCase() == 'month') {
          turnTime.timeUomValue = (4 * turnTime.timeUomValue);
          turnTime.timeUom = uomList.find((uom: any) => uom.name == 'Week(s)').id;
        }

        if (!qty.externalQuantityId) {
          qty.externalQuantityId = turnTime.externalTurnTimeId != null;
        }
      });
    });
    return quantities;
  }

  const addQuantity = () => {
    let maxQtyId = max(boardQuantities, function (o: any) {
      return o.customQuantityId;
    }).customQuantityId;
    setBoardQuantities([
      ...boardQuantities,
      {
        id: 0,
        customQuantityId: maxQtyId + 1,
        quantity: "",
        turntimeUom: [
          {
            id: 0,
            customTurnTimeId: Math.random(),
            timeUomValue: "",
            timeUom: defaultTurnTimeUOMId,
          },
        ],
      },
    ]);
  };

  const removeQuantity = (customQuantityId: any) => {
    let remainingQuantities = [];
    let quantity = boardQuantities.find((x: any) => x.customQuantityId === customQuantityId);

    if (props.projectId > 0) {
      let existingQuantities = boardQuantities.filter((x: any) => x.id > 0);

      if (existingQuantities?.length > 1 && quantity?.id > 0) {
        let requestObject = {
          Id: quantity.id,
          UserName: getUserName(),
        };
        showDeleteQuantitySweeetAlert(requestObject, customQuantityId);
      }
      else if (existingQuantities?.length == 1 && quantity?.id > 0) {
        showRequiredSweeetAlert(MESSAGE_CONSTANT.MinimumOneQuantityRequired);
      }
      else if (boardQuantities.length > 1) {
        remainingQuantities = boardQuantities.filter((qty: any) => qty.customQuantityId !== customQuantityId);
      }
    }
    else if (boardQuantities.length > 1) {
      remainingQuantities = boardQuantities.filter(
        (qty: any) => qty.customQuantityId !== customQuantityId
      );
    }

    if (remainingQuantities.length > 0) {
      setBoardQuantities(remainingQuantities);
    }
  };

  const showRequiredSweeetAlert = (message: any) => {
    Swal.fire({
      text: message,
      icon: "warning",
    });
  };

  const showDeleteQuantitySweeetAlert = (
    requestObject: any,
    customQuantityId: any
  ) => {
    Swal.fire({
      text: MESSAGE_CONSTANT.DeleteQuantity,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await deleteQuantityOnEdit(requestObject);
        let updatedQuantities = boardQuantities.filter(
          (qty: any) => qty.customQuantityId !== customQuantityId
        );
        setBoardQuantities(updatedQuantities);
      }
    });
  };

  const deleteQuantityOnEdit = async (data: any) => {
    return await deleteQuantity(data);
  };

  const handleTurnChanged = (
    qtyIndex: any,
    turntimeIndex: any,
    event: any,
    isValidated: any
  ) => {
    let target = event.target;
    let value = target.value;
    let name = target.name;
    let copiedBoardQuantities = [...boardQuantities];
    let propertyName = name.split("_")[0];
    let copiedTurnTime = copiedBoardQuantities[qtyIndex].turntimeUom;
    copiedTurnTime[turntimeIndex] = {
      ...copiedTurnTime[turntimeIndex],
      [propertyName]: value,
    };
    copiedBoardQuantities[qtyIndex].turntimeUom = copiedTurnTime;
    setBoardQuantities(copiedBoardQuantities);
  };

  const handleTimeUomChanged = (
    value: any,
    turnTimeIndex: any,
    qtyIndex: any
  ) => {
    let copiedBoardQuantities = [...boardQuantities];
    let copiedTurnTime = copiedBoardQuantities[qtyIndex].turntimeUom;
    copiedTurnTime[turnTimeIndex] = {
      ...copiedTurnTime[turnTimeIndex],
      timeUom: value,
    };
    copiedBoardQuantities[qtyIndex].turntimeUom = copiedTurnTime;
  };

  const addTurnTime = (qtyIndex: any) => {
    let copiedBoardQuantities = [...boardQuantities];
    let copyTurnTimeUom = copiedBoardQuantities[qtyIndex].turntimeUom;
    let turnTimes = [
      ...copyTurnTimeUom,
      {
        id: 0,
        customTurnTimeId: Math.random(),
        timeUomValue: "",
        timeUom: defaultTurnTimeUOMId,
      },
    ];
    copiedBoardQuantities[qtyIndex].turntimeUom = turnTimes;
    setBoardQuantities(copiedBoardQuantities);
  };

  const removeTurnTime = (qtyIndex: any, customTurnTimeId: any) => {
    let copiedBoardQuantities = [...boardQuantities];
    let copiedBoardQuantity = copiedBoardQuantities[qtyIndex];
    let copiedTurnTimeUom = copiedBoardQuantities[qtyIndex].turntimeUom;

    let turnTime = copiedTurnTimeUom.find(
      (x: any) => x.customTurnTimeId === customTurnTimeId
    );

    if (props.projectId > 0) {
      let existingTurnTimes = copiedTurnTimeUom.filter((x: any) => x.id > 0);
      if (existingTurnTimes?.length > 1 && turnTime?.id > 0) {
        let requestObject = {
          Id: turnTime.id,
          UserName: getUserName(),
        };
        showDeleteTurnTimeSweeetAlert(
          requestObject,
          customTurnTimeId,
          qtyIndex
        );
      } else {
        if (copiedTurnTimeUom.length <= 1) {
          copiedBoardQuantities[qtyIndex].turntimeUom = [
            { customTurnTimeId: Math.random(), timeUomValue: "", timeUom: defaultTurnTimeUOMId },
          ];
        } else {
          copiedBoardQuantities[qtyIndex].turntimeUom =
            copiedTurnTimeUom.filter(
              (qty: any) => qty.customTurnTimeId !== customTurnTimeId
            );
        }
      }
    } else {
      if (copiedTurnTimeUom.length <= 1) {
        copiedBoardQuantities[qtyIndex].turntimeUom = [
          { customTurnTimeId: Math.random(), timeUomValue: "", timeUom: defaultTurnTimeUOMId },
        ];
      } else {
        copiedBoardQuantities[qtyIndex].turntimeUom = copiedTurnTimeUom.filter(
          (qty: any) => qty.customTurnTimeId !== customTurnTimeId
        );
      }
    }
    setBoardQuantities(copiedBoardQuantities);
  };

  const showDeleteTurnTimeSweeetAlert = async (
    requestObject: any,
    customTurnTimeId: any,
    qtyIndex: any
  ) => {
    Swal.fire({
      text: MESSAGE_CONSTANT.DeleteTurnTime,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await deleteTurntimeOnEdit(requestObject);
        let copiedBoardQuantities = [...boardQuantities];
        copiedBoardQuantities[qtyIndex].turntimeUom = copiedBoardQuantities[
          qtyIndex
        ].turntimeUom.filter(
          (qty: any) => qty.customTurnTimeId !== customTurnTimeId
        );
        setBoardQuantities(copiedBoardQuantities);
      }
    });
  };

  const deleteTurntimeOnEdit = async (data: any) => {
    return await deleteTurnTime(data);
  };

  return (
    <>
      {boardQuantities.length > 0 &&
        boardQuantities.map((qty: any, qtyIndex: any) => (
          <Row key={qtyIndex}>
            <Col span={8} >
              <Form.Item
                name={`Qty${qty.customQuantityId}`}
                initialValue={qty.quantity}
                label={`Quantity ${qtyIndex + 1}`}
                rules={[{ pattern: new RegExp(/^[1-9][0-9]*$/), message: "Please Input Valid Number" },
                { required: true, message: '' }
                ]}
              >
                <Input
                  maxLength={6}
                  placeholder="Quantity"
                  onChange={(event) => handleQtyChanged(qtyIndex, event)}
                  disabled={qty.externalQuantityId && qty.externalQuantityId != null}
                />
              </Form.Item>
            </Col>
            <Col span={1} className="text-center">
              {!isPricingDone && boardQuantities.length > 1 && (
                <Tooltip title="Delete Quantity">
                  <CloseOutlined
                    style={{ color: "red" }}
                    onClick={() => removeQuantity(qty.customQuantityId)}
                  />
                </Tooltip>
              )}
            </Col>
            <Col span={12} offset={1} key={qtyIndex}>
              {qty.turntimeUom.map(
                (turntime: any, turnTimeIndex: any) => (
                  <Row key={`${qtyIndex}_${turnTimeIndex}`}>
                    <Col span={14}>
                      <Form.Item
                        name={`turnTime_${turntime.customTurnTimeId}`}
                        initialValue={turntime.timeUomValue}
                        label='Turn Time'
                        rules={[{ pattern: new RegExp(/^[1-9][0-9]*$/), message: "Please Input Valid Number" },
                        { required: true, message: '' }]}
                      >
                        <Input
                          name="timeUomValue"
                          style={{ width: '100%' }}
                          placeholder="Turn time"
                          maxLength={6}
                          onChange={(event) =>
                            handleTurnChanged(qtyIndex, turnTimeIndex, event, true)
                          }
                          disabled={turntime.externalTurnTimeId != null}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6} offset={1}>
                      <Form.Item
                        name={`turntimeUom_${turnTimeIndex}_${qtyIndex}`}
                        initialValue={turntime.timeUom}
                      >
                        <Select
                          onChange={(selectedValue) =>
                            handleTimeUomChanged(selectedValue, turnTimeIndex, qtyIndex)
                          }
                          disabled={turntime.externalTurnTimeId != null}
                        >
                          {turnTimeUom &&
                            turnTimeUom.map((item: any) => (
                              <Option value={item.id} key={item.id}>
                                {item.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={1} offset={1} style={{ paddingBottom: "26px" }}>
                      {!isPricingDone &&
                        qty?.turntimeUom.length > 1 && (
                          <Tooltip title="Delete TurnTime">
                            <CloseOutlined
                              style={{ color: "red" }}
                              onClick={() =>
                                removeTurnTime(
                                  qtyIndex,
                                  turntime.customTurnTimeId
                                )
                              }
                            />
                          </Tooltip>
                        )}
                    </Col>
                  </Row>
                )
              )}
              <Row>
                {isPricingDone ? (
                  <></>
                ) : (
                  <Typography.Text
                    onClick={() => addTurnTime(qtyIndex)}
                    style={{
                      color: "#1890ff",
                      paddingBottom: "0px",
                      cursor: "pointer",
                    }}
                  >
                    Add Turn Time
                  </Typography.Text>
                )}
              </Row>
            </Col>
          </Row>
        ))}

      <Row style={{ marginTop: "20px" }}>
        <Col>
          {isPricingDone ? (
            <></>
          ) : (
            <Typography.Text
              onClick={() => addQuantity()}
              style={{
                color: "#1890ff",
                marginRight: "50px",
                cursor: "pointer",
              }}
            >
              Add New Quantity To Quote
            </Typography.Text>
          )}
        </Col>
      </Row>
    </>
  );
};
