import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.css";
import reportWebVitals from './reportWebVitals';
import withClearCache from './ClearCache';
import { BrowserRouter as Router } from 'react-router-dom';
import BoardCQProvider from './context/Context';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const AppComponentWithClearCache = withClearCache(App);

root.render(
    <BoardCQProvider >
      <Router>
        <AppComponentWithClearCache />
      </Router>
    </BoardCQProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
