const AboutPage = () => {
    return (
        <>
            <div className="overflow-none">
                <h1 className="header text-center">About Page</h1>
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-12">
                        <h3 className="text-center content-space">
                            <>You're accessing the about page resource.</>
                        </h3>
                    </div>
                    <div className="col-md-2"></div>
                </div>
            </div>
        </>
    );
};

export default AboutPage;